
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const productService = {
    getProductList,

};
function logout() {
    // remove user from local storage to log user out
    // localStorage.removeItem('user');
    // window.location.href = "#/home"
}

function getProductList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    // console.log("headerheaderheaderheader  ",header);
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)

    }
    return fetch(CONST.BACKEND_URL + `/getProductList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                getProductList: data.data
            }
            console.log("i am in service getProductList:::", userObj);
            return userObj;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.msg) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            const error = (data && data.msg) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}