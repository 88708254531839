import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
// import { connect } from "react-redux";
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import PublicFooter from "../../components/PublicFooter/PublicFooter";

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
      errorsUpdateAddress: {},
      UpdateAddressCreateModal: false,
      CreateAddress: false,
      fieldslogin: {},
      errorslogin: {},
      // totalOldAmount,
      selectedAdd: '',
      show: false,
      listCard: false,
      currentTotal: '',
      currentDisc: '',
      oldTotal: '',
      payType: ''
    };
  }

  componentDidMount() {
    let productList = JSON.parse(localStorage.getItem("products"))
    let totalAmount = 0
    let totalOldAmount = 0
    for (let index = 0; index < productList.length; index++) {
      const element = productList[index];
      let tempObj = {
        "productId": element.id,
        "quantity": element.count
      }
      totalAmount = Number(totalAmount + element.subTotal)
      totalOldAmount = Number(totalOldAmount + Number(element.product.oldPrice * element.count))
      console.log('elemmmmmmmmm', element);
    }
    let currentDisc = Number(totalOldAmount - totalAmount)
    this.setState({ currentTotal: totalAmount })
    this.setState({ oldTotal: totalOldAmount })
    this.setState({ currentDisc: currentDisc })

    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getAddressList(data));

  }

  onClickMenu = (url, data) => {
    this.props.history.push(url + data.id);
  }

  chooseAddress = (e) => {
    // e.preventDefault();
    console.log('e_________________', e);

    // this.setState({ fieldslogin, errorslogin });
    this.setState({ selectedAdd: e.id });
  };
  selectPaymentType = (e) => {
    e.preventDefault();
    console.log('EEEEEEEEEEEEEEE', e.target.value);
    this.setState({ payType: e.target.value });
  };


  // payType = (f) => {
  //   // e.preventDefault();
  //   console.log('f_________________', f);

  //   // this.setState({ fieldslogin, errorslogin });
  //   this.setState({ payTyped: f.id });
  // };

  saveAddressDetails = () => {

    let add = {
      fullName: this.state.fieldslogin.fullName,
      Address: this.state.fieldslogin.Address,
      email: this.state.fieldslogin.email,
      city: this.state.fieldslogin.city,
      country: this.state.fieldslogin.country,
      mobNo: this.state.fieldslogin.mobNo,
      pin: this.state.fieldslogin.pin,
      state: this.state.fieldslogin.state,

    }
    this.props.dispatch(userActions.createAddress(add, this.props));

  }


  addToCart = (data) => {
    console.log("+++++++++++++++++++", data);
    let products = JSON.parse(localStorage.getItem("products"));
    console.log("localStorage____products::", products);
    // {
    //     JSON.stringify(products)   
    // }
    if (products && products.length > 0) {
      let currentPrductindex = products.findIndex((element) => (element.id === data.id));

      if (currentPrductindex >= 0) {

        products[currentPrductindex]['count'] += 1;
        let { product, amount } = products[currentPrductindex];

        console.log("products[product]:::", product.newPrice);

        products[currentPrductindex].subTotal = products[currentPrductindex].count * product.newPrice

        amount = products[currentPrductindex].subTotal;
        console.log("currentPrductindex::::::::::::  ", amount);
        // products[currentPrductindex].grandTotal = products.reduce + products[currentPrductindex].count * subTotal
        // console.log("grandTotalgrandTotalgrandTotalgrandTotalgrandTotal:::", grandTotal);
        // console.log("productsTotal:::", productsTotal);
        // localStorage.setItem("grandTotal", JSON.stringify(grandTotal))
        // localStorage.setItem("productsTotal", JSON.stringify(productsTotal))
        localStorage.setItem("products", JSON.stringify(products))
        window.location.reload();
      } else {
        let product = {
          id: data.id,
          product: data,
          count: 1,
          subTotal: data.newPrice,
          grandtotal: data.newPrice
        }
        products.push(product)
        localStorage.setItem("products", JSON.stringify(products))
        window.location.reload();
      }
    } else {
      let product = [{
        id: data.id,
        product: data,
        count: 1,
      }]
      localStorage.setItem("products", JSON.stringify(product))
      window.location.reload();
    }
    console.log("")
    console.log("productsproductsproducts ", products);
    localStorage.setItem("products", JSON.stringify(products))
    let totalAmount = products.reduce((a, b) => ({ subTotal: a.subTotal + b.subTotal }));
    console.log("totalAmounttotalAmount  ", totalAmount);
    this.setState({ products })
    this.setState({ totalAmount: totalAmount })

  }


  subtractToCart = (data) => {
    console.log("---------------");
    let products = JSON.parse(localStorage.getItem("products"));
    if (products && products.length > 0) {
      let currentPrductindex = products.findIndex((element) => (element.id == data.id));

      console.log("currentPrductindex msdddddddddddddddddd ", currentPrductindex);
      console.log("products[currentPrductindex]  ", products[currentPrductindex]);

      if (currentPrductindex >= 0 && products[currentPrductindex]['count'] > 1) {
        products[currentPrductindex]['count'] -= 1;
        let { product, productTotal } = products[currentPrductindex];
        products[currentPrductindex].subTotal = products[currentPrductindex].count * product.newPrice
        localStorage.setItem("products", JSON.stringify(products))
        window.location.reload();
      } else {
        let currentPrductindex = products.filter((element) => (element.id != data.id));
        localStorage.setItem("products", JSON.stringify(currentPrductindex))
        window.location.reload();
      }
    }

    console.log("")
    console.log("productsproductsproducts ", products);

    localStorage.setItem("products", JSON.stringify(products))

    let totalAmount = products.reduce((a, b) => ({ subTotal: a.subTotal + b.subTotal }));
    console.log("totalAmounttotalAmount  ", totalAmount);
    this.setState({ products })
    this.setState({ totalAmount })

  }

  removeFromCart = (data) => {
    let products = JSON.parse(localStorage.getItem("products"));
    if (products && products.length > 0) {
      let currentPrductindex = products.filter((element) => (element.id != data.id));
      localStorage.setItem("products", JSON.stringify(currentPrductindex))
      window.location.reload();
    }
  }


  createOrder = () => {
    let productList = JSON.parse(localStorage.getItem("products"))
    let tempArr = []
    let totalAmount = 0
    let totalOldAmount = 0
    for (let index = 0; index < productList.length; index++) {
      const element = productList[index];
      let tempObj = {
        "arrivalId": element.id,
        "quantity": element.count
      }
      totalAmount = Number(totalAmount + element.subTotal)
      totalOldAmount = Number(totalOldAmount + Number(element.product.oldPrice * element.count))
      console.log('elemmmmmmmmm', element);
      tempArr.push(tempObj)
    }
    console.log('totalAmount', totalAmount);
    console.log('totalOldAmount', totalOldAmount);
    console.log('tempArrtempArrtempArrtempArr', tempArr);
    let order = {
      // "userId": "63c4f938835a6f7649a05e8d",
      "addressId": this.state.selectedAdd,
      "orderItems": tempArr,
      // "orderDiscount": "10% Disc",
      "orderDiscount": Number(totalOldAmount - totalAmount),
      "countrycode": "+91",
      // "ip": "168.192.0.0",
      "paymentType": this.state.payType ? this.state.payType : 'N/A',
      "shippingCharge": "50",
      "price": totalAmount
    }

    // let currentDisc = Number(totalOldAmount - totalAmount)
    // this.setState({ currentTotal: totalAmount })
    // this.setState({ oldTotal: totalOldAmount })
    // this.setState({ currentDisc: currentDisc })
    this.props.dispatch(userActions.createOrder(order, this.props));
    console.log('oder________________', order);
    alert(" Your Order is placed")
  }


  toggle = () => this.setState((currentState) => ({ show: !currentState.show, fieldsAddressUpdate: {} }));

  render() {
    const truncate = (input) =>
      input?.length > 60 ? `${input.substring(0, 40)}...` : input;
    let { users } = this.props;
    let { getUserProfile, getAddressList } = users
    // console.log("fieldsloginfieldsloginfieldslogin:::", getAddressList);
    console.log("e_________________RENDER", this.state);

    return (
      <>
        {/* Whole Structure */}
        <div className="overflow-hidden">
          {/* Headdr */}
          <PublicHeader />
          <main className="bg-gray-50">
            {/* Hero Section for Product Category/Family*/}
            <section className="relative z-20 items-center overflow-hidden py-5">
              <div className="max-w-screen-2xl mx-auto px-2 sm:px-4 lg:px-8 flex relative">
                <div className=" flex flex-col w-full space-y-5 max-w-3xl mx-auto">
                  {/* Login/Signup */}
                  {/* If logged-in */}




                  <div className="py-2 px-7  items-center shadow rounded bg-white">
                    <div className="flex-1 flex items-center space-x-5 py-4 bg-primary">
                      <span className=" px-5 text-white justify-center text-3xl fony-bold items-center inline-flex h-7 w-7 rounded ">1.</span>
                      <h3 className="text-2xl fony-bold text-white font-medium">Order Summary </h3>
                    </div>
                    <br />
                    <div className="md:pt-4 item-center px-10">
                      {/* Cart Items header*/}
                      <div className="hidden md:flex justify-between border-b border-black border-opacity-20 mr-10">
                        <div className="w-1/2 pb-2 lg:w-3/5 xl:w-1/2 ">
                          <span className="text-sm uppercase font-normal text-gray-600 ">Product</span>
                        </div>

                        <div className="w-1/5 pb-2 text-left ">
                          <span className="text-sm uppercase font-normal text-gray-600 ">Price</span>
                        </div>

                        <div className="w-1/4 pb-2 text-center ">
                          <span className="text-sm uppercase font-normal text-gray-600 ">Quantity</span>
                        </div>

                        <div className="w-1/5 pb-2 text-right  ">
                          <span className="text-sm uppercase font-normal text-gray-600 ">Total</span>
                        </div>
                        <div className="flex items-center "> <button className="w-6 h-6 opacity-0 visibility-hidden flex justify-center items-center rounded-full bg-gray-100"><svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" fill="currentColor" className="w-4"><path d="M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z"></path></svg></button></div>

                      </div>

                      <div className="max-h-[500px] overflow-auto pr-10">
                        {/* Cart Items */}

                        {
                          JSON.parse(localStorage.getItem("products")) && JSON.parse(localStorage.getItem("products")).length > 0 ? JSON.parse(localStorage.getItem("products")).map((element, index) => (
                            <div
                              className="flex flex-wrap lg:flex-nowrap justify-between items-center relative py-4 lg:py-8 border-b border-black border-opacity-20">
                              <div className="md:w-1/2 w-full flex items-center ">
                                <div className="w-20 pr-0 mx-0">
                                  <div className="flex items-center justify-center h-20 rounded bg-gray-100">

                                    <div className="w-full  aspect-w-1 aspect-h-1">
                                      <img src={element && element.product && element.product.imageLink} alt="productImage" className="object-cover" />
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <span className="mt-2 ml-4 text-sm lg:text-base font-bold text-black block ">{element && element.product && element.product.name}</span>
                                  <span className="ml-4 text-sm  font-normal text-gray-400">{(element && element.product && element.product.desc)}</span>
                                </div>
                              </div>

                              <div className="w-1/5  text-left  flex flex-wrap items-center mt-3">
                                <span className="block w-full text-sm md:hidden mb-1">Price</span>
                                <span className="text-base uppercase font-medium text-black"> ₹{element && element.product && element.product.newPrice}</span>
                              </div>

                              <div className="w-1/4  text-left  flex flex-wrap items-center mt-3">
                                <span className="block w-full text-sm md:hidden mb-1 text-center">Quantity</span>
                                <div className="container flex items-center w-28 justify-center bg-gray-100 rounded-full py-2 mx-auto">
                                  <button className="mx-auto cart-qty-minus" type="button" value="element.count"
                                    onClick={() => this.subtractToCart(element, false)}
                                  ><svg xmlns="http://www.w3.org/2000/svg"
                                    id="Outline" viewBox="0 0 24 24" className="w-4">
                                      <rect x="6" y="11" width="12" height="2" rx="1"></rect>
                                    </svg></button>

                                  <input type="text" name="qty" className="p-0 text-center w-5 bg-gray-100 text-sm  qty " maxlength="12"
                                    value={element && element.count ? element.count : "-"} min="1" />
                                  <button className="mx-auto cart-qty-minus" type="button" value="+"
                                    onClick={() => this.addToCart(element, false)}
                                  ><svg xmlns="http://www.w3.org/2000/svg"
                                    id="Outline" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                      <path
                                        d="M17,11H13V7a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v4H7a1,1,0,0,0-1,1H6a1,1,0,0,0,1,1h4v4a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13h4a1,1,0,0,0,1-1h0A1,1,0,0,0,17,11Z">
                                      </path>
                                    </svg></button>


                                </div>
                              </div>

                              <div className="w-1/5  text-right lg:pr-10  mt-3">
                                <span className="block w-full text-sm md:hidden mb-1">Total</span>
                                <span className="text-base uppercase font-medium text-black  ml-auto">{element && element.subTotal ? element.subTotal : "-"}</span>
                              </div>

                              <div className="flex items-center ">
                                <button className="hover:text-red-600 text-gray-400 cursor-pointer w-8 h-8 rounded bg-white flex justify-center items-center buzz-out transition hover:bg-red-50" onClick={() => this.removeFromCart(element)}>
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" id="Outline" viewBox="0 0 24 24"
                                    className="transition w-4">
                                    <path
                                      d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z">
                                    </path>
                                    <path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z"></path>
                                    <path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"></path>
                                  </svg>
                                </button>
                              </div>
                            </div>


                          )) : null
                        }


                      </div>
                    </div>



                  </div>
                  <div className="py-2  items-center shadow rounded bg-white">
                    <div className="flex-1 flex items-center space-x-5 py-4 bg-primary">
                      <span className=" px-5 text-white justify-center text-3xl fony-bold items-center inline-flex h-7 w-7 rounded ">2.</span>
                      <h3 className="text-2xl fony-bold text-white font-medium">Login </h3>
                    </div>
                    <br />


                    <div className="prose prose-sm max-w-none p-4 text-base-content relative">

                      <p>
                        Hello, <span className="uppercase font-medium">{getUserProfile && getUserProfile.name ? getUserProfile.name : "-"}!</span>

                        <br />
                        We're so excited you are a part of Algems Gemstones. We love all our customers, and that includes you too!
                      </p>
                      <div className="flex gap-10">
                        <p className="space-x-3 flex items-center">
                          <span className="text-primary">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                              />
                            </svg>
                          </span>
                          <span>{getUserProfile && getUserProfile.email ? getUserProfile.email : "-"}</span>
                        </p>
                        <p className="space-x-3 flex items-center">
                          <span className="text-primary">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                              />
                            </svg>
                          </span>
                          <span>+91 {getUserProfile && getUserProfile.mobile ? getUserProfile.mobile : "-"}</span>
                        </p>
                      </div>
                    </div>

                    {/* <button class="inline-flex items-center px-3 py-2 text-center border border-transparent font-normal rounded text-sm shadow-sm text-white bg-primary hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Change</button> */}
                  </div>
                  <div className="py-2  items-center shadow rounded bg-white">
                    <div className="flex-1 flex items-center space-x-5 py-4 bg-primary">
                      <span className=" px-5 text-white justify-center text-3xl fony-bold items-center inline-flex h-7 w-7 rounded ">3.</span>
                      <h3 className="text-2xl fony-bold text-white font-medium">Final Payment </h3>
                    </div>
                    <br />
                    <div className="px-10">
                    <div className="flex justify-between">
                      <span className="text-base font-normal text-black">Total</span>
                      <span className="text-base font-bold black">₹{this.state.oldTotal ? this.state.oldTotal : "N/A"}</span>
                    </div>

                    <div className="flex justify-between">
                      <span className="text-sm text-red-500">Discount:</span>
                      <span className="text-sm text-red-500">{this.state.currentDisc ? this.state.currentDisc : "N/A"}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-sm text-gray-500">Total price:</span>
                      <span className="text-sm text-gray-500">{this.state.currentTotal ? this.state.currentTotal : "N/A"}</span>
                    </div>
                  </div>
                  </div>


                  <div className="py-2 px-7  items-center shadow rounded bg-white">
                    <div className="flex-1 flex items-center space-x-5 py-4 bg-primary">
                      <span className=" px-5 text-white justify-center text-3xl fony-bold items-center inline-flex h-7 w-7 rounded ">3.</span>
                      <h3 className="text-2xl fony-bold text-white font-medium">Delivery Address </h3>
                    </div>
                    <br />
                    <div>
                      {getAddressList && getAddressList.length > 0 ? getAddressList.map((element, index) => <>
                        <div className="gap-10 mt-5">


                          <div class="flex items-center pl-4 border border-gray-200 rounded dark:border-gray-700">

                            <div className="col-span-6 relative space-y-3 p-5 bg-base-200 rounded">
                              <p className="uppercase font-medium text-gray-600" >
                                {element && element.userId && element.userId.name ? element.userId.name : "-"} <br />{element ? element.address_tpye : "-"}
                              </p>
                              <p class="space-x-3 flex items-center">
                                <span class="text-primary">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                    />
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                  </svg>
                                </span>
                                <span>
                                  {element ? element.fullName + " ," : "-"}
                                </span>
                                <span>
                                  {element ? element.Address + " ," : "-"}
                                </span>
                                <span>
                                  {element ? element.city + " ," : "-"}
                                </span>
                                <span>
                                  {element ? element.state + " ," : "-"}
                                </span>
                                <span>
                                  {element ? element.country + " ," : "-"}
                                </span>
                                <span>
                                  {element ? element.pin + " ." : "-"}
                                </span>


                              </p>
                              <div class="flex flex-wrap gap-10">
                                <p class="space-x-3 flex items-center">
                                  <span class="text-primary">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      class="h-5 w-5"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                      ></path>
                                    </svg>
                                  </span>
                                  <span> {element ? element.email : "-"}</span>
                                </p>
                                <p class="space-x-3 flex items-center">
                                  <span class="text-primary">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      class="h-5 w-5"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                      ></path>
                                    </svg>
                                  </span>
                                  <span>+91- {element ? element.mobNo : "-"}</span>
                                </p>
                              </div>
                              <button type="button" onClick={() => { this.chooseAddress(element) }} class="inline-flex items-center px-5 py-2 text-center border border-transparent   font-bold rounded-md text-sm shadow-sm text-white bg-primary hover:bg-primary-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Select Address</button>
                            </div>
                          </div>


                        </div>
                      </>)
                        : null}

                    </div>
                  </div>
                  <div className="py-2 px-3 flex flex-col shadow rounded bg-white ">
                    {/* Heading */}
                    {/* <div className="flex-1 flex items-center space-x-3 pb-2">
                      <span className="bg-gray-200 justify-center items-center inline-flex h-7 w-7 rounded ">1</span><h3 className="text-lg font-medium">Login/Signup</h3>
                    </div> */}
                    {/* <div className="border-t border-gary-300 pt-5 w-full">
                      <div className="grid grid-cols-2 gap-5">
                        <div className="bg-primary p-5  rounded  ">
                          <h3 className="uppercase font-semibold text-white mb-2">Login</h3>
                          <form className="space-y-6" action="#" method="POST" autoComplete="off">
                            <div className="{otpSent?'disableArea':''}">
                              <label for="email" className="block text-sm font-medium text-white">
                                Email address
                              </label>
                              <div className="mt-1">
                                <input id="email" name="email" type="email" autocomplete="email" required className="appearance-none block w-full px-3 py-2 bg-white bg-opacity-25 border border-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-700 focus:border-pink-300 focus:ring-2 transition ease-in-out duration-150 sm:text-sm" />
                              </div>
                            </div>

                            <div>
                              <label for="password" className="block text-sm font-medium text-white">
                                Password
                              </label>
                              <div className="mt-1">
                                <input id="password" name="password" type="password" autocomplete="current-password" required className="appearance-none block w-full px-3 py-2 bg-white bg-opacity-25 border border-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-700 focus:border-pink-300 focus:ring-2 transition ease-in-out duration-150 sm:text-sm" />
                              </div>
                            </div>

                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <input id="remember_me" name="remember_me" type="checkbox" className="h-4 w-4 text-primary focus:ring-primary-500 border-white rounded bg-white bg-opacity-25" />
                                <label for="remember_me" className="ml-2 block text-sm text-white">
                                  Remember me
                                </label>
                              </div>

                              <div className="text-sm">
                                <a href="!#" className="font-medium text-white px-2 rounded py-1 bg-white bg-opacity-10 hover:bg-opacity-100 hover:text-primary transition ease-in-out duration-150">
                                  Forgot your password?
                                </a>
                              </div>
                            </div>

                            <div>
                              <button type="submit" className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                                Sign in
                              </button>
                            </div>
                          </form>

                          <div className="mt-6">
                            <div className="relative">
                              <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-pink-300"></div>
                              </div>
                              <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-primary text-white">
                                  Or continue with
                                </span>
                              </div>
                            </div>

                            <div className="mt-6 grid grid-cols-3 gap-3">
                              <div>
                                <a href="!#" className="w-full inline-flex justify-center py-2 px-4   rounded-md shadow-sm bg-white text-sm font-medium text-primary hover:bg-gray-50">
                                  <span className="sr-only">Sign in with Facebook</span>
                                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z" clip-rule="evenodd" />
                                  </svg>
                                </a>
                              </div>

                              <div>
                                <a href="!#" className="w-full inline-flex justify-center py-2 px-4 rounded-md shadow-sm bg-white text-sm font-medium text-primary hover:bg-gray-50">
                                  <span className="sr-only">Sign in with Twitter</span>
                                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                    <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                  </svg>
                                </a>
                              </div>

                              <div>
                                <a href="!#" className="w-full inline-flex justify-center py-2 px-4   rounded-md shadow-sm bg-white text-sm font-medium text-primary hover:bg-gray-50">
                                  <span className="sr-only">Sign in with GitHub</span>
                                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z" clip-rule="evenodd" />
                                  </svg>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="flex flex-col justify-center">
                          <div className="max-w-xs mx-auto">
                            <h3 className="uppercase font-semibold text-gray-900 mb-2">For new user</h3>
                            <p className="line-clamp-3">From your My Account Dashboard you have the ability to view a snapshot of your recent account activity and update your account information. Select a link below to view or edit information.</p>
                            <button class="mt-5 inline-flex items-center px-3 py-2 text-center border border-transparent font-normal rounded w-40  text-sm shadow-sm text-white bg-primary hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center "><span>Signup Now</span></button>
                          </div>

                   
                          <div className="pt-5 ">
                            <h3 className="uppercase font-semibold text-gray-900 mb-2">Signup</h3>
                            <form className="space-y-6" action="#" method="POST" autoComplete="off">
                              <div className="{otpSent?'disableArea':''}">
                                <label for="email" className="block text-sm font-medium text-gray-700">
                                  Email address
                                </label>
                                <div className="mt-1">
                                  <input id="email" name="email" type="email" autocomplete="email" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm" />
                                </div>
                              </div>

                              <div>
                                <label for="password" className="block text-sm font-medium text-gray-700">
                                  Password
                                </label>
                                <div className="mt-1">
                                  <input id="password" name="password" type="password" autocomplete="current-password" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm" />
                                </div>
                              </div>

                              <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                  <input id="remember_me" name="remember_me" type="checkbox" className="h-4 w-4 text-primary focus:ring-primary-500 border-gray-300 rounded" />
                                  <label for="remember_me" className="ml-2 block text-sm text-gray-900">
                                    Remember me
                                  </label>
                                </div>

                              </div>

                              <div>
                                <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                                  Signup
                                </button>
                              </div>
                            </form>

                            <div className="mt-6">
                              <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                  <div className="w-full border-t border-gray-300"></div>
                                </div>
                                <div className="relative flex justify-center text-sm">
                                  <span className="px-2 bg-white text-gray-500">
                                    Or continue with
                                  </span>
                                </div>
                              </div>

                              <div className="mt-6 grid grid-cols-3 gap-3">
                                <div>
                                  <a href="!#" className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                    <span className="sr-only">Sign in with Facebook</span>
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                      <path fill-rule="evenodd" d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z" clip-rule="evenodd" />
                                    </svg>
                                  </a>
                                </div>

                                <div>
                                  <a href="!#" className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                    <span className="sr-only">Sign in with Twitter</span>
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                    </svg>
                                  </a>
                                </div>

                                <div>
                                  <a href="!#" className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                    <span className="sr-only">Sign in with GitHub</span>
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                      <path fill-rule="evenodd" d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z" clip-rule="evenodd" />
                                    </svg>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div> */}

                  </div>

                  <div>

                    {/* Delivery Address */}
                    {/* <div className="py-2 px-3 flex flex-col shadow rounded bg-white">
                    <div className="flex w-full">
                      <div className="flex-1 flex items-center space-x-3 pb-2">
                        <span className="bg-gray-200 justify-center items-center inline-flex h-7 w-7 rounded ">2</span><h3 className="text-lg font-medium">Delivery Address</h3>
                      </div>

                      <button class="inline-flex items-center px-3 py-2 text-center border border-transparent font-normal rounded text-sm shadow-sm text-white bg-primary hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">New Address</button>
                    </div>
                    <div className=" mt-3 space-y-5">




                      <div className="relative p-5 bg-base-200 rounded relative pl-10">
                        <input id="Address" name="male" type="radio" class="h-4 w-4 text-primary focus:ring-primary-500 border-gray-300 rounded-full absolute left-4 top-6" />
                        <div className="space-y-3">
                          <p className="uppercase font-medium text-gray-600">
                            Kamran Shekh
                          </p>
                          <p class="space-x-3 flex items-center">
                            <span class="text-primary">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </span>
                            <span>
                              771 Bassel Street, Metairie, LA, USA, 70001
                            </span>
                          </p>
                          <div class="flex gap-10">
                            <p class="space-x-3 div
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                  ></path>
                                </svg>
                              </span>
                              <span>kshekh@gmail.com</span>
                            </p>
                            <p class="space-x-3 flex items-center">
                              <span class="text-primary">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                  ></path>
                                </svg>
                              </span>div
                              <span>+91-9828368878</span>
                            </p>
                          </div>
                        </div>
                        <label for="Address" className="absolute inset-0 z-10"> <span className="opacity-0">Select this address</span></label>
                      </div>
                      <div className="relative p-5 bg-base-200 rounded relative pl-10">
                        <input id="Address2" name="male" type="radio" class="h-4 w-4 text-primary focus:ring-primary-500 border-gray-300 rounded-full absolute left-4 top-6" />
                        <div className="space-y-3">
                          <p className="uppercase font-medium text-gray-600">
                            Saddam Husen
                          </p>
                          <p class="space-x-3 flex items-center">
                            <span class="text-primary">
                              <svg
                                xmlns="httpdiv 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                />
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </span>
                            <span>
                              771 Bassel Street, Metairie, LA, USA, 70001
                            </span>
                          </p>
                          <div class="flex gap-10">
                            <p class="space-x-3 flex items-center">
                              <span class="text-primary">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                  ></path>
                                </svg>
                              </span>
                              <span>kshekh@gmail.com</span>
                            </p>
                            <p class="space-x-3 flex items-center">
                              <span class="text-primary">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 div2 0 002 2z"
                                  ></path>
                                </svg>
                              </span>
                              <span>+91-9828368878</span>
                            </p>
                          </div>
                        </div>
                        <label for="Address2" className="absolute inset-0 z-10"> <span className="opacity-0">Select this address</span></label>
                      </div>


                    </div>

                    <div class="mt-3 flex space-x-4 justify-end"><button type="button" class="inline-flex items-center px-4 py-2 text-center border border-gray-300 font-normal rounded-md shadow-sm text-gray-600 hover:bg-primary hover:border-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Reset</button><button type="button" class="inline-flex items-center px-4 py-2 text-center border border-transparent font-normal rounded-md shadow-sm text-white bg-primary hover:bg-primary-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Continue</button></div>


                  </div> */}
                  </div>

                  {/* Payment Options */}

                  <div className="py-2 px-7  items-center shadow rounded bg-white">
                    <div className="flex-1 flex items-center space-x-5 py-4 bg-primary">
                      <span className=" px-5 text-white justify-center text-3xl fony-bold items-center inline-flex h-7 w-7 rounded ">4.</span>
                      <h3 className="text-2xl fony-bold text-white font-medium">Payment Option</h3>
                    </div>
                    <br />

                    <div className=" mt-3 space-y-5">

                      <div class="flex items-center pl-4 border border-gray-200 rounded dark:border-gray-700">
                        <button type="button" onClick={this.selectPaymentType} value='CASH' class="inline-flex items-center px-8 py-3 text-center border border-transparent text-lg  font-bold rounded-md shadow-sm hover:text-white bg-gray-200 text-primary hover:bg-primary-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Cash On Delivery</button>
                      </div>

                      <div class="flex items-center pl-4 border border-gray-200 rounded dark:border-gray-700">
                        <button type="button" onClick={this.selectPaymentType} value='ONLINE' class="inline-flex items-center px-8 py-3 text-center border border-transparent text-lg  font-bold rounded-md shadow-sm hover:text-white bg-gray-200 text-primary hover:bg-primary-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Online Delivery</button>
                      </div>

                    </div>

                    <div class="mt-3 flex space-x-4 justify-end">
                      <button
                        onClick={this.createOrder}
                        type="button" class="inline-flex items-center px-8 py-3 text-center border border-transparent text-xl  font-bold rounded-md shadow-sm text-white bg-primary hover:bg-primary-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Place Order</button></div>

                  </div>



                </div>
              </div>
            </section>
          </main>

          {/* Footer */}
          <PublicFooter />
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn, otpSent, forgotuser } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    forgotuser,
    otpSent,
    users,
  };
}
export default connect(mapStateToProps)(Checkout);
