export const userConstants = {


    GETALL_ADDRESS_REQUEST: 'GETALL_ADDRESS_REQUEST',
    GETALL_ADDRESS_SUCCESS: 'GETALL_ADDRESS_SUCCESS',
    GETALL_ADDRESS_FAILURE: 'GETALL_ADDRESS_FAILURE',


    STATUS_ADDRESS_REQUEST: 'STATUS_ADDRESS_REQUEST',
    STATUS_ADDRESS_SUCCESS: 'STATUS_ADDRESS_SUCCESS',
    STATUS_ADDRESS_FAILURE: 'STATUS_ADDRESS_FAILURE',


    USER_TICKET_CREATE_REQUEST: 'USER_TICKET_CREATE_REQUEST',
    USER_TICKET_CREATE_SUCCESS: 'USER_TICKET_CREATE_SUCCESS',
    USER_TICKET_CREATE_FAILURE: 'USER_TICKET_CREATE_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    USER_INFO_REQUEST: 'USER_INFO_REQUEST',
    USER_INFO_SUCCESS: 'USER_INFO_SUCCESS',
    USER_INFO_FAILURE: 'USER_INFO_FAILURE',

    SIGNUP_REQUEST: 'USERS_SIGNUP_REQUEST',
    SIGNUP_SUCCESS: 'USERS_SIGNUP_SUCCESS',
    SIGNUP_FAILURE: 'USERS_SIGNUP_FAILURE',

    LOGIN_FIRST_REQUEST: 'USERS_FIRST_LOGIN_REQUEST',
    LOGIN_FIRST_SUCCESS: 'USERS_FIRST_LOGIN_SUCCESS',
    LOGIN_FIRST_FAILURE: 'USERS_FIRST_LOGIN_FAILURE',

    UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORDddd_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORDddd_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORDddd_FAILURE',

    GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
    GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',


    SEND_COIN_REQUEST: 'SEND_COIN__REQUEST',
    SEND_COIN_SUCCESS: 'SEND_COIN__SUCCESS',
    SEND_COIN_FAILURE: 'SEND_COIN__FAILURE',

    TX_LIST_REQUEST: 'TX_LIST__REQUEST',
    TX_LIST_SUCCESS: 'TX_LIST__SUCCESS',
    TX_LIST_FAILURE: 'TX_LIST__FAILURE',

    FORGOT_FIRST_REQUEST: 'USERS_FIRST_FORGOT_REQUEST',
    FORGOT_FIRST_SUCCESS: 'USERS_FIRST_FORGOT_SUCCESS',
    FORGOT_FIRST_FAILURE: 'USERS_FIRST_FORGOT_FAILURE',

    SEND_REQUEST: 'USERS_SEND_REQUEST',
    SEND_SUCCESS: 'USERS_SEND_SUCCESS',
    SEND_FAILURE: 'USERS_SEND_FAILURE',

    SEND_OTP_REQUEST: 'USERS_SEND_OTP_REQUEST',
    SEND_OTP_SUCCESS: 'USERS_SEND_OTP_SUCCESS',
    SEND_OTP_FAILURE: 'USERS_SEND_OTP_FAILURE',

    TOKEN_REQUEST: 'USERS_TOKEN_REQUEST',
    TOKEN_SUCCESS: 'USERS_TOKEN_SUCCESS',
    TOKEN_FAILURE: 'USERS_TOKEN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    USER_DETAILS_REQUEST: 'USER_DETAILS_REQUEST',
    USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
    USER_DETAILS_FAILURE: 'USER_DETAILS_FAILURE',

    UPDATE_USER_DETAILS_REQUEST: 'UPDATE_USER_DETAILS_REQUEST',
    UPDATE_USER_DETAILS_SUCCESS: 'UPDATE_USER_DETAILS_SUCCESS',
    UPDATE_USER_DETAILS_FAILURE: 'UPDATE_USER_DETAILS_FAILURE',

    PRICE_REQUEST: 'PRICE_REQUEST',
    PRICE_SUCCESS: 'PRICE_SUCCESS',
    PRICE_FAILURE: 'PRICE_FAILURE',

    SWAP_REQUEST: 'SWAP_REQUEST',
    SWAP_SUCCESS: 'SWAP_SUCCESS',
    SWAP_FAILURE: 'SWAP_FAILURE',


    USER_DOC_LIST_REQUEST: 'USER_DOC_LIST_REQUEST',
    USER_DOC_LIST_SUCCESS: 'USER_DOC_LIST_SUCCESS',
    USER_DOC_LIST_FAILURE: 'USER_DOC_LIST_FAILURE',

    GET_KYC_REQUEST: 'GET_KYC_REQUEST',
    GET_KYC_SUCCESS: 'GET_KYC_SUCCESS',
    GET_KYC_FAILURE: 'GET_KYC_FAILURE',

    USER_KYC_CREATE_REQUEST: 'USER_KYC_CREATE_REQUEST',
    USER_KYC_CREATE_SUCCESS: 'USER_KYC_CREATE_SUCCESS',
    USER_KYC_CREATE_FAILURE: 'USER_KYC_CREATE_FAILURE',


    USER_TICKET_LIST_REQUEST: 'USER_TICKET_LIST_REQUEST',
    USER_TICKET_LIST_SUCCESS: 'USER_TICKET_LIST_SUCCESS',
    USER_TICKET_LIST_FAILURE: 'USER_TICKET_LIST_FAILURE',

    GETALL_USER_NOTIFY_REQUEST: 'GETALL_USER_NOTIFY_REQUEST',
    GETALL_USER_NOTIFY_SUCCESS: 'GETALL_USER_NOTIFY_SUCCESS',
    GETALL_USER_NOTIFY_FAILURE: 'GETALL_USER_NOTIFY_FAILURE',

    UPDATE_USER_NOTIFY_REQUEST: 'UPDATE_USER_NOTIFY_REQUEST',
    UPDATE_USER_NOTIFY_SUCCESS: 'UPDATE_USER_NOTIFY_SUCCESS',
    UPDATE_USER_NOTIFY_FAILURE: 'UPDATE_USER_NOTIFY_FAILURE',


    ADD_RESTAURANT_USER_REQUEST: 'ADD_RESTAURANT_USER_REQUEST',
    ADD_RESTAURANT_USER_SUCCESS: 'ADD_RESTAURANT_USER_SUCCESS',
    ADD_RESTAURANT_USER_FAILURE: 'ADD_RESTAURANT_USER_FAILURE',

    FILE_UPLOAD_SELFIE_STATUS_REQUEST: 'FILE_UPLOAD_SELFIE_STATUS_REQUEST',
    FILE_UPLOAD_SELFIE_STATUS_SUCCESS: 'FILE_UPLOAD_SELFIE_STATUS_SUCCESS',
    FILE_UPLOAD_SELFIE_CLEAR_SUCCESS: 'FILE_UPLOAD_SELFIE_CLEAR_SUCCESS',
    FILE_UPLOAD_SELFIE_STATUS_FAILURE: 'FILE_UPLOAD_SELFIE_STATUS_FAILURE',

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_CLEAR_SUCCESS: 'FILE_UPLOAD_CLEAR_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',

    RESET_USER_REDUX: 'RESET_USER_REDUX',


    GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',

    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',

    UPDATE_ADDRESS_REQUEST: 'UPDATE_ADDRESS_REQUEST',
    UPDATE_ADDRESS_SUCCESS: 'UPDATE_ADDRESS_SUCCESS',
    UPDATE_ADDRESS_FAILURE: 'UPDATE_ADDRESS_FAILURE',

   DISABLE_ADDRESS_REQUEST: 'DISABLE_ADDRESS_REQUEST',
   DISABLE_ADDRESS_SUCCESS: 'DISABLE_ADDRESS_SUCCESS',
   DISABLE_ADDRESS_FAILURE: 'DISABLE_ADDRESS_FAILURE',

    CREATE_ADDRESS_REQUEST: 'CREATE_ADDRESS_REQUEST',
    CREATE_ADDRESS_SUCCESS: 'CREATE_ADDRESS_SUCCESS',
    CREATE_ADDRESS_FAILURE: 'CREATE_ADDRESS_FAILURE',

    CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
    CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
    CREATE_ORDER_FAILURE: 'CREATE_ORDER_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',

};
