import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { homeActions, } from "../../_actions";
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import PublicFooter from "../../components/PublicFooter/PublicFooter";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    let dataReq = {
      "id": this.props.match.params.id,

    }

    console.log("this.props.match.params.id", this.props.match.params.id);

    this.props.dispatch(homeActions.getArrivalsById(dataReq));
    // this.props.dispatch(homeActions.getProductList(data));
    // this.props.dispatch(homeActions.getReviewList(data));
  }

  // componentDidMount() {
  //   this.setState({
  //     nav1: this.slider1,
  //     nav2: this.slider2,
  //   });
  // }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    this.setState({ fieldslogin, errorslogin });
  };

  forgotPassword = (e) => {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let { email } = this.state.fieldslogin;
      this.props.dispatch(
        userActions.forgotPassword(
          {
            email: email,
          },
          this.props,
        ),
      );
    }
  };

  otpSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationOTP()) {
      let { token } = this.props.forgotuser;
      let { OTP, newPassword, confirmnewPassword } = this.state.fieldslogin;
      this.props.dispatch(
        userActions.forgotUpdatePassword(
          {
            token: token,
            newPassword,
            confirmnewPassword,
            otp: OTP,
          },
          this.props,
        ),
      );
    }
  };
  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    });
    this.hideErrorMessage();
  };

  handleValidationOTP = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Name
    if (!fieldslogin["OTP"]) {
      formIsValid = false;
      errorslogin["OTP"] = "Please enter OTP!";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  };

  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Email
    if (!fieldslogin["email"] || fieldslogin["email"] === "") {
      formIsValid = false;
      errorslogin["email"] = "Cannot be empty";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  };

  onClickMenu = (url) => {
    this.props.history.push(url);
  };

  addToCart = (data) => {
    console.log("+++++++++++++++++++", data);
    let products = JSON.parse(localStorage.getItem("products"));
    // console.log("localStorage____products::", products);
    // {
    //     JSON.stringify(products)   
    // }
    if (products && products.length > 0) {
      let currentPrductindex = products.findIndex((element) => (element.id == data.id));
      // let currentPrductCount = products.findIndex((element) => (element.id == data.id ? { count: element.count } : null));
      console.log("currentPrductindex:::", currentPrductindex);

      if (currentPrductindex >= 0) {


        products[currentPrductindex]['count'] += 1;
        // console.log("products[currentPrductindex]:::", products[currentPrductindex].count);
        let { product } = products[currentPrductindex];
        // console.log("products[product]:::", product.newPrice);
        products = products[currentPrductindex].count * product.newPrice;
        console.log("productsResult:::", products);


        localStorage.setItem("products", JSON.stringify(products))
        // window.location.reload();
      } else {
        let product = {
          id: data.id,
          product: data,
          count: 1
        }
        products.push(product)
        localStorage.setItem("products", JSON.stringify(products))
        // window.location.reload();
      }
    } else {
      let product = [{
        id: data.id,
        product: data,
        count: 1
      }]
      localStorage.setItem("products", JSON.stringify(product))
      // window.location.reload();
    }
    // this.props.history.push(url)
  }

  subtractToCart = (data) => {
    console.log("---------------");

    let products = JSON.parse(localStorage.getItem("products"));
    if (products && products.length > 0) {
      let currentPrductindex = products.findIndex((element) => (element.id == data.id));
      console.log("currentPrductindex  ", currentPrductindex);
      console.log("products[currentPrductindex]  ", products[currentPrductindex]);

      if (currentPrductindex >= 0 && products[currentPrductindex]['count'] > 1) {
        products[currentPrductindex]['count'] -= 1;
        localStorage.setItem("products", JSON.stringify(products))
        window.location.reload();
      } else {
        let currentPrductindex = products.filter((element) => (element.id != data.id));
        localStorage.setItem("products", JSON.stringify(currentPrductindex))
        window.location.reload();
      }
    }
  }

  render() {
    let { home } = this.props;
    let { getArrivalsListId } = home;
    console.log("getArrivalsListId_____________:", getArrivalsListId)

    return (
      <>
        {/* Whole Structure */}
        <div className="overflow-hidden">
          {/* Headdr */}
          <PublicHeader />

          <main className="bg-gray-50">
            {/* Product Results */}
            <section className="relative z-20 items-center overflow-hidden dark:bg-gray-800 pt-7">
              <div className="relative px-6 mx-auto max-w-screen-2xl">
                <div>
                  <h1 className="text-center text-2xl text-primary">Contactus</h1>
                </div>
                <div className="grid grid-cols-12 gap-10 p-6 bg-white rounded shadow lg:my-5 ring-1 ring-gray-200">

                  <div className="bg-white col-span-12 rounded-xl p-6 drop-shadow-2xl space-y-4 text-left lg:col-span-2">

                    <h1 className="text-lg font-semibold ">Send a Message</h1>
                    <p className="">For all enquiries, please contact with us using the form below we'll be glad to help you.</p>
                    <div className="grid grid-cols-2 gap-6">

                      {/* {JSON.stringify(this.state.fieldsContactUs)} */}
                      <div>
                        <input type="text" 
                        // onChange={this.inputChange}
                         name="name"
                          // value={this.state.fieldsContactUs["name"] ? this.state.fieldsContactUs["name"] : ''}
                          placeholder="First name*"
                          className="focus:outline-none border p-2 w-full focus:ring-1 rounded font-light focus:font-normal" />
                        {/* {this.state.errorsContactUs["name"] ?
                          <div className="invalid-feedback  text-red-500 text-sm">
                            {this.state.errorsContactUs["name"]}
                          </div>
                          : null} */}
                      </div>

                      <div>
                        <input type="text" 
                        // onChange={this.inputChange}
                         name="email"
                          // value={this.state.fieldsContactUs["email"] ? this.state.fieldsContactUs["email"] : ''}
                          placeholder="Email*"
                          className="focus:outline-none border p-2 w-full focus:ring-1 rounded font-light focus:font-normal" />
                        {/* {this.state.errorsContactUs["email"] ?
                          <div className="invalid-feedback  text-red-500 text-sm">
                            {this.state.errorsContactUs["email"]}
                          </div>
                          : null} */}
                      </div>

                      <div>
                        <input type="number" 
                        // onChange={this.inputChange}
                         name="mobNo"
                          // value={this.state.fieldsContactUs["mobNo"] ? this.state.fieldsContactUs["mobNo"] : ''}
                          placeholder="Phone number *"
                          className="focus:outline-none border p-2 w-full focus:ring-1 rounded font-light focus:font-normal" />
                        {/* {this.state.errorsContactUs["mobNo"] ?
                          <div className="invalid-feedback  text-red-500 text-sm">
                            {this.state.errorsContactUs["mobNo"]}
                          </div>
                          : null} */}
                      </div>

                      <div>
                        <input type="text"
                        //  onChange={this.inputChange}
                          name="subject"
                          // value={this.state.fieldsContactUs["subject"] ? this.state.fieldsContactUs["subject"] : ''}
                          placeholder="Subject "
                          className="focus:outline-none border p-2 w-full focus:ring-1 rounded font-light focus:font-normal" />
                        {/* {this.state.errorsContactUs["subject"] ?
                          <div className="invalid-feedback  text-red-500 text-sm">
                            {this.state.errorsContactUs["subject"]}
                          </div>
                          : null} */}
                      </div>


                      <div className="col-span-2">

                        <textarea type="text" name="msg"
                          // value={this.state.fieldsContactUs["msg"] ? this.state.fieldsContactUs["msg"] : ''}
                          cols="20" rows="8" placeholder="Message "
                          className="focus:outline-none focus:ring-1 w-full border p-2 rounded resize-none font-light focus:font-normal"></textarea>
                        {/* {this.state.errorsContactUs["msg"] ?
                          <div className="invalid-feedback  text-red-500 text-sm">
                            {this.state.errorsContactUs["msg"]}
                          </div>
                          : null} */}

                      </div>

                    </div>

                    <div className="flex justify-center pt-4">
                      
                      <button type="button"
                      style={{backgroundColor:"#e882dc", color:"white"}}
                         className="uppercase mt-4 px-8 py-3 rounded-full text-sm  font-medium bg-gradient-to-b from-pink-400 to-purple-500 whitespace-nowrap">Send Message</button>
                    </div>
                  </div>


                  {/* <div className="col-span-6 gap-5">
                    <div className="">
                      <Slider
                        asNavFor={this.state.nav2}
                        focusOnSelect={false}
                        ref={(slider) => (this.slider1 = slider)}
                        className={"product"}
                      >

                        <div class="overflow-hidden rounded">
                          <img alt="gallery" class="w-full h-full object-cover object-center" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} width="70" height="70" />
                        </div>
                      </Slider>
                    </div>
                    <div className="">
                      <Slider
                        asNavFor={this.state.nav1}
                        ref={(slider) => (this.slider2 = slider)}
                        slidesToShow={5}
                        // vertical={true}
                        className={"productThumb"}
                        swipeToSlide={true}
                        focusOnSelect={true}
                      >
                        <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                          <div className="overflow-hidden bg-gray-900 rounded">
                            <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                          </div>
                        </div>
                        <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                          <div className="overflow-hidden bg-gray-900 rounded">
                            <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                          </div>
                        </div>
                        <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                          <div className="overflow-hidden bg-gray-900 rounded">
                            <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                          </div>
                        </div>
                        <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                          <div className="overflow-hidden bg-gray-900 rounded">
                            <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                          </div>
                        </div>
                        <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                          <div className="overflow-hidden bg-gray-900 rounded">
                            <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                          </div>
                        </div>
                        <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                          <div className="overflow-hidden bg-gray-900 rounded">
                            <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                          </div>
                        </div>
                        <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                          <div className="overflow-hidden bg-gray-900 rounded">
                            <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                          </div>
                        </div>
                        <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                          <div className="overflow-hidden bg-gray-900 rounded">
                            <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                          </div>
                        </div>
                      </Slider>
                    </div>
                  </div> */}

                  {/* <div className="col-span-6 ">

                    <div className="max-w-2xl mx-auto space-y-5">
                      Product name
                      <h2 className="text-4xl font-bold leading-snug font-gray-900 font-headings line-clamp-3">
                        {getArrivalsListId && getArrivalsListId.name ? getArrivalsListId.name : '-'}
                      </h2>
                      Vendor name
                      <p className="font-medium text-gray-500 ">
                        {getArrivalsListId && getArrivalsListId.venderName ? getArrivalsListId.venderName : '-'}
                      </p>
                      Rating & Reviews
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-3 ">
                          <div className="flex items-center space-x-1 ">
                            <svg
                              className="w-4 h-4 fill-current text-primary"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                            </svg>
                            <svg
                              className="w-4 h-4 fill-current text-primary"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                            </svg>
                            <svg
                              className="w-4 h-4 fill-current text-primary"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                            </svg>
                            <svg
                              className="w-4 h-4 fill-current text-primary"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                            </svg>
                            <svg
                              className="w-4 h-4 text-primary fill-current"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                            </svg>
                          </div>
                          <span className="font-normal">{getArrivalsListId && getArrivalsListId.rating ? getArrivalsListId.rating : '-'} Reviews</span>
                        </div>

                        <div className="flex items-center space-x-2 text-green-500">
                          <svg
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12.25 2.25H9.115C8.8 1.38 7.975 0.75 7 0.75C6.025 0.75 5.2 1.38 4.885 2.25H1.75C0.925 2.25 0.25 2.925 0.25 3.75V14.25C0.25 15.075 0.925 15.75 1.75 15.75H12.25C13.075 15.75 13.75 15.075 13.75 14.25V3.75C13.75 2.925 13.075 2.25 12.25 2.25ZM7 2.25C7.4125 2.25 7.75 2.5875 7.75 3C7.75 3.4125 7.4125 3.75 7 3.75C6.5875 3.75 6.25 3.4125 6.25 3C6.25 2.5875 6.5875 2.25 7 2.25ZM5.5 12.75L2.5 9.74998L3.5575 8.69248L5.5 10.6275L10.4425 5.68498L11.5 6.74998L5.5 12.75Z"
                              fill="CurrentColor"
                            />
                          </svg>
                          <span className="font-normal">{getArrivalsListId && getArrivalsListId.order ? getArrivalsListId.order : '-'}</span>
                        </div>
                      </div>
                      Price & Availability
                      <div className="flex items-center">
                        Price
                        <p className="flex items-end flex-1 space-x-2 text-3xl font-medium text-gray-900 ">
                          <strong className="font-bold">₹{getArrivalsListId && getArrivalsListId.newPrice ? getArrivalsListId.newPrice : '-'}</strong>
                          <span className="font-light text-gray-500 line-through">{getArrivalsListId && getArrivalsListId.oldPrice ? getArrivalsListId.oldPrice : '-'}</span>
                          <span className="font-light text-gray-400">
                            / gram
                          </span>
                        </p>
                        Availability
                        <p className="flex items-end space-x-2 text-lg font-normal text-right text-green-500 ">
                          In Stock
                        </p>
                      </div>
                      Product Short Intro
                      <p className="font-light leading-loose text-gray-500 ">{getArrivalsListId && getArrivalsListId.desc ? getArrivalsListId.desc : '-'}</p>

                      Product Few Featured
                      <div>
                        <dl>
                          <div class="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500"> Model</dt>
                            <dd class="mt-1 text-sm font-semibold text-gray-900 sm:mt-0 sm:col-span-3">{getArrivalsListId && getArrivalsListId.model ? getArrivalsListId.model : '-'}</dd>
                          </div>
                          <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">Color</dt>
                            <dd class="mt-1 text-sm font-bold text-gray-900 sm:mt-0 sm:col-span-3 capitalize">{getArrivalsListId && getArrivalsListId.color ? getArrivalsListId.color : '-'}</dd>
                          </div>

                        </dl>
                      </div>
                      Quanity and Size


                      Action Buy Now or Add to Cart Buttons
                      <div className="flex flex-wrap pt-3">
                        <div className="mt-2 ml-2">
                          <button type="button" class="w-60 md:w-40 justify-center inline-flex items-center px-6 py-3 border border-transparent text-lg font-medium rounded-md shadow-sm text-white bg-primary hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300" onClick={() => this.onClickMenu("/cart")}>Buy Now</button>
                        </div>
                        <div className="mt-2">
                          <button onClick={() => this.onClickMenu("/cart")} type="button" class="w-60 md:w-40 justify-center inline-flex items-center px-6 py-3 border border-gray-300 shadow-sm text-lg font-medium rounded-md text-gray-700 bg-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary ml-2 hover:bg-primary hover:text-white hover:border-primary transition ease-in-out duration-300">
                            <span>Add to Cart</span>{" "}
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"></path>
                            </svg>
                          </button>
                        </div>
                      </div>

                      Quoates
                      <div className="relative flex items-center px-6 py-3 space-x-3 bg-white rounded-lg shadow-sm bg-pink-50 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                        <div className="flex-shrink-0 text-primary">
                          <svg className="w-14" viewBox="0 0 37 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.48962 4.65941C4.70031 5.50253 4.0742 6.50347 3.64703 7.60507C3.21986 8.70667 3 9.88736 3 11.0797C3 12.2721 3.21986 13.4528 3.64703 14.5544C4.0742 15.656 4.70031 16.6569 5.48962 17.5L20 33L34.5104 17.5C36.1045 15.7973 37 13.4878 37 11.0797C37 8.67164 36.1045 6.36218 34.5104 4.65941C32.9163 2.95663 30.7543 2.00002 28.5 2.00002C26.2456 2.00002 24.0836 2.95663 22.4896 4.65941L20 7.31873L17.5105 4.65941C16.7212 3.81627 15.7841 3.14746 14.7529 2.69116C13.7216 2.23486 12.6163 2 11.5 2C10.3838 2 9.27849 2.23486 8.24722 2.69116C7.21595 3.14746 6.27892 3.81627 5.48962 4.65941Z" fill="currentColor" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.647031 5.60507C1.0742 4.50347 1.70031 3.50253 2.48962 2.65941C3.27892 1.81627 4.21595 1.14746 5.24722 0.691157C6.27849 0.234856 7.3838 0 8.50004 0C9.61628 0 10.7216 0.234856 11.7529 0.691157C12.4012 0.978018 13.0123 1.34887 13.5728 1.79422C13.5301 1.77438 13.4872 1.75489 13.4442 1.73575C12.3523 1.25001 11.1819 1 10 1C8.81814 1 7.64781 1.25001 6.55588 1.73575C5.46395 2.22149 4.47179 2.93345 3.63607 3.83098C2.80033 4.7285 2.13739 5.79401 1.68509 6.96668C1.23279 8.13936 1 9.39622 1 10.6655C1 11.9348 1.23279 13.1917 1.68509 14.3643C1.72754 14.4744 1.77184 14.5835 1.81796 14.6916C1.33909 14.041 0.944743 13.3221 0.647031 12.5544C0.219861 11.4528 0 10.2721 0 9.07972C0 7.88736 0.219861 6.70667 0.647031 5.60507ZM29.6703 1.1679C28.4083 0.408917 26.9738 2.31025e-05 25.5 2.30907e-05C23.2456 2.30728e-05 21.0836 0.956631 19.4896 2.65941L17.3757 4.91744L19 6.66188L21.636 3.83098C23.3238 2.01835 25.613 1.00002 28 1.00002C28.5644 1.00002 29.1234 1.05697 29.6703 1.1679Z" fill="currentColor" />
                          </svg>

                        </div>
                        <div className="flex-1 min-w-0">
                          <div className="focus:outline-none">
                            <span className="absolute inset-0" aria-hidden="true" />
                            <p className="text-lg font-medium text-primary">
                              Shop on Algems and be part of a community doing good.
                            </p>
                            <p className="text-sm italic text-primary line-clamp-2">
                              We are not just a destination for creative goods, we’re a community pushing for positive change for small businesses, people and the planet.
                            </p>
                          </div>
                        </div>
                      </div>


                    </div>

                  </div> */}

                </div>
              </div>
            </section>


          </main>

          {/* Footer */}
          <PublicFooter />
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn, otpSent, forgotuser } = state.authentication;
  const { users, home } = state;
  return {
    loggingIn,
    forgotuser,
    otpSent,
    users,
    home
  };
}
export default connect(mapStateToProps)(Contact);
