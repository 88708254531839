import React, { Component } from "react";

import Modal from 'react-modal';


export default function UpdateAddressUser(props) {

  let { UpdateAddressCreateModal, handleAppSeetingHideModal, errorsUpdateAddress, inputChange, UpdateAddressSubmit, fieldsAddressUpdate } = props;
  //console.log("userCreateModal  ",userCreateModal);

  return (

    <Modal
      isOpen={UpdateAddressCreateModal}
    // contentLabel="Example Modal"
    >



      <div className="mt-2 ">
        <h4 className="font-sans font-medium text-lg">Fill New Address</h4>
        <form className="mt-2">
          <div className="space-y-5">
            <div className="flex-col gap-5">
              <div className="col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  Full Name
                </label>
                <div class="mt-1">
                  <input
                    class={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"

                    ${errorsUpdateAddress && !errorsUpdateAddress["fullName"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="fullName" name="fullName" value={fieldsAddressUpdate.fullName} placeholder="fullName" type="fullName" onChange={inputChange} />
                    {errorsUpdateAddress && errorsUpdateAddress["fullName"] ?
                    <div className="invalid-feedback text-yellow-600">
                      {errorsUpdateAddress["fullName"]}
                    </div>
                    : null}

                </div>
              </div>
            </div>

            <div className="grid grid-cols-6 gap-5">
              <div className="col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  MobNo 
                </label>
                <div class="mt-1">
                  <input

                  class={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"

                  ${errorsUpdateAddress && !errorsUpdateAddress["mobNo"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="mobNo" name="mobNo" value={fieldsAddressUpdate.mobNo} placeholder="mobNo" type="mobNo" onChange={inputChange} />
                     { errorsUpdateAddress && errorsUpdateAddress["mobNo"] ?
                         <div className="invalid-feedback text-yellow-600">
                      {errorsUpdateAddress["mobNo"]}
                   </div>
                         : null}
                </div>
              </div>

              <div className="col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <div class="mt-1">
                  <input


                  class={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"

                  ${errorsUpdateAddress && !errorsUpdateAddress["Email"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="Email" name="Email" value={fieldsAddressUpdate.Email} placeholder="Email" type="Email" onChange={inputChange} />
                     { errorsUpdateAddress && errorsUpdateAddress["Email"] ?
                         <div className="invalid-feedback text-yellow-600">
                      {errorsUpdateAddress["Email"]}
                   </div>
                         : null}

                  
                </div>
              </div>
            </div>


            <div className="flex-col gap-5">
              <div className="col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  Address
                </label>
                <div class="mt-1">
                  <input
                 class={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"

                  ${errorsUpdateAddress && !errorsUpdateAddress["Address"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="Address" name="Address" value={fieldsAddressUpdate.Address} placeholder="Address" type="Address" onChange={inputChange} />
                     { errorsUpdateAddress && errorsUpdateAddress["Address"] ?
                         <div className="invalid-feedback text-yellow-600">
                      {errorsUpdateAddress["Address"]}
                   </div>
                         : null}
                </div>
              </div>

            </div>



            <div className="grid grid-cols-6 gap-5">
              <div className="col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  City
                </label>
                <div class="mt-1">
                  <input

                 class={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"

                  ${errorsUpdateAddress && !errorsUpdateAddress["City"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="City" name="City" value={fieldsAddressUpdate.City} placeholder="City" type="City" onChange={inputChange} />
                     { errorsUpdateAddress && errorsUpdateAddress["City"] ?
                         <div className="invalid-feedback text-yellow-600">
                      {errorsUpdateAddress["City"]}
                   </div>
                         : null}
                </div>
              </div>
              <div className="col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  State
                </label>
                <div class="mt-1">
                  <input

                 class={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"

                  ${errorsUpdateAddress && !errorsUpdateAddress["state"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="state" name="state" value={fieldsAddressUpdate.state} placeholder="state" type="state" onChange={inputChange} />
                     { errorsUpdateAddress && errorsUpdateAddress["state"] ?
                         <div className="invalid-feedback text-yellow-600">
                      {errorsUpdateAddress["state"]}
                   </div>
                         : null}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-6 gap-5">
              <div className="col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  Country
                </label>
                <div class="mt-1">
                  <input

                 class={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"

                  ${errorsUpdateAddress && !errorsUpdateAddress["country"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="country" name="country" value={fieldsAddressUpdate.country} placeholder="country" type="country" onChange={inputChange} />
                     { errorsUpdateAddress && errorsUpdateAddress["country"] ?
                         <div className="invalid-feedback text-yellow-600">
                      {errorsUpdateAddress["country"]}
                   </div>
                         : null}
                </div>
              </div>

              <div className="col-span-3">
                <label class="block text-sm font-medium text-gray-700">
                  Pin Code
                </label>
                <div class="mt-1">
                  <input

                 class={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"

                  ${errorsUpdateAddress && !errorsUpdateAddress["pin"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="pin" name="pin" value={fieldsAddressUpdate.pin} placeholder="pin" type="pin" onChange={inputChange} />
                     { errorsUpdateAddress && errorsUpdateAddress["pin"] ?
                         <div className="invalid-feedback text-yellow-600">
                      {errorsUpdateAddress["pin"]}
                   </div>
                         : null}
                </div>
              </div>
            </div>


            <div>
              <div className="grid grid-cols-8 gap-5">
                {/* <div className="col-span-2">
                                  <label class="block text-sm font-medium text-gray-700">
                                    country
                                  </label>
                                  <div class="mt-1">
                                    <input

                                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                      name="country" type="country" onChange={this.inputChange}

                                    />
                                  </div>
                                </div> */}
                <div className="grid grid-cols-8 gap-5">
                  {/* <div className="col-span-2">
                                    <label class="block text-sm font-medium text-gray-700">
                                    Pin Code
                                    </label>
                                    <div class="mt-1">
                                      <input
                                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                        name="pin" type="pin" onChange={this.inputChange}

                                      />
                                    </div>
                                  </div> */}
                  {/* <div className="col-span-2">
                                <label class="block text-sm font-medium text-gray-700">
                                  State
                                </label>
                                <div class="mt-1">
                                  <select class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                  >
                                    <option>Rajasthan</option>
                                  </select>
                                </div>
                              </div> */}
                  {/* <div className="col-span-2">
                                <label class="block text-sm font-medium text-gray-700">
                                  Country
                                </label>
                                <div class="mt-1">
                                  <select class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                  >
                                    <option>India</option>
                                  </select>
                                </div> 
                              </div>*/}

                  {/* <div className="col-span-3">
                                    <label class="block text-sm font-medium text-gray-700">
                                      Pin Code
                                    </label>
                                    <div class="mt-1">
                                      <input

                                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                        name="pin" type="pin" onChange={this.inputChange}
                                      />
                                    </div>
                                  </div> */}
                </div>

              </div>

              <div className="flex space-x-4 justify-end">

                <button onClick={UpdateAddressSubmit} type="button" class="inline-flex items-center px-4 py-2 text-center border border-transparent font-normal rounded-md shadow-sm text-white bg-primary hover:bg-primary-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Update Address</button>

              </div>
            </div>
          </div>
        </form>

      </div>


    </Modal>



  );
}
