import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { dashboard } from './dashboard.reducer';
import { home } from './home.reducer';
import { product } from './product.reducer';
import { order } from './order.reducer';


const rootReducer = combineReducers({
  //branch,
  authentication,
  users,
  alert,
  dashboard,
  home,
  product,
  order

});

export default rootReducer;
