
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const orderService = {
    getOrderList,
};


function getOrderList(data) {
    console.log("data_________________data", data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    console.log("requestOptions______requestOptions", requestOptions);
    return fetch(CONST.BACKEND_URL + `/getOrderList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let categoryObj = {
                getOrderLists: data.data
            }
            console.log("categoryObjcategoryObjcategoryObjcategoryObj", data.data);
            return categoryObj;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            // if (response.status === 401) {
            //     // logout();
            // }
            const error = (data && data.msg) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            const error = (data && data.msg) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}