import React from "react";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";
// import { Menu, Transition } from "@headlessui/react";

// function classNames(...classes) {
  //   return classes.filter(Boolean).join(" ");
  // }
export default function Header (props) {

  
  // const onClickMenu = (url) => {
  //   this.props.history.push(url);
  // };
  let history = useHistory()

  return (
    <>

      <footer className="bg-gray-900" aria-labelledby="footerHeading">
        <h2 id="footerHeading" className="sr-only">Footer</h2>
        <div className="px-2 mx-auto max-w-screen-2xl sm:px-4 lg:px-8">
          <div className="py-10 border-b border-white border-opacity-10 flex justify-between xl:mt-0">
            <div className="flex items-center flex-shrink-0 space-x-4">
              <img
                className="block w-auto h-12"
                src="dist/img/logo-opt-2.svg"
                alt="algems"
              />
            </div>
            <form className="flex-1  px-5 flex ">
              <div className="flex w-full max-w-3xl mx-auto">
                <label htmlFor="search" className="sr-only">
                  Email address
                </label>
                <div className="relative flex w-full">
                  <input
                    id="emailAddress"
                    name="emailAddress"
                    className="block w-full py-2 pl-5 text-sm font-light text-white placeholder-gray-300 bg-white border border-transparent rounded shadow-inner bg-opacity-10 pr-14 focus:outline-none focus:text-primary focus:placeholder-gray-400 focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm"
                    placeholder="Enter your email"
                    type="email"
                  />

                  <button className="absolute inset-y-0 right-0 flex items-center pl-4 pr-3 text-white rounded-r cursor-pointer bg-primary hover:bg-primary-500 focus:outline-none">
                    {/* Heroicon name: solid/search */}
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 transform rotate-45 relative -top-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                    </svg>
                  </button>
                </div>
              </div>



            </form>

            <div className="flex items-center  justify-center space-x-3 ">
              <span className="flex items-center justify-center w-12 h-12 p-2 text-white rounded cursor-pointer bg-primary hover:bg-primary-500">
                <span className="sr-only">Facebook</span>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                </svg>
              </span>
              <span className="flex items-center justify-center w-12 h-12 p-2 text-white rounded cursor-pointer bg-primary hover:bg-primary-500">
                <span className="sr-only">Instagram</span>


                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" >
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C7.28417 0 6.94417 0.0116667 5.8775 0.06C2.24583 0.226667 0.2275 2.24167 0.0608333 5.87667C0.0116667 6.94417 0 7.28417 0 10C0 12.7158 0.0116667 13.0567 0.06 14.1233C0.226667 17.755 2.24167 19.7733 5.87667 19.94C6.94417 19.9883 7.28417 20 10 20C12.7158 20 13.0567 19.9883 14.1233 19.94C17.7517 19.7733 19.775 17.7583 19.9392 14.1233C19.9883 13.0567 20 12.7158 20 10C20 7.28417 19.9883 6.94417 19.94 5.8775C19.7767 2.24917 17.7592 0.2275 14.1242 0.0608333C13.0567 0.0116667 12.7158 0 10 0ZM10 4.865C7.16417 4.865 4.865 7.16417 4.865 10C4.865 12.8358 7.16417 15.1358 10 15.1358C12.8358 15.1358 15.135 12.8367 15.135 10C15.135 7.16417 12.8358 4.865 10 4.865ZM10 13.3333C8.15917 13.3333 6.66667 11.8417 6.66667 10C6.66667 8.15917 8.15917 6.66667 10 6.66667C11.8408 6.66667 13.3333 8.15917 13.3333 10C13.3333 11.8417 11.8408 13.3333 10 13.3333ZM15.3383 3.4625C14.675 3.4625 14.1375 4 14.1375 4.6625C14.1375 5.325 14.675 5.8625 15.3383 5.8625C16.0008 5.8625 16.5375 5.325 16.5375 4.6625C16.5375 4 16.0008 3.4625 15.3383 3.4625Z" />
                </svg>




              </span>
              <span className="flex items-center justify-center w-12 h-12 p-2 text-white rounded cursor-pointer bg-primary hover:bg-primary-500">
                <span className="sr-only">Twitter</span>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </span>
              <span className="flex items-center justify-center w-12 h-12 p-2 text-white rounded cursor-pointer bg-primary hover:bg-primary-500">
                <span className="sr-only">Google</span>

                <svg className="flex items-center justify-center w-4 h-4" fill="currentColor" viewBox="0 0 20 21" >
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2033 8.74572V12.244H15.9901C15.7569 13.7439 14.2409 16.646 10.2033 16.646C6.71963 16.646 3.87727 13.7614 3.87727 10.2033C3.87727 6.64675 6.71963 3.76066 10.2033 3.76066C12.1857 3.76066 13.5121 4.60608 14.2701 5.33489L17.0396 2.66744C15.2613 1.00576 12.9582 0 10.2033 0C4.56235 0 0 4.56235 0 10.2033C0 15.8443 4.56235 20.4067 10.2033 20.4067C16.0921 20.4067 20 16.267 20 10.4366C20 9.76605 19.9257 9.25589 19.8382 8.74572H10.2033Z" />
                </svg>




              </span>
              <span className="flex items-center justify-center w-12 h-12 p-2 text-white rounded cursor-pointer bg-primary hover:bg-primary-500">
                <span className="sr-only">youtube</span>



                <svg className="flex items-center justify-center w-5 h-5" fill="currentColor" viewBox="0 0 20 15" >
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3458 0.153438C13.3425 -0.0515623 6.65333 -0.050729 3.65417 0.153438C0.406667 0.375104 0.0241667 2.33677 0 7.5001C0.0241667 12.6543 0.403333 14.6243 3.65417 14.8468C6.65417 15.0509 13.3425 15.0518 16.3458 14.8468C19.5933 14.6251 19.9758 12.6634 20 7.5001C19.9758 2.34594 19.5967 0.375938 16.3458 0.153438ZM7.5 10.8334V4.16677L14.1667 7.49427L7.5 10.8334Z" />
                </svg>





              </span>
            </div>


          </div>

          <div className="grid grid-cols-12 gap-8 pt-10 pb-8">
            <div className="grid grid-cols-8 col-span-10 gap-8">
              <div className="grid col-span-3 md:grid-cols-2 md:gap-8">
                  {/* <div>
                    <h3 className="font-medium tracking-tight text-gray-400 uppercase">
                      Main Categories
                    </h3>
                    <ul className="mt-4 space-y-4 text-sm">
                      <li>
                        <span className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                          Precious Stone
                        </span>
                      </li>
                      <li>
                        <span className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                          Semi Precious
                        </span>
                      </li>
                      <li>
                        <span className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                          Shafire
                        </span>
                      </li>
                      <li>
                        <span className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                          Ruby
                        </span>
                      </li>
                      <li>
                        <span className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                          Emraled
                        </span>
                      </li>
                    </ul>
                  </div> */}
                <div className="mt-12 md:mt-0 px-8">
                  <h3 className="font-medium tracking-tight text-gray-400 uppercase">
                    Company
                  </h3>
                  <ul className="mt-4 space-y-4">
                    <li>
                      <span className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        About Us
                      </span>
                    </li>
                    <li>
                      <span className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        Find a Store
                      </span>
                    </li>
                    <li>
                      <span className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        Rules and Terms
                      </span>
                    </li>
                    <li>
                      <span className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        Sitemap
                      </span>
                    </li>
                    <li>
                      <span className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        Career
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="grid col-span-5 md:grid-cols-3 md:gap-8">
                <div>
                  <h3 className="font-medium tracking-tight text-gray-400 uppercase">
                    Help
                  </h3>
                  <ul className="mt-4 space-y-4">
                    <li>
                      <span onClick={() => history.push('/contact')}  className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500 ">
                        Contact Us
                      </span>
                    </li>
                    <li>
                      <span className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        Money Refund
                      </span>
                    </li>
                    <li>
                      <span onClick={() => history.push('/order')}className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        Order Status
                      </span>
                    </li>
                    <li>
                      <span className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        Shipping Info
                      </span>
                    </li>
                    <li>
                      <span onClick={() => history.push('/contact')} className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        Open Dispute
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="font-medium tracking-tight text-gray-400 uppercase">
                    Account
                  </h3>
                  <ul className="mt-4 space-y-4">
                    <li >
                      <span onClick={() => history.push('/login')} className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        Login
                      </span>
                    </li>
                    <li>
                      <span onClick={() => history.push('/signup')} className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        Register
                      </span>
                    </li>
                    <li>
                      <span onClick={() => history.push('/dashboard')} className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        Account Setting
                      </span>
                    </li>
                    <li>
                      <span onClick={() => history.push('/order')} className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        My Orders 
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="mt-12 md:mt-0">
                  <h3 className="font-medium tracking-tight text-gray-400 uppercase">
                    Resources
                  </h3>
                  <ul className="mt-4 space-y-4">
                    <li>
                      <span className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        Documents
                      </span>
                    </li>
                    <li>
                      <span className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        Webinars
                      </span>
                    </li>
                    <li>
                      <span className="text-gray-600 transition duration-300 ease-in-out cursor-pointer hover:text-gray-500">
                        Product Samples
                      </span>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div className="col-span-2 mt-12 xl:mt-0">
              <h3 className="font-medium tracking-tight text-gray-400 uppercase">
                Language &amp; Currency
              </h3>
              <form className="mt-4 sm:max-w-xs">
                <fieldset className="w-full">
                  <label htmlFor="language" className="sr-only">Language</label>
                  <div className="relative">
                    <select id="language" name="language" className="block w-full py-2 pl-3 pr-10 text-base text-gray-500 bg-white border border-transparent rounded-md shadow-inner appearance-none bg-none bg-opacity-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm">
                      <option selected>English</option>
                      <option>French</option>
                      <option>German</option>
                      <option>Japanese</option>
                      <option>Spanish</option>
                    </select>
                    <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                      {/* Heroicon name: solid/chevron-down */}
                      <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                </fieldset>
                <fieldset className="w-full mt-4">
                  <label htmlFor="currency" className="sr-only">Currency</label>
                  <div className="mt-1.5 relative">
                    <select id="currency" name="currency" className="block w-full py-2 pl-3 pr-10 text-base text-gray-500 bg-white border border-transparent rounded-md shadow-inner appearance-none bg-none bg-opacity-10 focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm">
                      <option>ARS</option>
                      <option selected>AUD</option>
                      <option>CAD</option>
                      <option>CHF</option>
                      <option>EUR</option>
                      <option>GBP</option>
                      <option>JPY</option>
                      <option>USD</option>
                    </select>
                    <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                      {/* Heroicon name: solid/chevron-down */}
                      <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
          <div className="py-5 border-t border-white border-opacity-10 md:flex md:items-center md:justify-between">
            <div className="flex space-x-6 md:order-2">
              <span className="cursor-pointer ">
                <img src="dist/img/Appstore.svg" alt="AppStore" />
              </span>
              <span className="cursor-pointer ">
                <img src="dist/img/Playstore.svg" alt="AppStore" />
              </span>

            </div>
            <p className="text-sm text-gray-600 md:mt-0 md:order-1">
              © 2021 Algems, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>


    </>
  );
}
