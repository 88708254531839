import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import Header from "../Header";
import Dashboard from "../../pages/dashboard";
import Profile from "../../pages/profile";
import Contactus from "../../pages/contactus";
import Orderhistory from "../../pages/Orderhistory/Orderhistory";
import Wishlist from "../../pages/Wishlist/Wishlist";

// import Sidebar from "../Sidebar";

function Layout(props) {
  return (
    <div >
      <>
        <div className="overflow-hidden">
          <div className="bg-gray-100 ">
            <div className="flex flex-col justify-center min-h-screen">
              <div className="flex overflow-hidden" >
                {/* <Sidebar /> */}
                <div className="flex flex-col flex-1 w-0 overflow-hidden">
                  <Header history={props.history} />
                  <Switch>
                    <Route path="/contactus" component={withRouter(Contactus)} />
                    <Route path="/contactus" component={withRouter(Contactus)} />
                    <Route path="/app/dashboard" component={withRouter(Dashboard)} />
                    <Route path="/app/profile" component={withRouter(Profile)} />
                    <Route path="/app/orderhistory" component={withRouter(Orderhistory)} />
                    <Route path="/app/wishlist" component={withRouter(Wishlist)} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
