import React, { Component } from "react";
import { connect } from 'react-redux';
import Sticky from "wil-react-sticky";
import { userActions } from "../../_actions";
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import PublicFooter from "../../components/PublicFooter/PublicFooter";

// import { userActions } from '../../_actions';

class Wishlist extends Component {
  constructor(props) {
    super(props);
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.loginValidateOtp = this.loginValidateOtp.bind(this);
    this.state = {
      email: '',
      password: '',
      otp: ''
    }
  }
  onClickMenu = (url, data) => {
    this.props.history.push(url + data.id);
  }
  componentDidMount() {
    let tempData = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    // this.props.dispatch(userActions.getWishList(tempData));
  }



  removeFromWishList = (data) => {

    let wishProducts = JSON.parse(localStorage.getItem("wishProducts"));
    if (wishProducts && wishProducts.length > 0) {
      let currentPrductindex = wishProducts.filter((element) => (element.id !== data.id));
      localStorage.setItem("wishProducts", JSON.stringify(currentPrductindex))
      window.location.reload();
    }
  }

  addToWishList = (data) => {
    console.log("+++++++++++++++++++", data);
    let wishProducts = JSON.parse(localStorage.getItem("wishProducts"));
    console.log("localStorage____wishProducts::", wishProducts);
    // {
    //     JSON.stringify(wishProducts)   
    // }
    if (wishProducts && wishProducts.length > 0) {
      let currentPrductindex = wishProducts.findIndex((element) => (element.id === data.id));

      if (currentPrductindex >= 0) {

        wishProducts[currentPrductindex]['count'] += 1;
        let { product, amount } = wishProducts[currentPrductindex];

        console.log("wishProducts[product]:::", product.newPrice);

        wishProducts[currentPrductindex].subTotal = wishProducts[currentPrductindex].count * product.newPrice

        amount = wishProducts[currentPrductindex].subTotal;
        console.log("currentPrductindex::::::::::::  ", amount);

        localStorage.setItem("wishProducts", JSON.stringify(wishProducts))
        window.location.reload();


      } else {
        let product = {
          id: data.id,
          product: data,
          count: 1,
          subTotal: data.newPrice,
          grandtotal: data.newPrice
        }
        wishProducts.push(product)
        localStorage.setItem("wishProducts", JSON.stringify(wishProducts))
        window.location.reload();
      }
    } else {
      let product = [{
        id: data.id,
        product: data,
        count: 1,
      }]
      localStorage.setItem("wishProducts", JSON.stringify(product))
      window.location.reload();
    }
    console.log("")
    console.log("wishProductswishProductswishProducts ", wishProducts);

    localStorage.setItem("wishProducts", JSON.stringify(wishProducts))

    let totalAmount = wishProducts.reduce((a, b) => ({ subTotal: a.subTotal + b.subTotal }));
    console.log("totalAmounttotalAmount  ", totalAmount);
    this.setState({ wishProducts })
    this.setState({ totalAmount: totalAmount })

  }


  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }
  loginSubmit(e) {
    e.preventDefault();
    // let { email, password } = this.state;
    //this.props.dispatch(userActions.login({ email: email, password }, this.props));
  }
  loginValidateOtp(e) {
    e.preventDefault();
    // let { email, password, otp } = this.state;
    //this.props.dispatch(userActions.loginValidateOtp({ email: email, password, otp }, this.props));
  }
  render() {
    let { user, otpSent } = this.props;
    console.log("user  ", user);
    console.log("otpSent  ", otpSent);

    return (
      <>
        <div className="overflow-hidden">
          {/* Headdr */}
          <PublicHeader />

          <main className="bg-gray-50">
            <section className="py-4  ">

              <div className="max-w-screen-2xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8 pb-5 sm:pb-20 lg:pb-24">


                <div className="flex flex-wrap justify-between lg:flex-row  cart-container">
                  <div className="w-full sm:w-3/4  ">
                    <div className="mt-5">
                      {/* Cart Heading */}
                      <div className="mb-4 text-left heading ">
                        <h2 className="pb-2 text-left text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ">Wishlist Items</h2>
                        <p className="text-sm text-left text-gray-400 mt-2">All carted items</p>
                      </div>

                      {/* Cart items wrapper */}
                      <div className="md:pt-4">
                        {/* Cart Items header*/}

                        <div className="hidden md:flex justify-between border-b border-black border-opacity-20 mr-10">
                          <div className="w-1/2 pb-2 lg:w-3/5 xl:w-1/2 ">
                            <span className="text-sm uppercase font-normal text-gray-600 ">Product</span>
                          </div>

                          <div className="w-1/5 pb-2 pl-14 text-left ">
                            <span className="text-sm uppercase font-normal text-gray-600 ">Price</span>
                          </div>


                          <div className="w-1/5 pb-2 text-right  ">
                            <span className="text-sm uppercase font-normal text-gray-600 ">Delete</span>
                          </div>


                        </div>
                        {
                          localStorage.getItem("wishProducts") && JSON.parse(localStorage.getItem("wishProducts")) && JSON.parse(localStorage.getItem("wishProducts")).map((element, index) => (
                            <>
                              <div className="max-h-[500px] overflow-auto pr-10">
                                <div className="flex flex-wrap lg:flex-nowrap justify-between items-center relative py-4 lg:py-8 border-b border-black border-opacity-20">
                                  <div className="md:w-1/2 w-full flex items-center ">
                                    <div className="w-20 pr-0 mx-0">
                                      <div className="flex items-center justify-center h-20 rounded bg-gray-100">
                                        <div className="w-full  aspect-w-1 cursor-pointer aspect-h-1">
                                          <img src={element && element.wishProduct && element.wishProduct.imageLink} alt="productImage"
                                            onClick={() => this.onClickMenu("/productDetails/", element)}
                                            className="object-cover" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="">
                                      <span className="mt-2 ml-4 text-sm lg:text-base font-bold text-black block ">{element && element.wishProduct && element.wishProduct.name}</span>
                                      {/* <span className="ml-4 text-sm  font-normal text-gray-400 block ">Rs.2,500</span> */}
                                    </div>
                                  </div>

                                  <div className="w-1/5  text-left  flex flex-wrap items-center mt-3">
                                    <span className="block w-full text-sm md:hidden mb-1">Price</span>
                                    <span className="text-base uppercase font-medium text-black">{element && element.wishProduct && element.wishProduct.newPrice}</span>
                                  </div>
                                  <div className="flex items-center ">
                                    <button
                                      onClick={() => this.removeFromWishList(element)}
                                      className="hover:text-red-600 text-gray-400 cursor-pointer w-8 h-8 rounded bg-white flex justify-center items-center buzz-out transition hover:bg-red-50">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" id="Outline" viewBox="0 0 24 24"
                                        className="transition w-4">
                                        <path
                                          d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z">
                                        </path>
                                        <path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z"></path>
                                        <path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"></path>
                                      </svg>
                                    </button>

                                  </div>
                                </div>
                              </div>
                            </>
                          ))
                        }


                      </div>

                      {/* Cuntinue Shopping button */}
                      <div className="flex justify-start items-end pt-5 space-x-5" onClick={() => this.onClickMenu("/home")}>
                        <button className="px-10 text-center py-3 text-white uppercase text-base rounded hover:bg-primary-500 bg-primary block transition ease-in-out duration-150">Continue shopping</button> </div>
                    </div>
                  </div>
                </div>

              </div>

            </section>

          </main>

          {/* Footer */}
          <PublicFooter />
        </div>
      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSent } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    otpSent,
    user,
    users
  };
}
export default connect(mapStateToProps)(Wishlist);
