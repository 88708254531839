import React, { Component } from "react";
import { connect } from "react-redux";
// import { userActions} from "../../_actions";
import { homeActions, } from "../../_actions";
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import PublicFooter from "../../components/PublicFooter/PublicFooter";
// import React from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const hero = {
  dots: true,
  dotsClass: "hero-dot absolute inset-x-0 bottom-5 space-x-3 justify-center",
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,

};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      keyWord: "",
      pageNo: 1,
      size: 50

    };
  }

  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    // this.props.dispatch(homeActions.getAllCategory(data));   
    //  this.props.dispatch(homeActions.getAllSlider(data));
    // this.props.dispatch(homeActions.getTopMostCategoryList(data));
    // this.props.dispatch(homeActions.getCategoryList(data));
    // this.props.dispatch(homeActions.getFeaturedList(data));
    this.props.dispatch(homeActions.getArrivalsList(data));
    this.props.dispatch(homeActions.getAllSlider(data));
    // this.props.dispatch(homeActions.getProductList(data));`````````````````````````````````````````````````````````````````````
    // this.props.dispatch(homeActions.getReviewList(data));
  }

  onClickMenu = (url, data) => {
    console.log("datadatadata", data);
    this.props.history.push(url + data.id);
  };


  addToWishList = (data) => {
    console.log("data  ", data);
    let wishProducts = JSON.parse(localStorage.getItem("wishProducts"));
    if (wishProducts && wishProducts.length > 0) {
      let currentPrductindex = wishProducts.findIndex((element) => (element.id === data.id));
      console.log("currentPrductindex  ", currentPrductindex);
      console.log("wishProducts  ", wishProducts);

      if (currentPrductindex >= 0) {
        // wishProducts[currentPrductindex]['count'] += 1;
        localStorage.setItem("wishProducts", JSON.stringify(wishProducts))
        // window.location.reload();
      } else {
        let wishProduct = {
          id: data.id,
          wishProduct: data,
          count: 1
        }
        wishProducts.push(wishProduct)
        localStorage.setItem("wishProducts", JSON.stringify(wishProducts))
        window.location.reload();
      }
    } else {
      let wishProduct = [{
        id: data.id,
        wishProduct: data,
        count: 1
      }]
      localStorage.setItem("wishProducts", JSON.stringify(wishProduct))
      // window.location.reload();
    }
  }



  addToCart = (data) => {
    console.log("data  ", data);
    let products = JSON.parse(localStorage.getItem("products"));
    if (products && products.length > 0) {
      let currentPrductindex = products.findIndex((element) => (element.id === data.id));
      console.log("currentPrductindex  ", currentPrductindex);
      console.log("products  ", products);

      if (currentPrductindex >= 0) {
        // products[currentPrductindex]['count'] += 1;
        localStorage.setItem("products", JSON.stringify(products))
        // window.location.reload();
      } else {
        let product = {
          id: data.id,
          product: data,
          count: 1
        }
        products.push(product)
        localStorage.setItem("products", JSON.stringify(products))
        window.location.reload();
      }
    } else {
      let product = [{
        id: data.id,
        product: data,
        count: 1
      }]
      localStorage.setItem("products", JSON.stringify(product))
      // window.location.reload();
    }
  }

  removeFromCart = (data) => {

    let products = JSON.parse(localStorage.getItem("products"));
    if (products && products.length > 0) {
      let currentPrductindex = products.filter((element) => (element.id !== data.id));
      localStorage.setItem("products", JSON.stringify(currentPrductindex))
    }
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    console.log("value kya aa rhi h::::", value);
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(homeActions.getArrivalsList(data));
  }

  render() {

    let { home } = this.props;
    let { GetArrivalsList, getAllSliderList } = home;
    // let { TopMostCategoryList, GetFeaturedList, GetArrivalsList, getAllSliderList } = home;

    console.log("getAllSliderList_____________:", getAllSliderList)
    // let { CategoryList, ProductItems, ReviewItems } = home;

    return (
      <>
        {/* Whole Structure */}
        <div className="overflow-hidden">
          {/* Headdr */}
          <PublicHeader />

          <main className="">
            {/* Hero Section */}
            <section className="relative z-20 items-center  overflow-hidden bg-gray-300">
              <Slider {...hero}>
                {
                  getAllSliderList && getAllSliderList.length > 0 ?
                    getAllSliderList.map((element, index) =>
                    (<React.Fragment >
                      <div className=" relative bg-gradient-to-br from-purple-400 via-pink-500 to-red-500">
                        <div className="absolute inset-0 flex justify-end">
                          {/* <img src="dist/img/bg-effect.svg" alt="hero background" /> */}
                          {/* <img src={element.imageLink} alt="Gems Stone" /> */}

                        </div>
                        <div className="relative flex px-6 mx-auto max-w-screen-2xl">
                          <div className="grid items-center grid-cols-12 gap-10">
                            <div className="relative z-20 flex flex-col col-span-8 py-10">
                              <h1 className="flex flex-col text-4xl font-semibold leading-snug text-white font-headings sm:text-5xl lg:text-6xl sm:leading-snug lg:leading-tight dark:text-white">
                                {element.name}
                              </h1>

                              {/* <div className="flex mt-8">
                                <button type="button" className="inline-flex items-center px-8 py-3 text-base font-medium transition duration-300 ease-in-out bg-white rounded-md shadow-sm text-primary hover:bg-gray-100 focus:outline-none">
                                  Buy Now
                                </button>
                              </div> */}
                            </div>
                            <div className="relative hidden  col-span-4  sm:block">
                              <img src={element.imageLink}
                                className="m-auto"
                                alt="Gems stone"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>))
                    :
                    (<div className="bg-white bg-opacity-5 " >
                      <span className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Product Not Found</span>
                    </div>)
                }

              </Slider>
            </section>


            {/* New Arrivals */}
            <section className="z-20  flex justify-center dark:bg-gray-800 pt-10">
              <div className="relative px-6 pb-16 mx-auto max-w-screen-2xl">
                <div className="relative mb-10">
                  <h2 className="text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl ">New Arrivals</h2>
                  <p className="mt-2 text-sm text-center text-gray-400">Upcoming & New Gemstones </p>
                </div>

                <ul className="grid grid-cols-2 gap-x-2 gap-y-8 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8">
                  {
                    GetArrivalsList && GetArrivalsList.length > 0 ?
                      GetArrivalsList.map((element, index) => (<React.Fragment >

                        <li className="inline-flex flex-col relative p-4 w-[350px]">
                          <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                            <img src={element && element.imageLink ? element.imageLink : ""} alt="" className="object-cover pointer-events-none group-hover:opacity-75 border border-blue-300" />
                            <button type="button" className="absolute inset-0 cursor-pointer focus:outline-none" onClick={() => this.onClickMenu("/productDetails/", element)}>
                              <span className="sr-only">View details for IMG_4985.HEIC </span>
                            </button>
                          </div>
                          <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">{element.name}</p>
                          <div className="flex items-center py-2 space-x-3">
                            <div className="flex items-center space-x-1 ">
                              <svg className="w-3 h-3 text-gray-900 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                              </svg>
                              <svg className="w-3 h-3 text-gray-900 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                              </svg>
                              <svg className="w-3 h-3 text-gray-900 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                              </svg>
                              <svg className="w-3 h-3 text-gray-900 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                              </svg>
                              <svg className="w-3 h-3 text-gray-300 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                              </svg>
                            </div>
                            <span className="text-sm font-light">{element.rating}</span>
                          </div>

                          <div className="flex items-center justify-between">
                            <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                              <strong className="font-semibold">{element.newPrice}</strong>
                              <span className="text-sm font-light text-gray-500 line-through">{element.oldPrice}</span>
                            </p>

                            <div className="flex space-x-1">
                              <button onClick={() => this.addToCart(element)} className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary" id="headlessui-menu-button-14" type="button" aria-haspopup="true">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                </svg>
                              </button>
                              <button onClick={() => this.addToWishList(element)} className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary" id="headlessui-menu-button-14" type="button" aria-haspopup="true">

                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </li>{" "}

                      </React.Fragment>))
                      : (<div className="bg-white bg-opacity-5 " >
                        <span className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Product Not Found</span>
                      </div>)
                  }
                  {" "}
                </ul>
              </div>
            </section>









            {/* Categories */}
            {/* <section className="relative z-20 items-center overflow-hidden bg-gray-50 dark:bg-gray-800">
              <div className="relative px-6 py-16 mx-auto max-w-screen-2xl">
                <div className="relative mb-14">
                  <h2 className="text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl">
                    Top most Categories
                  </h2>
                  <p className="mt-2 text-sm text-center text-gray-400">
                    {" "}
                    Buy Gemstones Online at Best Prices
                  </p>
                </div>
                <div className="grid grid-cols-12 gap-x-5 gap-y-10">
                  {
                    TopMostCategoryList && TopMostCategoryList.length > 0 ?
                      TopMostCategoryList.map((element, index) =>
                      (<React.Fragment >
                        <div className="relative flex items-center col-span-3 space-x-4 truncate">
                          <div className="flex items-center justify-center flex-shrink-0 w-20 h-20 p-4 overflow-hidden bg-white rounded-full shadow-6">
                            <img
                              className="object-cover w-auto h-auto max-w-full max-h-full rounded-full"
                              src="dist/img/YellowSapphire.png"
                              alt=""
                            />
                          </div>
                          <div className="flex flex-col flex-1 min-w-0 cursor-pointer focus:outline-none">
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            ></span>
                            <h4 className="text-lg font-normal leading-none tracking-wide text-gray-800 truncate">{element.name}</h4>
                            <p className="pb-1 text-sm font-normal tracking-wide text-gray-400 truncate ">{element.description}</p>
                            <p className="text-sm font-semibold">
                              <span className="text-gray-500">Start at: </span>{element.startAt}
                            </p>
                          </div>
                        </div>
                      </React.Fragment>))
                      :
                      (<div className="bg-white bg-opacity-5 " >
                        <span className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Product Not Found</span>
                      </div>)
                  }
                </div>
              </div>
            </section> */}

            {/* Feature Collection */}
            {/* <section className="relative z-20 items-center overflow-hidden dark:bg-gray-800">
              <div className="relative px-6 py-16 mx-auto max-w-screen-2xl">
                <div className="relative mb-10">
                  <h2 className="text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl">
                    Featured Collection
                  </h2>
                  <p className="mt-2 text-sm text-center text-gray-400">
                    Best selling Gemstones
                  </p>
                </div>
                <div className="overflow-hidden">
                  <ul className="pb-5 -mx-4 overflow-auto scrolling-touch whitespace-nowrap max-w-none scrollbar-w-4">

                    {
                      GetFeaturedList && GetFeaturedList.length > 0 ?
                        GetFeaturedList.map((element, index) => (<React.Fragment >

                          <li className="inline-flex flex-col relative p-4 w-[350px]">
                            <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                              <img
                                src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                                alt=""
                                className="object-cover pointer-events-none group-hover:opacity-75"
                              />
                              <button
                                type="button"
                                className="absolute inset-0 cursor-pointer focus:outline-none"
                              >
                                <span className="sr-only">
                                  View details for IMG_4985.HEIC
                                </span>
                              </button>
                            </div>
                            <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">{element.name}</p>
                            <div className="flex items-center py-2 space-x-3">
                              <div className="flex items-center space-x-1 ">
                                <svg
                                  className="w-3 h-3 text-gray-900 fill-current"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                                </svg>
                                <svg
                                  className="w-3 h-3 text-gray-900 fill-current"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                                </svg>
                                <svg
                                  className="w-3 h-3 text-gray-900 fill-current"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                                </svg>
                                <svg
                                  className="w-3 h-3 text-gray-900 fill-current"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                                </svg>
                                <svg
                                  className="w-3 h-3 text-gray-300 fill-current"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                                </svg>
                              </div>
                              <span className="text-sm font-light">{element.rating}</span>
                            </div>

                            <div className="flex items-center justify-between">
                              <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                                <strong className="font-semibold">{element.newPrice}</strong>
                                <span className="text-sm font-light text-gray-500 line-through">{element.oldPrice}</span>
                              </p>

                              <div className="flex space-x-1">
                                <button
                                  className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                                  id="headlessui-menu-button-14"
                                  type="button"
                                  aria-haspopup="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-5 h-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      stroke-width="2"
                                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                                    />
                                  </svg>
                                </button>
                                <button
                                  className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                                  id="headlessui-menu-button-14"
                                  type="button"
                                  aria-haspopup="true"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-5 h-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      stroke-width="2"
                                      d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </li>{" "}

                        </React.Fragment>))
                        : (<div className="bg-white bg-opacity-5 " >
                          <span className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Product Not Found</span>
                        </div>)
                    }
                  </ul>
                </div>
              </div>
            </section> */}


            {/* Special Points */}
            {/* <section className="relative z-20 items-center overflow-hidden bg-gray-50 dark:bg-gray-800">
             
              <div className="absolute bottom-0 left-0">
                <img src="dist/img/bg-rock-left.png" alt="rock" />{" "}
              </div>
              <div className="absolute right-0 rop-0">
                <img src="dist/img/bg-rock-right.png" alt="rock" />{" "}
              </div>

              <div className="relative z-10 px-6 py-16 mx-auto max-w-screen-2xl">
                <div className="relative mb-10">
                  <h2 className="text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl ">
                    What is algems?
                  </h2>
                  <p className="mt-2 text-sm text-center text-gray-400">
                    Read our passion and determination
                  </p>
                </div>

                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  <div className="flex flex-col items-center max-w-sm mx-auto space-y-3 text-center">
                    <h3 className="text-2xl font-medium tracking-tight text-gray-900 font-headings">
                      A one-of-a-kind community
                    </h3>
                    <p className="text-sm text-gray-600">
                      Etsy is a global online marketplace, where people come
                      together to make, sell, buy and collect unique items.
                    </p>
                  </div>
                  <div className="flex flex-col items-center max-w-sm mx-auto space-y-3 text-center">
                    <h3 className="text-2xl font-medium tracking-tight text-gray-900 font-headings">
                      Support independent creators
                    </h3>
                    <p className="text-sm text-gray-600">
                      There’s no Etsy warehouse – just millions of people
                      selling the things they love. We make the whole process
                      easy, helping you connect directly with makers to find
                      something extraordinary.
                    </p>
                  </div>

                  <div className="flex flex-col items-center max-w-sm mx-auto space-y-3 text-center">
                    <h3 className="text-2xl font-medium tracking-tight text-gray-900 font-headings">
                      Peace of mind
                    </h3>
                    <p className="text-sm text-gray-600">
                      Your privacy is the highest priority of our dedicated
                      team. And if you ever need assistance, we are always ready
                      to step in for support.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col items-center max-w-sm mx-auto mt-16 space-y-4 text-center ">
                  <h3 className="text-2xl font-medium tracking-tight text-gray-900 font-headings">
                    Have a question?
                  </h3>
                  <p className="text-sm text-gray-600">
                    Well, we’ve got some answers.
                  </p>

                  <button
                    type="button"
                    className="inline-flex items-center px-6 py-3 text-center border border-transparent text-xl font-normal rounded-md shadow-sm text-white bg-primary hover:bg-primary-500 min-w-[10rem] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center "
                  >
                    Contact us
                  </button>
                </div>
              </div>
            </section> */}

            {/* Categories with colors */}
            {/* <section className="relative z-20 items-center overflow-hidden dark:bg-gray-800">
              <div className="relative px-6 py-16 mx-auto max-w-screen-2xl">
                <ul className="grid grid-cols-2 gap-8 sm:grid-cols-3 lg:grid-cols-4 ">
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-vintage-400 to-vintage-300"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Yellow Sapphire
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-pink-400 to-pink-300"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Blue Sapphire
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-cold-600 to-cold-400"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Emerald
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-green-300 to-green-200"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Ruby
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-navy-500 to-navy"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Yellow Sapphire
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-yellow-600 to-yellow-500"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Blue Sapphire
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-blue-600 to-blue-400"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Emerald
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-red-400 to-red-300"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Ruby
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-vintage-400 to-vintage-300"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Yellow Sapphire
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-pink-400 to-pink-300"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Blue Sapphire
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-cold-600 to-cold-400"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Emerald
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-green-300 to-green-200"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Ruby
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-navy-500 to-navy"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Yellow Sapphire
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-yellow-600 to-yellow-500"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Blue Sapphire
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-blue-600 to-blue-400"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Emerald
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="relative">
                    <div
                      className="flex items-center justify-center w-full h-56 overflow-hidden group rounded-3xl bg-gradient-to-tr from-red-400 to-red-300"
                    >
                      <div className="space-y-3">
                        <h4 className="text-2xl font-medium text-white capitalize truncate">
                          Ruby
                        </h4>
                        <p className="font-medium text-white pointer-events-none">
                          $45.10
                        </p>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 text-sm font-medium leading-4 text-gray-900 transition duration-300 ease-in-out bg-white border border-white rounded shadow-sm hover:bg-transparent focus:outline-none hover:text-white"
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </li>

                </ul>
              </div>
            </section> */}

            {/* Site, category, product, terms etc.. */}
            {/* <section className="relative z-20 items-center overflow-hidden bg-gray-50 dark:bg-gray-800">
              <div className="relative px-6 py-16 mx-auto max-w-screen-2xl">
                <div className="relative py-16 overflow-hidden bg-white shadow-6">
                  <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto text-sm prose prose-lg max-w-none">
                      <h2>
                        <span className="block text-base font-semibold tracking-wide text-center uppercase text-primary">
                          Introducing
                        </span>
                        <span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
                          How to buy and sell Gemstones with us?
                        </span>
                      </h2>
                      <p className="mt-8 leading-8 text-gray-500">
                        Aliquet nec orci mattis amet quisque ullamcorper neque,
                        nibh sem. At arcu, sit dui mi, nibh dui, diam eget
                        aliquam. Quisque id at vitae feugiat egestas ac. Diam
                        nulla orci at in viverra scelerisque eget. Eleifend
                        egestas fringilla sapien.
                      </p>
                    </div>
                    <div className="mx-auto mt-6 prose-sm prose text-gray-500 prose-pink max-w-none">
                      <p>
                        Faucibus commodo massa rhoncus, volutpat.{" "}
                        <strong>Dignissim</strong> sed{" "}
                        <strong>eget risus enim</strong>. Mattis mauris semper
                        sed amet vitae sed turpis id. Id dolor praesent donec
                        est. Odio penatibus risus viverra tellus varius sit
                        neque erat velit. Faucibus commodo massa rhoncus,
                        volutpat. Dignissim sed eget risus enim.{" "}
                        <a href="!#">Mattis mauris semper</a>{" "}
                        sed amet vitae sed turpis id.
                      </p>
                      <ul>
                        <li>Quis elit egestas venenatis mattis dignissim.</li>
                        <li>
                          Cras cras lobortis vitae vivamus ultricies facilisis
                          tempus.
                        </li>
                        <li>
                          Orci in sit morbi dignissim metus diam arcu pretium.
                        </li>
                      </ul>
                      <p>
                        Quis semper vulputate aliquam venenatis egestas sagittis
                        quisque orci. Donec commodo sit viverra aliquam
                        porttitor ultrices gravida eu. Tincidunt leo, elementum
                        mattis elementum ut nisl, justo, amet, mattis. Nunc
                        purus, diam commodo tincidunt turpis. Amet, duis sed
                        elit interdum dignissim.
                      </p>
                      <h4 className="text-xl text-gray-700">
                        From beginner to expert in 30 days
                      </h4>
                      <p>
                        Id orci tellus laoreet id ac. Dolor, aenean leo, ac
                        etiam consequat in. Convallis arcu ipsum urna nibh.
                        Pharetra, euismod vitae interdum mauris enim, consequat
                        vulputate nibh. Maecenas pellentesque id sed tellus
                        mauris, ultrices mauris. Tincidunt enim cursus ridiculus
                        mi. Pellentesque nam sed nullam sed diam turpis ipsum eu
                        a sed convallis diam.
                      </p>
                      <blockquote>
                        <p>
                          Sagittis scelerisque nulla cursus in enim consectetur
                          quam. Dictum urna sed consectetur neque tristique
                          pellentesque. Blandit amet, sed aenean erat arcu
                          morbi.
                        </p>
                      </blockquote>
                      <p>
                        Faucibus commodo massa rhoncus, volutpat. Dignissim sed
                        eget risus enim. Mattis mauris semper sed amet vitae sed
                        turpis id. Id dolor praesent donec est. Odio penatibus
                        risus viverra tellus varius sit neque erat velit.
                      </p>

                      <h4 className="text-xl text-gray-700">
                        Everything you need to get up and running
                      </h4>
                      <p>
                        Purus morbi dignissim senectus mattis{" "}
                        <a href="!#">adipiscing</a>. Amet, massa
                        quam varius orci dapibus volutpat cras. In amet eu
                        ridiculus leo sodales cursus tristique. Tincidunt sed
                        tempus ut viverra ridiculus non molestie. Gravida quis
                        fringilla amet eget dui tempor dignissim. Facilisis
                        auctor venenatis varius nunc, congue erat ac. Cras
                        fermentum convallis quam.
                      </p>
                      <p>
                        Faucibus commodo massa rhoncus, volutpat. Dignissim sed
                        eget risus enim. Mattis mauris semper sed amet vitae sed
                        turpis id. Id dolor praesent donec est. Odio penatibus
                        risus viverra tellus varius sit neque erat velit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
          </main>

          {/* Footer */}
          <PublicFooter />
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn, otpSent, forgotuser } = state.authentication;
  const { users, home } = state;
  console.log("homehomehomehome", home);

  return {
    loggingIn,
    forgotuser,
    otpSent,
    home,
    users,
  };
}
export default connect(mapStateToProps)(Home);
