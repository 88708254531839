import React, { Component } from 'react';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentpassword: '',
      newpassword: '',
      confirmnewpassword: ''
    }
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }
  updatePasswordSubmit = (e) => {
    e.preventDefault();
    let { currentpassword, newpassword, confirmnewpassword } = this.state;
    this.props.dispatch(userActions.updatePassword({ currentpassword, newpassword, confirmnewpassword }, this.props));
  }
  render() {

    return (
      <>
        <div className="mid-part">
          <div className="container">
            <div className="profile-part">
              <h2>Profile</h2>
              <div className="row">
                <div className="col-sm-6 mx-auto">
                  <form onSubmit={this.updatePasswordSubmit}>
                    <div className="form-group">
                      <input type="password" name="currentpassword" placeholder="Current Password" className="form-control" onChange={this.inputChange} />
                    </div>
                    <div className="form-group">
                      <input type="password" name="newpassword" placeholder="New Password" className="form-control" onChange={this.inputChange} />
                    </div>
                    <div className="form-group">
                      <input type="password" name="confirmnewpassword" placeholder="Confirm Password" className="form-control" onChange={this.inputChange} />
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn submit-btn">Update</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}
export default connect(mapStateToProps)(Dashboard);
