import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from 'react-router';

import Layout from "./Layout";

import Error from "../pages/error";
import Login from "../pages/login";
// import ProductList1 from "../pages/productlist1/ProductList1";
// import Categories from "../pages/categories/Categories";
import Products from "../pages/products/Products";
import Checkout from "../pages/checkout/Checkout";
import ProductDetails from "../pages/productDetails/ProductDetails";
import AllProductDetails from "../pages/productDetails/AllProductDetails";
// import Dashboard from "../pages/dashboard/Dashboard";
import Signup from "../pages/signup";
import Cart from "../pages/cart";
import Wishlist from "../pages/Wishlist";
import order from "../pages/order/order";
import Forgot from "../pages/forgot";
import Home from "../pages/Home/Home";
// import ProductList1 from "../pages/productList/ProductList1";
import VerifyEmail from "../pages/VerifyEmail";
// import Contactus from "../pages/contactus/Contactus";
import ValidateToken from "../pages/ValidateToken/ValidateToken";
import NetworkDetector from '../components/Hoc/NetworkDetector';
import Orderhistory from "../pages/Orderhistory/Orderhistory";
import Contact from "../pages/Contact/Contact";
import Payment from "../pages/Payment/Payment";
import Singleproductby from "../pages/Singleproductby/Singleproductby";
import LoginSignUp from "../pages/LoginSignUp/LoginSignUp";
import UpdateAddress from "../pages/dashboard/components/CreateUpdatePasswordUserModal/updateAddress";
import Thankyou from "../pages/thankyou/thankyou";


function App() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/home" />} />
        <Route
          exact
          path="/"
          render={() => <Redirect to="/home" />}
        />
        <PrivateRoute path="/app" component={withRouter(Layout)} />
        <PublicRoute path="/login" component={withRouter(Login)} />
        {/* <PublicRoute path="/productlist1" component={withRouter(ProductList1)} /> */}
        {/* <PublicRoute path="/categories" component={withRouter(Categories)} /> */}
        <PublicRoute path="/products" component={withRouter(Products)} />
        {/* <PublicRoute path="/products/:id" component={withRouter(Products)} /> */}
        <PublicRoute path="/productDetails/:id" component={withRouter(ProductDetails)} />
        <PublicRoute path="/allProductDetails/:id" component={withRouter(AllProductDetails)} />
        {/* <PublicRoute path="/dashboard" component={withRouter(Dashboard)} /> */}
        <PublicRoute path="/signup" component={withRouter(Signup)} />
        <PublicRoute path="/cart" component={withRouter(Cart)} />
        <PublicRoute path="/checkout" component={withRouter(Checkout)} />
        <PublicRoute path="/thankyou" exact component={withRouter(Thankyou)} />
        <PublicRoute path="/wishlist" exact component={withRouter(Wishlist)} />
        <PublicRoute path="/order" exact component={withRouter(order)} />
        <PublicRoute path="/home" component={withRouter(Home)} />
        <PublicRoute path="/forgot" component={withRouter(Forgot)} />
        {/* <PublicRoute path="/contactus" component={withRouter(Contactus)} /> */}
        <PublicRoute path="/verifyemail/:id/:token" component={withRouter(VerifyEmail)} />
        <PublicRoute path="/validateToken/:id" component={withRouter(ValidateToken)} />
        <PublicRoute path="/orderhistory" component={withRouter(Orderhistory)} />
        <PublicRoute path="/contact" component={withRouter(Contact)} />
        <PublicRoute path="/payment" component={withRouter(Payment)} />
        <PublicRoute path="/singleproductby/:productId" component={withRouter(Singleproductby)} />
        <PublicRoute path="/loginSignUp" component={withRouter(LoginSignUp)} />
        <PublicRoute path="/updateAddress" component={withRouter(UpdateAddress)} />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          localStorage.getItem('user') ? (
            // true ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          false ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}

// App = NetworkDetector(App);  

export default NetworkDetector(App);