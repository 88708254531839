import React, { Component } from "react";
import { userActions } from '../../_actions';
import { connect } from "react-redux";
import { dashboardActions } from "../../_actions";
import { homeActions, } from "../../_actions";
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import PublicFooter from "../../components/PublicFooter/PublicFooter";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.updatePasswordSubmit = this.updatePasswordSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      offset: 0,
      pageNo: 1,
      size: 10,
      errorsUpdateAddress: {},
      UpdateAddressCreateModal: false,
      CreateAddress: false,
      fieldslogin: {},
      errorslogin: {},
      show: false,
      listCard: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        fieldsAddUser: {},
        errorsAddUser: {},
        fieldsAddressUpdate: {},
        errorsUpdateAddress: {},
        addUserCreateModal: false,
        UpdateAddressCreateModal: false,
      }
    }
    if (nextProps.users.updateAddressSuccess) {
      return {
        ...nextProps,
        fieldsAddressUpdate: {},
        errorsUpdateAddress: {},
        show: false,
        listCard: false,
      }
    }
    else {
      return {
        ...nextProps,

      }
    }
  }
  handleOpenCreateModalUpdate = (data) => {
    console.log("updateAddress:::::", data);
    this.setState({ UpdateAddressCreateModal: true, fieldsAddressUpdate: data, listCard: true, show: true });
  }
  handleUpdateAddressHideModal = () => {
    this.setState({ UpdateAddressCreateModal: false });
  }

  handleCreateAddressHide = () => {
    this.setState({ CreateAddress: true });
  }

  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getAddressList(data));

  }

  logout = (url) => {
		localStorage.removeItem('user');
		this.props.history.push(url);
		this.authToken = null;
		this.user = null;
		localStorage.removeItem('token');
		localStorage.removeItem('user');
	} 
  
  updateAddressStatus = (data) => {
    let datatemp = {
      "id": data.id,
    }
    let paginationdata = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    console.log("datatemp", datatemp);
    console.log("paginationdata", paginationdata);


    // confirmAlert({
    //   title: 'Confirm to Delete?',
    //   message: 'Are you sure to Delete ',
    //   buttons: [
    //     {
    //       label: 'Yes',
    //       onClick: () =>  this.props.dispatch(userActions.updateAddressStatus(datatemp, paginationdata))
    //     },
    //     {
    //       label: 'No'
    //     }
    //   ]
    // });

  }




  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    console.log(name, value);
    let fieldsAddressUpdate = this.state.fieldsAddressUpdate;
    let errorsUpdateAddress = this.state.errorsUpdateAddress;
    fieldsAddressUpdate[name] = value;
    errorsUpdateAddress[name] = "";
    this.setState({ fieldslogin, errorslogin, fieldsAddressUpdate, errorsUpdateAddress });
  }

  UpdateAddressSubmit = () => {

    let reqData = {
      "fullName": this.state.fieldsAddressUpdate.fullName,
      "Address": this.state.fieldsAddressUpdate.Address,
      "email": this.state.fieldsAddressUpdate.email,
      "city": this.state.fieldsAddressUpdate.city,
      "country": this.state.fieldsAddressUpdate.country,
      "mobNo": this.state.fieldsAddressUpdate.mobNo,
      "pin": this.state.fieldsAddressUpdate.pin,
      "state": this.state.fieldsAddressUpdate.state,
    }

    console.log("time pass :", reqData);

    let paginationData = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.pageNo,
      "size": this.state.size
    }

    this.props.dispatch(userActions.updateProduct(reqData, paginationData));
    // }

  }


  updatePasswordSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      console.log("After hello validation");
      let data = {
        currentpassword: this.state.fieldslogin.currentpassword,
        newPassword: this.state.fieldslogin.newPassword,
        confirmnewpassword: this.state.fieldslogin.confirmnewpassword,
      }

      console.log("updatePasswordSubmit>>>>>>>", data);

      this.props.dispatch(userActions.updatePassword(data, this.props));
    }
  }
  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }

  handleValidationLogin = () => {
    console.log("hello validation");

    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    if (!fieldslogin["currentpassword"] || fieldslogin["currentpassword"] === "") {
      formIsValid = false;
      errorslogin["currentpassword"] = "New Password Cannot Be Blank.";
    }

    //newPassword
    if (!fieldslogin["newPassword"] || fieldslogin["newPassword"] === "") {
      formIsValid = false;
      errorslogin["newPassword"] = "Confirm New Password Cannot Be Blank.";
    }

    //confirmnewpassword
    if (!fieldslogin["confirmnewpassword"] || fieldslogin["confirmnewpassword"] === "") {
      formIsValid = false;
      errorslogin["confirmnewpassword"] = "Confirm New Password Cannot Be Blank.";
    }

    console.log("fieldsloginfieldsloginfieldslogin:::", fieldslogin);

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  loginSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let { email, password, } = this.state;
      this.props.dispatch(userActions.userlogin({ email: email, password: password, }, this.props));
    }

  }

  onClickMenu1 = (url) => {
		this.props.history.push(url);
	};


  onClickMenu = (url, data) => {
    this.props.history.push(url + data.id);
  }

  saveAddressDetails = () => {

    let add = {
      fullName: this.state.fieldslogin.fullName,
      Address: this.state.fieldslogin.Address,
      email: this.state.fieldslogin.email,
      city: this.state.fieldslogin.city,
      country: this.state.fieldslogin.country,
      mobNo: this.state.fieldslogin.mobNo,
      pin: this.state.fieldslogin.pin,
      state: this.state.fieldslogin.state,

    }
    this.props.dispatch(userActions.createAddress(add, this.props));

  }

  saveAddressDetailsUpdate = () => {


    let pagination = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }

    let add = {

      id: this.state.fieldsAddressUpdate.id,
      fullName: this.state.fieldsAddressUpdate.fullName,
      Address: this.state.fieldsAddressUpdate.Address,
      email: this.state.fieldsAddressUpdate.email,
      city: this.state.fieldsAddressUpdate.city,
      country: this.state.fieldsAddressUpdate.country,
      mobNo: this.state.fieldsAddressUpdate.mobNo,
      pin: this.state.fieldsAddressUpdate.pin,
      state: this.state.fieldsAddressUpdate.state,

    }

    console.log("UPdtaeasdhjkasdas:", add);
    this.props.dispatch(userActions.updateAddress(add, pagination, this.props));

  }


  toggle = () => this.setState((currentState) => ({ show: !currentState.show, fieldsAddressUpdate: {} }));

  render() {
    const truncate = (input) =>
      input?.length > 60 ? `${input.substring(0, 40)}...` : input;
    let { dashboard, home, users } = this.props;
    // let { getAddressList } = dashboard
    let { GetArrivalsList } = home
    let { getUserProfile, getAddressList } = users
    console.log("fieldsloginfieldsloginfieldslogin:::", getAddressList);


    console.log("Rebnder__fieldsAddressUpdate", this.state.fieldsAddressUpdate);

    return (
      <>
        {/* Whole Structure */}
        <div className="overflow-hidden">
          {/* Headdr */}
          <PublicHeader />

          <main className="bg-gray-50">
            {/* <Breadcrumb /> */}

            <section class=" dark:bg-gray-800 pt-7 relative z-20 items-center overflow-hidden ">
              <div class="max-w-screen-2xl mx-auto px-2 sm:px-4 lg:px-8">
                <div class="text-left heading ">
                  <h2 class="pb-2 text-left text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ">
                    Dashboard
                  </h2>
                  <p class="text-sm text-left text-gray-400 mt-2">
                    All carted items
                  </p>
                </div>

                <div className="lg:my-5 grid grid-cols-12 gap-10">
                  <div className="col-span-3 bg-white rounded-md shadow p-6">
                    {/* User Identity */}
                    <div className="flex flex-col space-y-3">
                      {/* photo */}
                      <div class="avatar mx-auto online">
                        <div class="rounded-full w-24 h-24">
                          <img
                            src="http://daisyui.com/tailwind-css-component-profile-2@56w.png"
                            alt=""
                          />
                        </div>
                      </div>

                      <div class="text-center flex flex-col justify-center items-center">
                        <h2 class="text-lg font-bold">{getUserProfile && getUserProfile.name ? getUserProfile.name : "-"}</h2>
                        <p className="line-clamp-2 text-sm text-gray-500">
                          From your My Account Dashboard you have the ability
                          to view a snapshot of your recent account activity
                          and update your account information. Select a link
                          below to view or edit information.
                        </p>
                        <div class="justify-center pt-3">
                          <button onClick={() => this.onClickMenu1("/home")} class="text-primary border border-primary py-1 px-4 flex justify-center items-center rounded hover:bg-primary hover:text-white transition ease-in-out duration-150">
                            Continue shopping
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10 flex flex-col space-y-2 text-lg  ">
                      <div className="px-4 py-3 text-left  transition ease-in-out duration-150 flex items-center justufy-start space-x-2 bg-primary text-white rounded shadow-6">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                          />
                        </svg>
                        <span>Account Info</span>
                      </div>
                      <div onClick={() => this.onClickMenu1('/app/wishlist')}  className="px-4 py-3 text-left  transition ease-in-out duration-150 flex items-center justufy-start space-x-2 hover:text-primary cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                          />
                        </svg>
                        <span >Wishlist</span>
                      </div>
                      <div className="px-4 py-3 text-left  transition ease-in-out duration-150 flex items-center justufy-start space-x-2 hover:text-primary cursor-pointer" onClick={() => this.onClickMenu1('/order')}>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                          />
                        </svg>
                        <span>My Orders</span>
                      </div>

                      {/* <div className="px-4 py-3 text-left  transition ease-in-out duration-150 flex items-center justufy-start space-x-2 hover:text-primary cursor-pointer">

                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                        </svg>

                        <span>Payment Cards</span>
                      </div> */}

                      {/* <div className="px-4 py-3 text-left  transition ease-in-out duration-150 flex items-center justufy-start space-x-2 hover:text-primary cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                          />
                        </svg>
                        <span>Change Password</span>
                      </div> */}
                      <div onClick={() => this.logout("/home")} className="px-4 py-3 text-left  transition ease-in-out duration-150 flex items-center justufy-start space-x-2 hover:text-primary cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                          />
                        </svg>
                        <span>Log out</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-9 bg-white rounded-md shadow p-6">

                    {/* Tab 1 Dashboard/MyAccount */}
                    <div className="tab-1 ">
                      <div className="prose prose-sm max-w-none text-base-content relative">
                        <h3>MY DASHBOARD</h3>
                        <p>
                          Hello, <span className="uppercase font-medium">{getUserProfile && getUserProfile.name ? getUserProfile.name : "-"}!</span>

                          <br />
                          From your My Account Dashboard you have the ability
                          to view a snapshot of your recent account activity
                          and update your account information. Select a link
                          below to view or edit information.
                        </p>
                        <div className="flex gap-10">

                          <p className="space-x-3 flex items-center">
                            <span className="text-primary">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                />
                              </svg>
                            </span>
                            <span>{getUserProfile && getUserProfile.email ? getUserProfile.email : "-"}</span>
                          </p>
                          <p className="space-x-3 flex items-center">
                            <span className="text-primary">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                />
                              </svg>
                            </span>
                            <span>+91 {getUserProfile && getUserProfile.mobile ? getUserProfile.mobile : "-"}</span>
                          </p>
                        </div>

                        {/* <button class="p-2 transition ease-in-out duration-150 hover:underline absolute -top-2 -right-2 hover:text-primary  hover:bg-pink-50 text-gray-400 cursor-pointer w-8 h-8 rounded flex justify-center items-center buzz-out">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                            />
                          </svg>
                        </button> */}

                        {/* After press EDIT button add flex else hidden*/}
                        {/* <div className="flex-col hidden">
                          <div className="">
                            <h3>Fill all the requirements</h3>
                            <form>
                              <div className="space-y-5">
                                <div className="grid grid-cols-6 gap-5">
                                  <div className="lg:col-span-3 col-span-6">
                                    <label class="block text-sm font-medium text-gray-700">
                                      Change Photo
                                    </label>
                                    <div class="mt-2">
                                      <input
                                        type="file" placeholder="chhose new photo"
                                        class=" appearance-none block w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                      />


                                    </div>
                                  </div>
                                </div>

                                <div className="grid grid-cols-6 gap-5">
                                  <div className="col-span-3">
                                    <label class="block text-sm font-medium text-gray-700">
                                      First Name
                                    </label>
                                    <div class="mt-1">
                                      <input
                                        type="text"
                                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"

                                      // value={this.state.fieldsAddressUpdate &&this.state.fieldsAddressUpdate[""]}

                                      />
                                    </div>
                                  </div>
                                  <div className="col-span-3">
                                    <label class="block text-sm font-medium text-gray-700">
                                      Last Name
                                    </label>
                                    <div class="mt-1">
                                      <input
                                        type="text"
                                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="">
                                  <label class="block text-sm font-medium text-gray-700">
                                    Gender
                                  </label>
                                  <div class="mt-1 flex gap-5">
                                    <div class="flex items-center">
                                      <input
                                        id="male"
                                        class="h-4 w-4 text-primary focus:ring-primary-500 border-gray-300 rounded-full"
                                      />
                                      <label
                                        for="male"
                                        class="ml-2 block text-sm text-gray-900"
                                      >
                                        Male
                                      </label>
                                    </div>
                                    <div class="flex items-center">
                                      <input
                                        id="female"
                                        name="female"
                                        type="radio"
                                        class="h-4 w-4 text-primary focus:ring-primary-500 border-gray-300 rounded-full"
                                      />
                                      <label
                                        for="female"
                                        class="ml-2 block text-sm text-gray-900"
                                      >
                                        Female
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <label class="block text-sm font-medium text-gray-700">
                                    Description
                                  </label>
                                  <div class="mt-1">
                                    <textarea
                                      type="text"
                                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="grid grid-cols-6 gap-5">
                                  <div className="col-span-3">
                                    <label class="block text-sm font-medium text-gray-700">
                                      Email
                                    </label>
                                    <div class="mt-1">
                                      <input
                                        type="text"
                                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-span-3">
                                    <label class="block text-sm font-medium text-gray-700">
                                      Phone
                                    </label>
                                    <div class="mt-1">
                                      <input
                                        type="text"
                                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="flex space-x-4 justify-end">
                                  <button type="button" class="inline-flex items-center px-4 py-2 text-center border border-gray-300 font-normal rounded-md shadow-sm text-gray-600 hover:bg-primary hover:border-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Reset</button>
                                  <button type="button" class="inline-flex items-center px-4 py-2 text-center border border-transparent font-normal rounded-md shadow-sm text-white bg-primary hover:bg-primary-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Save</button>

                                </div>
                              </div>
                            </form>
                          </div>
                        </div> */}
                      </div>

                      {/* Address list*/}
                      <div className="flex justify-between items-center mt-7">
                        <div className="text-lg uppercase font-semibold text-gray-600">
                          Manage Address
                        </div>

                        <div>
                          {this.state.show ? <button onClick={this.toggle} className="inline-flex items-center px-3 py-2 text-center border border-transparent font-normal rounded     text-sm shadow-sm text-white bg-primary hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Cancel</button>
                            :
                            <button onClick={this.toggle} className="inline-flex items-center px-3 py-2 text-center border border-transparent font-normal rounded     text-sm shadow-sm text-white bg-primary hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                              </svg>
                              <span>New Address</span></button>
                          }
                        </div>
                      </div>

                      {this.state.show &&
                        <div className="mt-2 ">
                          <h4 className="font-sans font-medium text-lg">Fill New Address</h4>
                          <form className="mt-2">
                            <div className="space-y-5">
                              <div className="flex-col gap-5">
                                <div className="col-span-3">
                                  <label class="block text-sm font-medium text-gray-700">
                                    Full Name
                                  </label>
                                  <div class="mt-1">
                                    <input
                                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"

                                      name="fullName" type="fullName"
                                      value={this.state.fieldsAddressUpdate && this.state.fieldsAddressUpdate["fullName"] ? this.state.fieldsAddressUpdate["fullName"] : ""}

                                      onChange={this.inputChange} />
                                    {this.state.errorslogin["fullName"] ?
                                      <div className="text-yellow-400 invalid-feedback">
                                        {this.state.errorslogin["fullName"]}
                                      </div>
                                      : null}

                                  </div>
                                </div>
                              </div>

                              <div className="grid grid-cols-6 gap-5">
                                <div className="col-span-3">
                                  <label class="block text-sm font-medium text-gray-700">
                                    MobNo
                                  </label>
                                  <div class="mt-1">
                                    <input

                                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                      name="mobNo" type="text"
                                      value={this.state.fieldsAddressUpdate && this.state.fieldsAddressUpdate["mobNo"] ? this.state.fieldsAddressUpdate["mobNo"] : ""}
                                      onChange={this.inputChange}
                                    />
                                  </div>
                                </div>

                                <div className="col-span-3">
                                  <label class="block text-sm font-medium text-gray-700">
                                    Email
                                  </label>
                                  <div class="mt-1">
                                    <input

                                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                      name="email" type="email"
                                      value={this.state.fieldsAddressUpdate && this.state.fieldsAddressUpdate["email"] ? this.state.fieldsAddressUpdate["email"] : ""}
                                      onChange={this.inputChange}

                                    />
                                  </div>
                                </div>
                              </div>


                              <div className="flex-col gap-5">
                                <div className="col-span-3">
                                  <label class="block text-sm font-medium text-gray-700">
                                    Address
                                  </label>
                                  <div class="mt-1">
                                    <input
                                      class="appearance-none block w-full h-20 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                      name="Address" type="text"
                                      value={this.state.fieldsAddressUpdate && this.state.fieldsAddressUpdate["Address"] ? this.state.fieldsAddressUpdate["Address"] : ""}
                                      onChange={this.inputChange}
                                    />
                                  </div>
                                </div>

                              </div>

                              <div className="grid grid-cols-6 gap-5">
                                <div className="col-span-3">
                                  <label class="block text-sm font-medium text-gray-700">
                                    City
                                  </label>
                                  <div class="mt-1">
                                    <input

                                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                      name="city" type="text"
                                      value={this.state.fieldsAddressUpdate && this.state.fieldsAddressUpdate["city"] ? this.state.fieldsAddressUpdate["city"] : ""}
                                      onChange={this.inputChange}

                                    />
                                  </div>
                                </div>
                                <div className="col-span-3">
                                  <label class="block text-sm font-medium text-gray-700">
                                    State
                                  </label>
                                  <div class="mt-1">
                                    <input

                                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                      name="state" type="text"
                                      value={this.state.fieldsAddressUpdate && this.state.fieldsAddressUpdate["state"] ? this.state.fieldsAddressUpdate["state"] : ""}
                                      onChange={this.inputChange}

                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="grid grid-cols-6 gap-5">
                                <div className="col-span-3">
                                  <label class="block text-sm font-medium text-gray-700">
                                    Country
                                  </label>
                                  <div class="mt-1">
                                    <input

                                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                      name="country" type="text"
                                      value={this.state.fieldsAddressUpdate && this.state.fieldsAddressUpdate["country"] ? this.state.fieldsAddressUpdate["country"] : ""}
                                      onChange={this.inputChange}
                                    />
                                  </div>
                                </div>

                                <div className="col-span-3">
                                  <label class="block text-sm font-medium text-gray-700">
                                    Pin Code
                                  </label>
                                  <div class="mt-1">
                                    <input

                                      class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                                      value={this.state.fieldsAddressUpdate && this.state.fieldsAddressUpdate["pin"] ? this.state.fieldsAddressUpdate["pin"] : ""}
                                      name="pin" type="text" onChange={this.inputChange}

                                    />
                                  </div>
                                </div>
                              </div>

                              <div>


                                {
                                  this.state.listCard ?
                                    <>
                                      <div className="flex space-x-4 justify-end">
                                        <button onClick={this.saveAddressDetailsUpdate} type="button" class="inline-flex items-center px-4 py-2 text-center border border-transparent font-normal rounded-md shadow-sm text-white bg-primary hover:bg-primary-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Save Update</button>

                                      </div>
                                    </> :
                                    <>
                                      <div className="flex space-x-4 justify-end">
                                        <button onClick={this.saveAddressDetails} type="button" class="inline-flex items-center px-4 py-2 text-center border border-transparent font-normal rounded-md shadow-sm text-white bg-primary hover:bg-primary-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Save</button>

                                      </div>
                                    </>
                                }




                              </div>
                            </div>
                          </form>

                        </div>
                      }

                      {/* When click on '+ New Address' Button remove class="hidden" */}





                      {getAddressList && getAddressList.length > 0 ? getAddressList.map((element, index) => <>
                        <div className="gap-10 mt-5">




                          <div className="col-span-6 relative space-y-3 p-5 bg-base-200 rounded">
                            <p className="uppercase font-medium text-gray-600">
                              {element && element.userId && element.userId.name ? element.userId.name : "-"} <br />{element ? element.address_tpye : "-"}
                            </p>
                            <p class="space-x-3 flex items-center">
                              <span class="text-primary">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                  />
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                </svg>
                              </span>
                              <span>
                                {element ? element.fullName + " ," : "-"}
                              </span>
                              <span>
                                {element ? element.Address + " ," : "-"}
                              </span>
                              <span>
                                {element ? element.city + " ," : "-"}
                              </span>
                              <span>
                                {element ? element.state + " ," : "-"}
                              </span>
                              <span>
                                {element ? element.country + " ," : "-"}
                              </span>
                              <span>
                                {element ? element.pin + " ." : "-"}
                              </span>


                            </p>
                            <div class="flex flex-wrap gap-10">
                              <p class="space-x-3 flex items-center">
                                <span class="text-primary">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                    ></path>
                                  </svg>
                                </span>
                                <span> {element ? element.email : "-"}</span>
                              </p>
                              <p class="space-x-3 flex items-center">
                                <span class="text-primary">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                                    ></path>
                                  </svg>
                                </span>
                                <span>+91- {element ? element.mobNo : "-"}</span>
                              </p>
                            </div>
                            <div className="flex space-x-1 absolute -top-2 right-0">
                              <button
                                class="p-2 hover:text-primary hover:bg-pink-50 text-gray-400 cursor-pointer w-8 h-8 rounded flex justify-center items-center buzz-out transition ease-in-out duration-150 hover:underline " onClick={() => this.updateAddressStatus(element)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              </button>
                              <span
                                onClick={() => this.handleOpenCreateModalUpdate(element)}
                                class="p-2 hover:text-primary hover:bg-pink-50 text-gray-400 cursor-pointer w-8 h-8 rounded flex justify-center items-center buzz-out transition ease-in-out duration-150 hover:underline ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </>)
                        : null}


                    </div>



                    {/* Tab 2 Wishlist */}
                    <div className="tab-2 ">

                      <div className="">
                        <h2 className="text-lg uppercase font-semibold text-gray-900 mt-7">My Wishlist</h2>
                        <div className="pt-4">

                          {/* row */}
                          <div className="max-h-[500px] overflow-auto pr-10">
                            {/* Cart Items */}
                            <div className="md:pt-4">
                              {/* Cart Items header*/}

                              <div className="hidden md:flex justify-between border-b border-black border-opacity-20 mr-10">
                                <div className="w-1/2 pb-2 lg:w-3/5 xl:w-1/2 ">
                                  <span className="text-sm uppercase font-normal text-gray-600 ">Product</span>
                                </div>

                                <div className="w-1/5 pb-2 pl-14 text-left ">
                                  <span className="text-sm uppercase font-normal text-gray-600 ">Price</span>
                                </div>


                                <div className="w-1/5 pb-2 text-right  ">
                                  <span className="text-sm uppercase font-normal text-gray-600 ">Delete</span>
                                </div>


                              </div>
                              {
                                localStorage.getItem("wishProducts") && JSON.parse(localStorage.getItem("wishProducts")) && JSON.parse(localStorage.getItem("wishProducts")).map((element, index) => (
                                  <>
                                    <div className="max-h-[500px] overflow-auto pr-10">
                                      <div className="flex flex-wrap lg:flex-nowrap justify-between items-center relative py-4 lg:py-8 border-b border-black border-opacity-20">
                                        <div className="md:w-1/2 w-full flex items-center ">
                                          <div className="w-20 pr-0 mx-0">
                                            <div className="flex items-center justify-center h-20 rounded bg-gray-100">
                                              <div className="w-full  aspect-w-1 cursor-pointer aspect-h-1">
                                                <img src={element && element.wishProduct && element.wishProduct.imageLink} alt="productImage"
                                                  onClick={() => this.onClickMenu("/productDetails/", element)}
                                                  className="object-cover" />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="">
                                            <span className="mt-2 ml-4 text-sm lg:text-base font-bold text-black block ">{element && element.wishProduct && element.wishProduct.name}</span>
                                            {/* <span className="ml-4 text-sm  font-normal text-gray-400 block ">Rs.2,500</span> */}
                                          </div>
                                        </div>

                                        <div className="w-1/5  text-left  flex flex-wrap items-center mt-3">
                                          <span className="block w-full text-sm md:hidden mb-1">Price</span>
                                          <span className="text-base uppercase font-medium text-black">{element && element.wishProduct && element.wishProduct.newPrice}</span>
                                        </div>
                                        <div className="flex items-center ">
                                          <button
                                            onClick={() => this.removeFromWishList(element)}
                                            className="hover:text-red-600 text-gray-400 cursor-pointer w-8 h-8 rounded bg-white flex justify-center items-center buzz-out transition hover:bg-red-50">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" id="Outline" viewBox="0 0 24 24"
                                              className="transition w-4">
                                              <path
                                                d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z">
                                              </path>
                                              <path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z"></path>
                                              <path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"></path>
                                            </svg>
                                          </button>

                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))
                              }


                            </div>

                          </div>



                        </div>
                      </div>
                      {/* Pagination */}
                    </div>

                    <div>
                      {/* Tab 2 My Orders */}
                      {/* <div className="tab-3 ">

                      <h2 className="text-lg uppercase font-semibold text-gray-900 mt-7">My Orders</h2>
                      <div className="pt-4">
                        <div className="hidden sm:block">
                          <div className="grid grid-cols-12 pb-3 gap-2">
                            <div className="col-span-4">
                              <span className="text-sm uppercase ">Product Name</span>
                            </div>
                            <div className="text-right">
                              <span className="text-sm uppercase ">Quantity</span>
                            </div>
                            <div className="text-right">
                              <span className="text-sm uppercase ">Price</span>
                            </div>
                            <div className="col-span-3 text-right">
                              <span className="text-sm uppercase ">Courier</span>
                            </div>
                            <div className="text-right col-span-3">
                              <span className="text-sm uppercase text-center">Action</span>
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-12 gap-2 py-2 sm:border-t border-b sm:border-b-0 border-gray-200 sm:py-4 flex-wrap items-center">
                          <div className="flex items-center text-center col-span-4 md:items-center sm:pb-0 sm:text-left">
         
                            <div className="relative sm:w-20 w-24   mr-3 sm:pr-0">
                              <div className="flex items-center justify-center sm:h-20 h-24 rounded">
                                <div className="w-full aspect-w-1 aspect-h-1">
                                  <img src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80" alt="  " className="object-cover" />
                                </div>
                              </div>
                            </div>
                            <span className="text-sm lg:text-base text-gray-light">Classic Beige</span>
                          </div>


                          <div className="flex flex-col items-end  py-2 ml-0 sm:py-0 text-center ">

                            2

                          </div>

    
                          <div className="flex flex-col items-end  py-2 mr-0   sm:py-0 text-center ">
                            <span className="text-base text-gray-light">$1045</span>
                          </div>


                          <div className="col-span-3 items-end py-2 ml-0 sm:py-0 text-right ">

                            <p className="space-x-1"><b className="text-primary">AIE014576</b> <span >India Post</span></p>

                          </div>

         
                          <div className="col-span-3 text-right flex items-center justify-end">
                            <a href="/" className="inline-flex items-center px-3 py-2 text-center border border-transparent font-normal rounded text-sm shadow-sm text-white bg-primary hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Track Now
                            </a>
         
                            <button className="ml-1 sm:ml-3 hover:text-primary text-gray-400 cursor-pointer rounded flex justify-center items-center  transition hover:bg-pink-50 p-2">
                              Cancel
                            </button>
                          </div>

                          <div className="col-span-12 "><p className="text-sm italic bg-base-200 px-2 py-1 rounded text-center">Date:12th July, 2021, Status: <span className="text-primary">On the way</span>/<span className="text-green-700">Delivered</span>/<span className="text-red-500">Canceled</span>/<span className="text-red-500">Returned</span></p></div>

                        </div>



                      </div>
                    </div> */}


                      {/* Tab Payment Cards */}
                      {/* 
                    <div className="tab-4 ">
            
                      <div className="flex justify-between items-center mt-7">
                        <h3 className="text-lg uppercase font-semibold text-gray-900">
                          Payment Cards
                        </h3>
 
                        <button className="inline-flex items-center px-3 py-2 text-center border border-transparent font-normal rounded     text-sm shadow-sm text-white bg-primary hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                          </svg>
                          <span>New Card</span></button>
                      </div>

             
                      <form className="w-80 hidden">



                        <div className="mt-3 space-y-5 p-3 rounded border border-gray-300">
                          <h4 className="font-sans font-medium ">Fill card detail</h4>


               
                          <div className="flex space-y-1 flex-col">
                            <input type="text" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm" placeholder="Card Number" />
                          </div>

                          <div className="grid grid-cols-6 gap-5">
                            <div className="col-span-3">
                              <select name="Month" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                                <option value="january">January</option>
                                <option value="february">February</option>
                                <option value="march">March</option>
                                <option value="april">April</option>
                                <option value="may">May</option>
                                <option value="june">June</option>
                                <option value="july">July</option>
                                <option value="august">August</option>
                                <option value="september">September</option>
                                <option value="october">October</option>
                                <option value="november">November</option>
                                <option value="december">December</option>
                              </select>
                            </div>
                            <div className="col-span-3">
                              <select name="Year" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                                <option value={2016}>2016</option>
                                <option value={2017}>2017</option>
                                <option value={2018}>2018</option>
                                <option value={2019}>2019</option>
                                <option value={2020}>2020</option>
                                <option value={2021}>2021</option>
                                <option value={2022}>2022</option>
                                <option value={2023}>2023</option>
                                <option value={2024}>2024</option>
                              </select>
                            </div>
                          </div>
    
                          <div className="flex space-x-2">
                            <div className="w-14">
                              <input type="text" placeholder="CVV" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm" />
                            </div>
                            <div className="text-sm">
                              <p>3 or 4 digits usually found <br /> on the signature strip</p>
                            </div>
                          </div>
             
                          <div class="flex space-x-4 justify-end"><button type="button" class="inline-flex items-center px-4 py-2 text-center border border-gray-300 font-normal rounded-md shadow-sm text-gray-600 hover:bg-primary hover:border-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Reset</button><button type="button" class="inline-flex items-center px-4 py-2 text-center border border-transparent font-normal rounded-md shadow-sm text-white bg-primary hover:bg-primary-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Save</button></div>

                        </div>
                      </form>


                      <div className="grid lg:grid-cols-12 grid-cols-2  gap-5 mt-5">
                        <div className="col-span-4 relative space-y-3 p-5 bg-base-200 rounded">
                          <p className="uppercase font-medium text-gray-600">
                            ICICI Bank Credit Card
                          </p>
                          <p class="space-x-3 flex items-center">
                            <span class="px-2 py-1 border border-gray-400 rounded w-12">
                              <img src="dist/img/masterCard.svg" alt="" />

                            </span>
                            <span>
                              5210 ******* 5210
                            </span>
                          </p>
                          <div className="flex space-x-1 absolute -top-2 right-0">
                            <button class="p-2 hover:text-primary hover:bg-pink-50 text-gray-400 cursor-pointer w-8 h-8 rounded flex justify-center items-center buzz-out transition ease-in-out duration-150 hover:underline ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                            <button class="p-2 hover:text-primary hover:bg-pink-50 text-gray-400 cursor-pointer w-8 h-8 rounded flex justify-center items-center buzz-out transition ease-in-out duration-150 hover:underline ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="col-span-4 relative space-y-3 p-5 bg-base-200 rounded">
                          <p className="uppercase font-medium text-gray-600">
                            HDFC Bank Debit Card
                          </p>
                          <p class="space-x-3 flex items-center">
                            <span class="px-2 py-1 border border-gray-400 rounded w-12">
                              <img src="dist/img/masterCard.svg" alt="" />

                            </span>
                            <span>
                              5210 ******* 5210
                            </span>
                          </p>
                          <div className="flex space-x-1 absolute -top-2 right-0">
                            <button class="p-2 hover:text-primary hover:bg-pink-50 text-gray-400 cursor-pointer w-8 h-8 rounded flex justify-center items-center buzz-out transition ease-in-out duration-150 hover:underline ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                            <button class="p-2 hover:text-primary hover:bg-pink-50 text-gray-400 cursor-pointer w-8 h-8 rounded flex justify-center items-center buzz-out transition ease-in-out duration-150 hover:underline ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="col-span-4 relative space-y-3 p-5 bg-base-200 rounded">
                          <p className="uppercase font-medium text-gray-600">
                            Amazon Pay
                          </p>
                          <p class="space-x-3 flex items-center">
                            <span class="px-2 py-1 border border-gray-400 rounded w-12">
                              <img src="dist/img/masterCard.svg" alt="" />

                            </span>
                            <span>
                              5210 ******* 5210
                            </span>
                          </p>
                          <div className="flex space-x-1 absolute -top-2 right-0">
                            <button class="p-2 hover:text-primary hover:bg-pink-50 text-gray-400 cursor-pointer w-8 h-8 rounded flex justify-center items-center buzz-out transition ease-in-out duration-150 hover:underline ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                            <button class="p-2 hover:text-primary hover:bg-pink-50 text-gray-400 cursor-pointer w-8 h-8 rounded flex justify-center items-center buzz-out transition ease-in-out duration-150 hover:underline ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div> 
                    </div> */}

                      {/* Tab Change password */}
                      {/* <div className="tab-5 ">


                      <h2 className="text-lg uppercase font-semibold text-gray-900 mt-7">Chage Password</h2>


                      <div className="mt-4">

                        <form>
                          <div className="space-y-5">
                            <div className="grid grid-cols-6 gap-5">
                              <div className="lg:col-span-3 col-span-6">
                                <label className="block text-sm font-medium text-gray-700">Old Password</label>
                                <div className="mt-1">
                                  <input className={`block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm ${!this.state.errorslogin["currentpassword"] ? "'border-opacity-20 border-opacity-100 border border-gray-300 placeholder-gray-300  " : "border-opacity-100 border border-red-500 "}`}
                                    id="password" name="password" placeholder="password" type="password" value={this.state.fieldslogin.currentpassword}
                                    inputChange={this.inputChange} />
                                  {this.state.errorslogin["currentpassword"] ?
                                    <div className="text-red-500 invalid-feedback">
                                      {this.state.errorslogin["currentpassword"]}
                                    </div>
                                    : null}</div>
                              </div>

                            </div>

                            <div className="grid grid-cols-6 gap-5">
                              <div className="col-span-3">
                                <label className="block text-sm font-medium text-gray-700">New Password</label>
                                <div className="mt-1"><input className={`block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm ${!this.state.errorslogin["newPassword"] ? "'border-opacity-20 border-opacity-100 border border-gray-300 placeholder-gray-300  " : "border-opacity-100 border border-red-500 "}`}
                                  id="password" name="password" placeholder="password" type="password" value={this.state.fieldslogin.newPassword}
                                  inputChange={this.inputChange} />
                                  {this.state.errorslogin["newPassword"] ?
                                    <div className="text-red-500 invalid-feedback">
                                      {this.state.errorslogin["newPassword"]}
                                    </div>
                                    : null}</div>
                              </div>
                              <div className="col-span-3">
                                <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
                                <div className="mt-1"><input className={`block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm ${!this.state.errorslogin["confirmnewpassword"] ? "'border-opacity-20 border-opacity-100 border border-gray-300 placeholder-gray-300  " : "border-opacity-100 border border-red-500 "}`}
                                  id="password" name="password" placeholder="password" type="password" inputChange={this.inputChange} />
                                  {this.state.errorslogin["confirmnewpassword"] ?
                                    <div className="text-red-500 invalid-feedback">
                                      {this.state.errorslogin["confirmnewpassword"]}
                                    </div>
                                    : null}</div>
                              </div>
                            </div>

                            <div className="flex space-x-4 justify-end">
                              <button type="button" class="inline-flex items-center px-4 py-2 text-center border border-gray-300 font-normal rounded-md shadow-sm text-gray-600 hover:bg-primary hover:border-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Reset</button>
                              <button onClick={this.updatePasswordSubmit} type="button" class="inline-flex items-center px-4 py-2 text-center border border-transparent font-normal rounded-md shadow-sm text-white bg-primary hover:bg-primary-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition ease-in-out duration-300 justify-center ">Save</button>

                            </div>

                          </div>
                        </form>
                      </div>


                    </div> */}
                    </div>


                  </div>
                </div>
              </div>
            </section>
          </main>

          {/* <UpdateAddressUser
          UpdateAddressCreateModal={this.state.UpdateAddressCreateModal}
          fieldsAddressUpdate={this.state.fieldsAddressUpdate}
          errorsUpdateAddress={this.state.errorsUpdateAddress}
          inputChange={this.inputChange}
          UpdateAddressSubmit={this.UpdateAddressSubmit}
          handleAppSeetingHideModal={this.handleUpdatePasswordHideModal}
        /> */}

          {/* Footer */}
          <PublicFooter />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, otpSent, forgotuser } = state.authentication;
  const { users, home, dashboard } = state;
  console.log("homehomehomehome", home);
  // const { users, dashboard } = state;
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    home,
    dashboard,
    setting,
  };
}
export default connect(mapStateToProps)(Dashboard);
