import { orderConstants } from '../_constants';

export function order(category = {}, action) {
  // console.log("action.typeaction.typeaction.type  ", action);

  switch (action.type) {
    case orderConstants.GET_ORDER_LIST_REQUEST:
      return {
        loading: true,
      };
    case orderConstants.GET_ORDER_LIST_SUCCESS:
      return {
        orderData: action.category.getOrderLists.list,
        total: action.category.getOrderLists.total,

      };
    case orderConstants.GET_ORDER_LIST_FAILURE:
      return {
        error: action.error
      };

    default:
      return category
  }
}