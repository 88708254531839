import { productConstants } from '../_constants';

export function product(state = {}, action) {
  // console.log("action.typeaction.typeaction.type  ", action);

  switch (action.type) {
    case productConstants.GET_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case productConstants.GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        productListItems: action.products.getProductList.list,

      };
    case productConstants.GET_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}