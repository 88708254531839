import { productConstants } from '../_constants';
import { productService } from '../_services';
// import { alertActions } from './';

export const productActions = {

    getProductList,
  
};



function getProductList(data) {
    return dispatch => {
        dispatch(request());
        productService.getProductList(data)
            .then(
                products => {
                    console.log("i am in action getProductList:::",products);
                    dispatch(success(products))
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: productConstants.GET_PRODUCT_LIST_REQUEST } }
    function success(products) { return { type: productConstants.GET_PRODUCT_LIST_SUCCESS, products } }
    function failure(error) { return { type: productConstants.GET_PRODUCT_LIST_FAILURE, error } }
}

