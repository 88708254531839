import {
  userConstants
} from '../_constants';

export function users(state = {}, action) {

  switch (action.type) {


    case userConstants.DISABLE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess:false,
        disableAddressSuccess:false
      };
    case userConstants.DISABLE_ADDRESS_SUCCESS:
      return {
        ...state,
        // addCategorySuccess: false,
        // CategoryList: action.category.getAllCategory
        getAddressList: action.dashboard.getAddressList.list
      };
      case userConstants.DISABLE_ADDRESS_FAILURE:
        return {
          ...state,
          error: action.error
        };


    case userConstants.updateAddressStatus_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess:false,
        updateAddressSuccess:false
      };
    case userConstants.updateAddressStatus_ADDRESS_SUCCESS:
      return {
        ...state,
        // addCategorySuccess: false,
        // CategoryList: action.category.getAllCategory
        getAddressList: action.dashboard.getAddressList.list
      };
    case userConstants.updateAddressStatus_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.error
      };


      
    case userConstants.GETALL_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        addUserSuccess:false,
        updateAddressSuccess:false
      };
    case userConstants.GETALL_ADDRESS_SUCCESS:
      return {
        ...state,
        // addCategorySuccess: false,
        // CategoryList: action.category.getAllCategory
        getAddressList: action.dashboard.getAddressList.list
      };
    case userConstants.GETALL_ADDRESS_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.USER_TICKET_CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_TICKET_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isTicketCreated: true
      };
    case userConstants.USER_TICKET_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.USER_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        getUserProfile: action.users.getUserProfile
      };
    case userConstants.USER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        getUserInfo: action.users.userInformation
      };
    case userConstants.GET_USER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        isProfileUpdated: true,
        updateUserInfo: action.users.updateUserInfo
      };
    case userConstants.UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        isPasswordUpdate: true
      };
    case userConstants.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: false,
        items: action.users.listOfRestaurant.list,
        total: action.users.listOfRestaurant.total
      };
    case userConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.TOKEN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: false,
        userinfotoken: action.users.userinfotoken
      };
    case userConstants.TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.USER_TICKET_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_TICKET_LIST_SUCCESS:
      return {
        ...state,
        isTicketCreated: false,
        addUserSuccess: false,
        loading: false,
        ticketList: action.users.ticketlistData.list,
        ticketTotal: action.users.ticketlistData.total,
      };
    case userConstants.USER_TICKET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.TX_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.TX_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: false,
        txList: action.users.txlistData.list,
        txTotal: action.users.txlistData.total,
      };
    case userConstants.TX_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SEND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SEND_SUCCESS:
      return {
        ...state,
        loading: false,
        sendCoinSuccess: true,
      };
    case userConstants.SEND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SEND_OTP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        sendCoinTXOTPSuccess: true,
      };
    case userConstants.SEND_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateAddressSuccess: false,
        isProfileUpdated: false,
        isPasswordUpdate: false,
        sendCoinSuccess: false,
        swapCoinSuccess: false,
        sendCoinTXOTPSuccess: false,
        overview: action.users.getUserDetails
      };
    case userConstants.USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.PRICE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        priceDeta: action.users.getPrice
      };
    case userConstants.PRICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SWAP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SWAP_SUCCESS:
      return {
        ...state,
        loading: false,
        swapCoinSuccess: true,
      };
    case userConstants.SWAP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SEND_COIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SEND_COIN_SUCCESS:
      return {
        ...state,
        loading: false,
        sendCoinSuccess: true,
      };
    case userConstants.SEND_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        filesDetails: action.users.filesDetails
      };
    case userConstants.FILE_UPLOAD_CLEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        filesDetails: null
      };
    case userConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state,
        loading: false
      };

    case userConstants.FILE_UPLOAD_SELFIE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        filesDetailsSelfie: action.users.filesDetails
      };
    case userConstants.FILE_UPLOAD_SELFIE_CLEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        filesDetailsSelfie: null
      };
    case userConstants.FILE_UPLOAD_SELFIE_STATUS_FAILURE:
      return {
        ...state,
        loading: false
      };


    case userConstants.USER_DOC_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_DOC_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        docList: action.users.getDocDetails
      };
    case userConstants.USER_DOC_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_KYC_REQUEST:
      return {
        ...state,
        isKYCCreated: false,
        loading: true
      };
    case userConstants.GET_KYC_SUCCESS:
      return {
        ...state,
        loading: false,
        kycData: action.users.getKYC
      };
    case userConstants.GET_KYC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.USER_KYC_CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_KYC_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isKYCCreated: true,
      };
    case userConstants.USER_KYC_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case userConstants.GETALL_USER_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_USER_NOTIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        listOfNotification: action.users.listOfNotification.list,
        listOfNotificationtotal: action.users.listOfNotification.total
      };
    case userConstants.GETALL_USER_NOTIFY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_USER_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_USER_NOTIFY_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case userConstants.UPDATE_USER_NOTIFY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.CREATE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREATE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case userConstants.CREATE_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


      case userConstants.CREATE_ORDER_REQUEST:
        return {
          ...state,
          loading: true
        };
      case userConstants.CREATE_ORDER_SUCCESS:
        return {
          ...state,
          loading: false
        };
        case userConstants.CREATE_ORDER_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };
    


    case userConstants.UPDATE_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateAddressSuccess: true
      };
    case userConstants.UPDATE_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.STATS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        statics: action.users.statics
      };
    case userConstants.STATS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.RESET_USER_REDUX:
      return {
      };
    default:
      return state
  }
}