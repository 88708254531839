export const homeConstants = {

    GETALL_HOME_CATEGORY_REQUEST: 'GETALL_HOME_CATEGORY_REQUEST',
    GETALL_HOME_CATEGORY_SUCCESS: 'GETALL_HOME_CATEGORY_SUCCESS',
    GETALL_HOME_CATEGORY_FAILURE: 'GETALL_HOME_CATEGORY_FAILURE',

    GETALL_HOME_PRODUCT_REQUEST: 'GETALL_HOME_PRODUCT_REQUEST',
    GETALL_HOME_PRODUCT_SUCCESS: 'GETALL_HOME_PRODUCT_SUCCESS',
    GETALL_HOME_PRODUCT_FAILURE: 'GETALL_HOME_PRODUCT_FAILURE',

    GETALL_HOME_REVIEW_REQUEST: 'GETALL_HOME_REVIEW_REQUEST',
    GETALL_HOME_REVIEW_SUCCESS: 'GETALL_HOME_REVIEW_SUCCESS',
    GETALL_HOME_REVIEW_FAILURE: 'GETALL_HOME_REVIEW_FAILURE',

    GETALL_TOP_MOST_CATEGORY_REQUEST: 'GETALL_TOP_MOST_CATEGORY_REQUEST',
    GETALL_TOP_MOST_CATEGORY_SUCCESS: 'GETALL_TOP_MOST_CATEGORY_SUCCESS',
    GETALL_TOP_MOST_CATEGORY_FAILURE: 'GETALL_TOP_MOST_CATEGORY_FAILURE',

    GET_FEATURED_LIST_REQUEST: 'GET_FEATURED_LIST_REQUEST',
    GET_FEATURED_LIST_SUCCESS: 'GET_FEATURED_LIST_SUCCESS',
    GET_FEATURED_LIST_FAILURE: 'GET_FEATURED_LIST_FAILURE',

    GET_ARRIVALS_LIST_REQUEST: 'GET_ARRIVALS_LIST_REQUEST',
    GET_ARRIVALS_LIST_SUCCESS: 'GET_ARRIVALS_LIST_SUCCESS',
    GET_ARRIVALS_LIST_FAILURE: 'GET_ARRIVALS_LIST_FAILURE',

    GET_ARRIVALS_ID_REQUEST: 'GET_ARRIVALS_ID_REQUEST',
    GET_ARRIVALS_ID_SUCCESS: 'GET_ARRIVALS_ID_SUCCESS',
    GET_ARRIVALS_ID_FAILURE: 'GET_ARRIVALS_ID_FAILURE',
   
    GET_PRODUCT_ID_REQUEST: 'GET_PRODUCT_ID_REQUEST',
    GET_PRODUCT_ID_SUCCESS: 'GET_PRODUCT_ID_SUCCESS',
    GET_PRODUCT_ID_FAILURE: 'GET_PRODUCT_ID_FAILURE',

    GET_SLIDER_LIST_REQUEST: 'GET_SLIDER_LIST_REQUEST',
    GET_SLIDER_LIST_SUCCESS: 'GET_SLIDER_LIST_SUCCESS',
    GET_SLIDER_LIST_FAILURE: 'GET_SLIDER_LIST_FAILURE',

};

