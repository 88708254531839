import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { store } from './_helpers';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
 
import "@fontsource/inter/variable-full.css"; // Defaults to weight 100 to 900.
import "@fontsource/bodoni-moda/variable-full.css"; // Defaults to weight 100 to 900.
import "@fontsource/playfair-display/variable.css"; // Defaults to weight 100 to 900.
import "@fontsource/hepta-slab/variable.css"; // Defaults to weight 100 to 900.

import './indexoutput.css';
// import './index.css';
import 'react-toastify/dist/ReactToastify.css';



ReactDOM.render(
  <LayoutProvider>
        <ToastContainer />
        <Provider store={store}>
          <App />
        </Provider>
      
  </LayoutProvider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
