import order from '../pages/order/order';
import { orderConstants } from '../_constants';
import { orderService } from '../_services';
import { alertActions } from '.';

export const orderActions = {
    getOrderList,
};
function getOrderList(data) {
    console.log("data___kkkkkkkdata",data);
    return dispatch => {
        dispatch(request());
        orderService.getOrderList(data)
            .then(
                category => {
                    console.log("we are in action ", category);
                    dispatch(success(category))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );

    };
    function request() { return { type: orderConstants.GET_ORDER_LIST_REQUEST } }
    function success(category) { return { type: orderConstants.GET_ORDER_LIST_SUCCESS, category } }
    function failure(error) { return { type: orderConstants.GET_ORDER_LIST_FAILURE, error } }
}
