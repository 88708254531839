import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions, productActions } from '../../_actions';

import PublicHeader from "../../components/PublicHeader/PublicHeader";
import PublicFooter from "../../components/PublicFooter/PublicFooter";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";

// import React from "react";
// import Slider from "react-slick";
// Import css files
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const hero = {
//   dots: true,
//   dotsClass: "hero-dot absolute inset-x-0 bottom-5 space-x-3 justify-center",
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   arrows: false,
//   autoplay: true,

// };

class Products extends Component {
  constructor(props) {
    super(props);
    // this.props.dispatch(userActions.logout());
    this.state = {
      fieldslogin: {},
      errorslogin: {},
    };
  }


  componentDidMount() {

    this.props.dispatch(productActions.getProductList())

  }


  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    this.setState({ fieldslogin, errorslogin });
  };

  // forgotPassword = (e) => {
  //   e.preventDefault();
  //   if (this.handleValidationLogin()) {
  //     let { email } = this.state.fieldslogin;
  //     this.props.dispatch(
  //       userActions.forgotPassword(
  //         {
  //           email: email,
  //         },
  //         this.props,
  //       ),
  //     );
  //   }
  // };

  // otpSubmit = (e) => {
  //   e.preventDefault();
  //   if (this.handleValidationOTP()) {
  //     let { token } = this.props.forgotuser;
  //     let { OTP, newPassword, confirmnewPassword } = this.state.fieldslogin;
  //     this.props.dispatch(
  //       userActions.forgotUpdatePassword(
  //         {
  //           token: token,
  //           newPassword,
  //           confirmnewPassword,
  //           otp: OTP,
  //         },
  //         this.props,
  //       ),
  //     );
  //   }
  // };
  // resetForm = (e) => {
  //   e.preventDefault();
  //   this.setState({
  //     fieldslogin: {},
  //     errorslogin: {},
  //   });
  //   this.hideErrorMessage();
  // };

  // handleValidationOTP = () => {
  //   let fieldslogin = this.state.fieldslogin;
  //   let errorslogin = {};
  //   let formIsValid = true;

  //   //Name
  //   if (!fieldslogin["OTP"]) {
  //     formIsValid = false;
  //     errorslogin["OTP"] = "Please enter OTP!";
  //   }

  //   this.setState({ errorslogin: errorslogin });
  //   return formIsValid;
  // };

  // handleValidationLogin = () => {
  //   let fieldslogin = this.state.fieldslogin;
  //   let errorslogin = {};
  //   let formIsValid = true;

  //   //Email
  //   if (!fieldslogin["email"] || fieldslogin["email"] === "") {
  //     formIsValid = false;
  //     errorslogin["email"] = "Cannot be empty";
  //   }

  //   this.setState({ errorslogin: errorslogin });
  //   return formIsValid;
  // };

  onClickMenu = (url, data) => {
    console.log("datadatadata", data);
    this.props.history.push(url + data.id);
  };


  onClickMenu1 = (url) => {
    this.props.history.push(url);
  };

  addToCart = (data) => {
    console.log("data  ", data);
    let products = JSON.parse(localStorage.getItem("products"));
    if (products && products.length > 0) {
      let currentPrductindex = products.findIndex((element) => (element.id == data._id));
      console.log("currentPrductindex  ", currentPrductindex);

      if (currentPrductindex >= 0) {
        products[currentPrductindex]['count'] += 1;
        localStorage.setItem("products", JSON.stringify(products))
      } else {
        let product = {
          id: data._id,
          product: data,
          count: 1
        }
        products.push(product)
        localStorage.setItem("products", JSON.stringify(products))
      }
    } else {
      let product = [{
        id: data._id,
        product: data,
        count: 1
      }]
      localStorage.setItem("products", JSON.stringify(product))
    }
  }

  removeFromCart = (data) => {

    let products = JSON.parse(localStorage.getItem("products"));
    if (products && products.length > 0) {
      let currentPrductindex = products.filter((element) => (element.id != data._id));
      localStorage.setItem("products", JSON.stringify(currentPrductindex))
    }
  }

  render() {
    let { product } = this.props;
    let { productListItems  } = product;
    console.log("render productListItems ::::", productListItems); return (
      <>
        {/* Whole Structure */}
        <div className="overflow-hidden">
          {/* Headdr */}
          <PublicHeader />

          <main className="">
            {/* <Breadcrumb /> */}
            {/* Hero Section for Product Category/Family*/}
            <section className="relative z-20 items-center py-5 overflow-hidden">
              <div className="relative px-2 mx-auto max-w-screen-2xl sm:px-4 lg:px-8">
                <div className="relative w-full py-5 pr-16 rounded-md bg-gradient-to-br from-rose-500 via-primary to-primary-500 pl-28">
                
                  <div className="relative grid items-center">
                    <h1 className="col-span-8 text-4xl font-bold text-center leading-snug text-white font-headings sm:leading-snug lg:leading-tight dark:text-white">
                     All Products
                      {/* <span className="block font-sans text-xl font-light tracking-wide">Neelam Stone</span> */}
                    </h1>
                    <div className="relative hidden col-span-4 sm:block ">
                     
                    </div>

                  </div>
                </div>
              </div>
            </section>


            {/* Filters and shoter */}
            <section className="relative z-20 items-center pt-1 pb-6 overflow-hidden">
              <h2 className="hidden">Search Your dream product here</h2>
              <div className="relative flex px-2 mx-auto max-w-screen-2xl sm:px-4 lg:px-8">
                <div className="flex items-center justify-between flex-1">
                  <div>
                    {/* <button type="button" className="inline-flex items-center px-6 py-2 text-base font-medium text-white uppercase transition duration-300 ease-in-out border border-transparent rounded-md shadow-sm bg-primary hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">

                      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                      </svg>
                      Filter
                    </button> */}

                  </div>
                  <div className="flex items-center space-x-3">
                    {/* <p className="text-sm"><span className="text-primary-500">2000 Results</span> from 3,465,906 Products,</p> */}
                    <div className="relative z-0 inline-flex rounded-md shadow-sm">
                      <button onClick={() => this.onClickMenu1('/app/wishlist')}  title="Save this search" type="button" className="relative inline-flex items-center px-4 py-2 text-sm font-medium transition duration-300 ease-in-out bg-white border border-gray-300 rounded-l-md text-primary hover:bg-primary hover:text-white hover:border-primary focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-white-500">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                        </svg>

                      </button>

                      <button onClick={() => this.onClickMenu1('/cart')} title="Short By Name" type="button" className="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium transition duration-300 ease-in-out bg-white border border-gray-300 text-primary hover:bg-primary hover:text-white hover:border-primary focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-white-500">
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"
                          viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                            d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                        </svg>
                      </button>
                     
                    






                      {/* <button title="Short By Name" type="button" className="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium transition duration-300 ease-in-out bg-white border border-gray-300 text-primary hover:bg-primary hover:text-white hover:border-primary focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-white-500">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
                        </svg>
                      </button>
                      <button title="Short By price" type="button" className="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium transition duration-300 ease-in-out bg-white border border-gray-300 rounded-r-md text-primary hover:bg-primary hover:text-white hover:border-primary focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-white-500">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </button> */}
                    </div>
                    <div>

                    </div>
                  </div>
                </div>
              </div>
            </section>


            {/* Product Results */}
            <section className="relative z-20 items-center overflow-hidden dark:bg-gray-800">
              <div className="relative px-2 mx-auto max-w-screen-2xl sm:px-4 lg:px-8 ">

                <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">





                  {
                    productListItems && productListItems.length > 0 ?
                      productListItems.map((element, index) => (<React.Fragment >


                        <li className="relative flex flex-col ">
                          <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                            <img src={element.imageLink} alt="" className="object-cover pointer-events-none group-hover:opacity-75"  />
                            <button
                              type="button"
                              className="absolute inset-0 cursor-pointer focus:outline-none"
                              onClick={() => this.onClickMenu("/allProductDetails/", element)}>
                            
                              <span className="sr-only">
                                View details for IMG_4985.HEIC
                              </span>
                            </button>
                          </div>
                          <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                            {element && element.name ? element.name : "NA"}
                          </p>
                          <div className="flex items-center py-2 space-x-3">
                            <div className="flex items-center space-x-1 ">
                              <svg
                                className="w-3 h-3 text-gray-900 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                              </svg>
                              <svg
                                className="w-3 h-3 text-gray-900 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                              </svg>
                              <svg
                                className="w-3 h-3 text-gray-900 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                              </svg>
                              <svg
                                className="w-3 h-3 text-gray-900 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                              </svg>
                              <svg
                                className="w-3 h-3 text-gray-300 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                              </svg>
                            </div>
                            <span className="text-sm font-light">({element && element.quantity ? element.quantity : "NA"})</span>
                          </div>

                          <div className="flex items-center justify-between">
                            <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                              <strong className="font-semibold">${element && element.price ? element.price : "NA"}</strong>
                              {/* <span className="text-sm font-light text-gray-500 line-through">
                                $64.50
                              </span> */}
                            </p>

                            <div className="flex space-x-1">
                              {/* <button
                                className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                                id="headlessui-menu-button-14"
                                type="button"
                                aria-haspopup="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                                  />
                                </svg>
                              </button> */}
                              {/* <button
                                className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                                id="headlessui-menu-button-14"
                                type="button"
                                onClick={() => this.addToCart(element)}
                                aria-haspopup="true">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                                  />
                                </svg>
                              </button> */}
                            </div>
                          </div>
                        </li>



                      </React.Fragment>))
                      : (<div className="bg-white bg-opacity-5 " >
                        <span className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">Product Not Found</span>
                      </div>)
                  }




                  {" "}


















                  {/* <li className="relative flex flex-col ">
                    <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                      <img
                        src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                        alt=""
                        className="object-cover pointer-events-none group-hover:opacity-75"
                      />
                      <button
                        type="button"
                        className="absolute inset-0 cursor-pointer focus:outline-none"
                      >
                        <span className="sr-only">
                          View details for IMG_4985.HEIC
                        </span>
                      </button>
                    </div>
                    <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                      Limited Edition Birth of the Fae Queen the Fae Queen
                      Aurora’s
                    </p>
                    <div className="flex items-center py-2 space-x-3">
                      <div className="flex items-center space-x-1 ">
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-300 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                      </div>
                      <span className="text-sm font-light">(19.50)</span>
                    </div>

                    <div className="flex items-center justify-between">
                      <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                        <strong className="font-semibold">$55.00</strong>
                        <span className="text-sm font-light text-gray-500 line-through">
                          $64.50
                        </span>
                      </p>

                      <div className="flex space-x-1">
                        <button
                          className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          id="headlessui-menu-button-14"
                          type="button"
                          aria-haspopup="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                            />
                          </svg>
                        </button>
                        <button
                          className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          id="headlessui-menu-button-14"
                          type="button"
                          aria-haspopup="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </li>{" "}
                  <li className="relative flex flex-col ">
                    <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                      <img
                        src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                        alt=""
                        className="object-cover pointer-events-none group-hover:opacity-75"
                      />
                      <button
                        type="button"
                        className="absolute inset-0 cursor-pointer focus:outline-none"
                      >
                        <span className="sr-only">
                          View details for IMG_4985.HEIC
                        </span>
                      </button>
                    </div>
                    <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                      Limited Edition Birth of the Fae Queen the Fae Queen
                      Aurora’s
                    </p>
                    <div className="flex items-center py-2 space-x-3">
                      <div className="flex items-center space-x-1 ">
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-300 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                      </div>
                      <span className="text-sm font-light">(19.50)</span>
                    </div>

                    <div className="flex items-center justify-between">
                      <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                        <strong className="font-semibold">$55.00</strong>
                        <span className="text-sm font-light text-gray-500 line-through">
                          $64.50
                        </span>
                      </p>

                      <div className="flex space-x-1">
                        <button
                          className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          id="headlessui-menu-button-14"
                          type="button"
                          aria-haspopup="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                            />
                          </svg>
                        </button>
                        <button
                          className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          id="headlessui-menu-button-14"
                          type="button"
                          aria-haspopup="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </li>{" "}
                  <li className="relative flex flex-col ">
                    <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                      <img
                        src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                        alt=""
                        className="object-cover pointer-events-none group-hover:opacity-75"
                      />
                      <button
                        type="button"
                        className="absolute inset-0 cursor-pointer focus:outline-none"
                      >
                        <span className="sr-only">
                          View details for IMG_4985.HEIC
                        </span>
                      </button>
                    </div>
                    <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                      Limited Edition Birth of the Fae Queen the Fae Queen
                      Aurora’s
                    </p>
                    <div className="flex items-center py-2 space-x-3">
                      <div className="flex items-center space-x-1 ">
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-300 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                      </div>
                      <span className="text-sm font-light">(19.50)</span>
                    </div>

                    <div className="flex items-center justify-between">
                      <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                        <strong className="font-semibold">$55.00</strong>
                        <span className="text-sm font-light text-gray-500 line-through">
                          $64.50
                        </span>
                      </p>

                      <div className="flex space-x-1">
                        <button
                          className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          id="headlessui-menu-button-14"
                          type="button"
                          aria-haspopup="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                            />
                          </svg>
                        </button>
                        <button
                          className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          id="headlessui-menu-button-14"
                          type="button"
                          aria-haspopup="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </li>{" "}
                  <li className="relative flex flex-col ">
                    <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                      <img
                        src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                        alt=""
                        className="object-cover pointer-events-none group-hover:opacity-75"
                      />
                      <button
                        type="button"
                        className="absolute inset-0 cursor-pointer focus:outline-none"
                      >
                        <span className="sr-only">
                          View details for IMG_4985.HEIC
                        </span>
                      </button>
                    </div>
                    <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                      Limited Edition Birth of the Fae Queen the Fae Queen
                      Aurora’s
                    </p>
                    <div className="flex items-center py-2 space-x-3">
                      <div className="flex items-center space-x-1 ">
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-300 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                      </div>
                      <span className="text-sm font-light">(19.50)</span>
                    </div>

                    <div className="flex items-center justify-between">
                      <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                        <strong className="font-semibold">$55.00</strong>
                        <span className="text-sm font-light text-gray-500 line-through">
                          $64.50
                        </span>
                      </p>

                      <div className="flex space-x-1">
                        <button
                          className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          id="headlessui-menu-button-14"
                          type="button"
                          aria-haspopup="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                            />
                          </svg>
                        </button>
                        <button
                          className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          id="headlessui-menu-button-14"
                          type="button"
                          aria-haspopup="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </li>{" "}
                  <li className="relative flex flex-col ">
                    <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                      <img
                        src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                        alt=""
                        className="object-cover pointer-events-none group-hover:opacity-75"
                      />
                      <button
                        type="button"
                        className="absolute inset-0 cursor-pointer focus:outline-none"
                      >
                        <span className="sr-only">
                          View details for IMG_4985.HEIC
                        </span>
                      </button>
                    </div>
                    <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                      Limited Edition Birth of the Fae Queen the Fae Queen
                      Aurora’s
                    </p>
                    <div className="flex items-center py-2 space-x-3">
                      <div className="flex items-center space-x-1 ">
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-300 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                      </div>
                      <span className="text-sm font-light">(19.50)</span>
                    </div>

                    <div className="flex items-center justify-between">
                      <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                        <strong className="font-semibold">$55.00</strong>
                        <span className="text-sm font-light text-gray-500 line-through">
                          $64.50
                        </span>
                      </p>

                      <div className="flex space-x-1">
                        <button
                          className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          id="headlessui-menu-button-14"
                          type="button"
                          aria-haspopup="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                            />
                          </svg>
                        </button>
                        <button
                          className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          id="headlessui-menu-button-14"
                          type="button"
                          aria-haspopup="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </li>{" "}
                  <li className="relative flex flex-col ">
                    <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                      <img
                        src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                        alt=""
                        className="object-cover pointer-events-none group-hover:opacity-75"
                      />
                      <button
                        type="button"
                        className="absolute inset-0 cursor-pointer focus:outline-none"
                      >
                        <span className="sr-only">
                          View details for IMG_4985.HEIC
                        </span>
                      </button>
                    </div>
                    <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                      Limited Edition Birth of the Fae Queen the Fae Queen
                      Aurora’s
                    </p>
                    <div className="flex items-center py-2 space-x-3">
                      <div className="flex items-center space-x-1 ">
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-300 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                      </div>
                      <span className="text-sm font-light">(19.50)</span>
                    </div>

                    <div className="flex items-center justify-between">
                      <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                        <strong className="font-semibold">$55.00</strong>
                        <span className="text-sm font-light text-gray-500 line-through">
                          $64.50
                        </span>
                      </p>

                      <div className="flex space-x-1">
                        <button
                          className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          id="headlessui-menu-button-14"
                          type="button"
                          aria-haspopup="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                            />
                          </svg>
                        </button>
                        <button
                          className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          id="headlessui-menu-button-14"
                          type="button"
                          aria-haspopup="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </li>{" "}
                  <li className="relative flex flex-col ">
                    <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                      <img
                        src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                        alt=""
                        className="object-cover pointer-events-none group-hover:opacity-75"
                      />
                      <button
                        type="button"
                        className="absolute inset-0 cursor-pointer focus:outline-none"
                      >
                        <span className="sr-only">
                          View details for IMG_4985.HEIC
                        </span>
                      </button>
                    </div>
                    <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                      Limited Edition Birth of the Fae Queen the Fae Queen
                      Aurora’s
                    </p>
                    <div className="flex items-center py-2 space-x-3">
                      <div className="flex items-center space-x-1 ">
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-900 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                        <svg
                          className="w-3 h-3 text-gray-300 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                        </svg>
                      </div>
                      <span className="text-sm font-light">(19.50)</span>
                    </div>

                    <div className="flex items-center justify-between">
                      <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                        <strong className="font-semibold">$55.00</strong>
                        <span className="text-sm font-light text-gray-500 line-through">
                          $64.50
                        </span>
                      </p>

                      <div className="flex space-x-1">
                        <button
                          className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          id="headlessui-menu-button-14"
                          type="button"
                          aria-haspopup="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                            />
                          </svg>
                        </button>
                        <button
                          className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                          id="headlessui-menu-button-14"
                          type="button"
                          aria-haspopup="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </li> */}
                  {" "}
                  {/* More files... */}
                </ul>
              </div>
            </section>

            {/* Pagination */}

            <nav aria-label="Page navigation" className="pt-10 pb-7">
              <div className="px-2 mx-auto max-w-screen-2xl sm:px-4 lg:px-8">
                <div className="relative flex items-center justify-between border-t border-gray-200 pt-7">
                  <p className="space-x-1 text-sm tracking-wider text-gray-700">
                    Showing
                    {/* space */}
                    <span className="font-medium">1</span>
                    {/* space */}
                    to
                    {/* space */}
                    <span className="font-medium">10</span>
                    {/* space */}
                    of
                    {/* space */}
                    <span className="font-medium">97</span>
                    {/* space */}
                    results
                  </p>
                  <ul className="inline-flex space-x-2">
                    <li><button className="flex items-center justify-center w-10 h-10 text-gray-500 transition-colors duration-150 bg-opacity-0 rounded focus:outline-none hover:text-primary-500 focus:shadow-outline hover:bg-opacity-20 bg-primary">
                      <svg className="w-5 h-5 fill-current" viewBox="0 0 20 20"><path d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" fill-rule="evenodd"></path></svg></button>
                    </li>
                    <li><button className="w-10 h-10 font-medium text-gray-500 transition-colors duration-150 bg-opacity-0 rounded focus:outline-none hover:text-primary-500 focus:shadow-outline hover:bg-opacity-20 bg-primary">1</button></li>
                    <li><button className="w-10 h-10 font-medium text-gray-500 transition-colors duration-150 bg-opacity-0 rounded focus:outline-none hover:text-primary-500 focus:shadow-outline hover:bg-opacity-20 bg-primary">2</button></li>
                    <li><button className="w-10 h-10 font-medium text-white transition-colors duration-150 border border-r-0 rounded focus:outline-none bg-primary border-primary focus:shadow-outline">3</button></li>
                    <li><button className="flex items-center justify-center w-10 h-10 text-gray-500 transition-colors duration-150 bg-white bg-opacity-0 rounded focus:outline-none hover:text-primary-500 focus:shadow-outline hover:bg-opacity-20 bg-primary">
                      <svg className="w-5 h-5 fill-current" viewBox="0 0 20 20"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" fill-rule="evenodd"></path></svg></button>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>


            {/* <div className="px-2 pb-10 mx-auto space-y-4 max-w-screen-2xl sm:px-4 lg:px-8">
              <div class="flex-1 flex gap-3 bg-white ring-1 shadow rounded p-3 ring-gray-200 items-center">
                <p className="text-sm font-medium text-gray-500">Related Search Tags</p>
                <span className="px-4 py-1 text-sm text-gray-500 capitalize transition duration-300 ease-in-out bg-gray-100 rounded hover:bg-primary hover:text-white">onyx gemstone</span>
                <span className="px-4 py-1 text-sm text-gray-500 capitalize transition duration-300 ease-in-out bg-gray-100 rounded hover:bg-primary hover:text-white">beads gemstone</span>
                <span className="px-4 py-1 text-sm text-gray-500 capitalize transition duration-300 ease-in-out bg-gray-100 rounded hover:bg-primary hover:text-white">jewelry gemstone</span>
                <span className="px-4 py-1 text-sm text-gray-500 capitalize transition duration-300 ease-in-out bg-gray-100 rounded hover:bg-primary hover:text-white">cz gemstone</span>
                <span className="px-4 py-1 text-sm text-gray-500 capitalize transition duration-300 ease-in-out bg-gray-100 rounded hover:bg-primary hover:text-white">Crystal</span>
              </div>
              <div class="flex-1 grid grid-cols-2 gap-y-3 bg-white ring-1 shadow rounded  ring-gray-200">
                <div class="flex flex-col p-3 gap-3">
                  <p className="text-sm font-medium text-gray-500">Did you find what you were looking for？</p>
                  <div className="flex gap-3">
                    <button type="button" class="w-16 justify-center inline-flex items-center px-4 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-pink-50 hover:border-primary hover:text-primary-500 focus:outline-none">
                      Yes
                    </button>
                    <button type="button" class="w-16 justify-center inline-flex items-center px-4 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-pink-50 hover:border-primary hover:text-primary-500 focus:outline-none">
                      No
                    </button>
                  </div>
                </div>
                <div className="flex flex-col items-end gap-3 p-3 border-l border-gray-200">
                  <p className="text-sm font-medium text-gray-500">Need help?</p>
                  <div className="flex gap-3">
                    <button type="button" class="justify-center inline-flex items-center px-4 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-pink-50 hover:border-primary hover:text-primary-500 focus:outline-none">
                      Contact to help center
                    </button>
                    <button type="button" class="justify-center inline-flex items-center px-4 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-pink-50 hover:border-primary hover:text-primary-500 focus:outline-none">
                      Write to us
                    </button>
                  </div>
                </div>
              </div>

            </div> */}


            {/* Recently viewed */}
            {/* <section className="relative z-20 items-center overflow-hidden dark:bg-gray-800">
              <div className="relative px-2 pb-10 mx-auto max-w-screen-2xl sm:px-4 lg:px-8">
                <div className="relative mb-5">
                  <h2 className="text-xl font-semibold tracking-tight text-left text-gray-900 sm:text-2xl">
                    Recently Viewed
                  </h2>

                </div>
                <div className="overflow-hidden">
                  <ul className="pb-5 -mx-2 overflow-auto scrolling-touch whitespace-nowrap max-w-none scrollbar-w-4">
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img src="/dist/img/stone/stone1.webp" alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg className="w-3 h-3 text-gray-900 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg className="w-3 h-3 text-gray-900 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg className="w-3 h-3 text-gray-900 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg className="w-3 h-3 text-gray-900 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg className="w-3 h-3 text-gray-900 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>{" "}
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img src="/dist/img/stone/stone2.webp" alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-300 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img src="/dist/img/stone/stone3.webp" alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-300 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img src="/dist/img/stone/stone4.webp" alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-300 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>{" "}
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img src="/dist/img/stone/stone5.webp" alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-300 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>{" "}
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img src="/dist/img/stone/stone6.webp" alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-300 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>{" "}
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img src="/dist/img/stone/stone7.webp" alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-300 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>{" "}
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img src="/dist/img/stone/stone8.webp" alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-300 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>{" "}
                  
                  </ul>
                </div>
              </div>
            </section> */}

            {/* Other Similar */}
            {/* <section className="relative z-20 items-center overflow-hidden dark:bg-gray-800">
              <div className="relative px-2 pb-10 mx-auto max-w-screen-2xl sm:px-4 lg:px-8">
                <div className="relative mb-5">
                  <h2 className="text-xl font-semibold tracking-tight text-left text-gray-900 sm:text-2xl">
                    Other Similar
                  </h2>

                </div>
                <div className="overflow-hidden">
                  <ul className="pb-5 -mx-2 overflow-auto scrolling-touch whitespace-nowrap max-w-none scrollbar-w-4">
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img
                          src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                          alt=""
                          className="object-cover pointer-events-none group-hover:opacity-75"
                        />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-300 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            className="flex p-1 text-gray-400 rounded-full hover:text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>{" "}
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img
                          src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                          alt=""
                          className="object-cover pointer-events-none group-hover:opacity-75"
                        />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-300 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img
                          src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                          alt=""
                          className="object-cover pointer-events-none group-hover:opacity-75"
                        />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-300 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img
                          src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                          alt=""
                          className="object-cover pointer-events-none group-hover:opacity-75"
                        />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-300 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>{" "}
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img
                          src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                          alt=""
                          className="object-cover pointer-events-none group-hover:opacity-75"
                        />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-300 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>{" "}
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img
                          src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                          alt=""
                          className="object-cover pointer-events-none group-hover:opacity-75"
                        />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-300 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>{" "}
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img
                          src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                          alt=""
                          className="object-cover pointer-events-none group-hover:opacity-75"
                        />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-300 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>{" "}
                    <li className="inline-flex flex-col relative p-2 w-[200px]">
                      <div className="block w-full overflow-hidden bg-gray-100 group aspect-w-10 aspect-h-7 ">
                        <img
                          src="https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80"
                          alt=""
                          className="object-cover pointer-events-none group-hover:opacity-75"
                        />
                        <button
                          type="button"
                          className="absolute inset-0 cursor-pointer focus:outline-none"
                        >
                          <span className="sr-only">
                            View details for IMG_4985.HEIC
                          </span>
                        </button>
                      </div>
                      <p className="block mt-2 text-sm font-medium text-gray-900 truncate cursor-pointer">
                        Limited Edition Birth of the Fae Queen the Fae Queen
                        Aurora’s
                      </p>
                      <div className="flex items-center py-2 space-x-3">
                        <div className="flex items-center space-x-1 ">
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-900 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                          <svg
                            className="w-3 h-3 text-gray-300 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                          </svg>
                        </div>
                        <span className="text-sm font-light">(19.50)</span>
                      </div>

                      <div className="flex items-center justify-between">
                        <p className="flex items-end space-x-2 font-medium text-gray-900 ">
                          <strong className="font-semibold">$55.00</strong>
                          <span className="text-sm font-light text-gray-500 line-through">
                            $64.50
                          </span>
                        </p>

                        <div className="flex space-x-1">
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                              />
                            </svg>
                          </button>
                          <button
                            class="hover:text-primary rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary text-gray-400 p-1"
                            id="headlessui-menu-button-14"
                            type="button"
                            aria-haspopup="true"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </li>{" "}

                  </ul>
                </div>
              </div>
            </section> */}



          </main>

          {/* Footer */}
          <PublicFooter />
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn, otpSent, forgotuser } = state.authentication;
  const { users, product } = state;
  return {
    loggingIn,
    forgotuser,
    otpSent,
    users,
    product
  };
}
export default connect(mapStateToProps)(Products);
