import React, { Component } from "react";
import { connect } from "react-redux";
import { orderActions, userActions } from "../../_actions";
import { dashboardActions } from "../../_actions";
import Sticky from "wil-react-sticky";
// import CreateSliderModal from "./components/CreateSliderModal/CreateSliderModal";
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import PublicFooter from "../../components/PublicFooter/PublicFooter";
import { authHeader } from '../../_helpers';

class order extends Component {
  constructor(props) {
    super(props);
    // this.props.dispatch(userActions.logout());

    this.state = {
      fieldslogin: {},
      errorslogin: {},
      nav1: null,
      nav2: null,
      productItems: null,
      finalTotalNewPrice: null,
      finalTotalOldPrice: null,
      depositIndexCreateModal: false,
      totalAmount: '',
      sliderCreateModal: false,
      keyWord: '',
      pageNo: '',
      size: ''
    };
  }

  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    console.log("data___data::::data", data);
    this.props.dispatch(orderActions.getOrderList(data));
  }

  onClickMenu = (url) => {
    this.props.history.push(url);
  };
  onClickMenu2 = (url, data) => {
    console.log("datadatadata______________", data);
    console.log("datadatadata______________", url + data.id);
    this.props.history.push(url + data.id);
  };

  render() {
    const truncate = (input) =>
      input?.length > 60 ? `${input.substring(0, 40)}...` : input;
    let { order } = this.props;
    let { orderData, total } = order
    console.log("fieldsloginfieldsloginfieldslogin:::", orderData);


    let { user } = this.props;
    return (
      <>
        <div className="overflow-hidden">
          {/* Headdr */}
          <PublicHeader />
          <main className="bg-gray-50">

            <section className="py-4  ">

              <div className="max-w-screen-2xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8 pb-5 sm:pb-20 lg:pb-24">

                <div className="flex flex-wrap justify-between lg:flex-row  cart-container">

                  <div className="w-full">
                    <div className="mt-5">
                      <div className="mb-4 text-left heading ">
                        <h2 className="pb-2 text-left text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl ">Order list</h2>
                        <p className="text-sm text-left text-gray-400 mt-2">All Ordered items</p>
                      </div>
                      <div className="md:pt-4">
                        <div className=" md:flex justify-between border-b border-black border-opacity-20">
                          <div className="w-1/2 pb-2 xl:w-1/2 ">
                            <span className="text-sm uppercase font-normal text-gray-600 ">Product</span>
                          </div>



                          <div className="w-1/5 pb-2  ">
                            <span className="text-sm uppercase font-normal text-gray-600 ">total Price</span>
                          </div>
                          <div className="w-1/5 pb-2    ">
                            <span className="text-sm uppercase font-normal text-gray-600 ">Order Status</span>
                          </div>


                          <div className="w-1/5 pb-2  text-center">
                            <span className="text-sm uppercase font-normal text-gray-600 ">Address</span>
                          </div>
                        </div>

                        <div className="max-h-[500px] overflow-auto pr-10">

                          {orderData && orderData && orderData.length > 0 ? orderData.map((element, index) =>
                            <>
                              <div className="flex flex-wrap lg:flex-nowrap justify-between items-center relative py-4 lg:py-8 border-b border-black border-opacity-20">
                                <div className="md:w-1/2 w-full flex items-center">
                                  <div className="rounded bg-gray-100  pr-0 mx-0">

                                    {element && element.orderItems && element.orderItems.length > 0 ? element.orderItems.map((innerelement, innerindex) =>
                                      <div className="flex justify-start items-center">
                                        <span className=" text-sm lg:text-base font-bold text-black block ">
                                          {/* {JSON.stringify(innerelement)} */}
                                          <img src={innerelement.arrivalId && innerelement.arrivalId.imageLink} alt="productImage" className="object-cover h-24 w-24" />
                                        </span>
                                        <button type="button" className="absolute inset-0 cursor-pointer focus:outline-none" onClick={() => this.onClickMenu2("/productDetails/", innerelement.arrivalId)}>
                                          <span className="sr-only">View details for IMG_4985.HEIC </span>
                                        </button>
                                        <span className="text-sm px-4 lg:text-base font-bold text-black block ">
                                          {innerelement.arrivalId && innerelement.arrivalId.name ? innerelement.arrivalId.name : 'no data'}
                                        </span>


                                        <div>
                                          <span className="mt-2 ml-4 text-sm lg:text-base font-bold text-black block ">{innerelement && innerelement.quantity ? innerelement.quantity : 'no data'}</span>
                                        </div>
                                      </div>

                                    ) : null
                                    }
                                  </div>

                                </div>



                                <div className=" w-1/5 flex items-center ">
                                  <span className="mt-2 ml-4 text-sm lg:text-base font-bold text-black block ">{element.price}</span>
                                </div>
                                <div className=" w-1/5 flex items-center">
                                  <span className="mt-2 ml-4 text-sm lg:text-base font-bold text-black block ">{element.orderStatus}</span>
                                </div>

                                <div className="w-2/5  mt-3">
                                  <span className="mt-2 ml-4 text-sm lg:text-base font-bold text-black block whitespace-normal text-right">{element && element.addressId && element.addressId.fullName ? element.addressId.fullName : 'no data'},  </span>

                                  <span className="mt-2 ml-4 text-sm lg:text-base font-bold text-black block whitespace-normal text-right">{element && element.addressId && element.addressId.Address ? element.addressId.Address : 'no data'}, </span>

                                  <span className="mt-2 ml-4 text-sm lg:text-base font-bold text-black block whitespace-normal text-right">{element.addressId.city},{element.addressId.state},{element.addressId.pin}  </span>
                                </div>
                              </div>
                            </>

                          ) : null
                          }


                        </div>
                      </div>

                      <div className="flex justify-start items-end pt-5 space-x-5" onClick={() => this.onClickMenu("/home")}>
                        <button className="px-10 text-center py-3 text-white uppercase text-base rounded hover:bg-primary-500 bg-primary block transition ease-in-out duration-150">Continue shopping</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </section>

          </main>

          <PublicFooter />
        </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, order } = state;
  return {
    order,
    users,
  };
}
export default connect(mapStateToProps)(order);
