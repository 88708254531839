import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions, dashboardActions } from './';
// import { history } from '../_helpers';
export const userActions = {
    logout1,
    resetUser,
    changepassword,
    getAllCategory,
    uploadImageClearSelfie,
    uploadImageClear,
    getKYC,
    updatePassword,
    uploadImageSelfie,
    uploadImage,
    swapAmount,
    getPrice,
    getTx,
    sendCoin,
    getTicketList,
    createTicket,
    createKYC,
    getDocumentList,
    userlogin,
    loginValidateOtp,
    registerValidateOtp,
    registerClient,
    logout,
    forgotUpdatePassword,
    getUserDetails,
    updateUserInfo,
    getUserProfile,
    createAddress,
    updateAddress,
    getAddressList,
    updateAddressStatus,
    createOrder,
    disableAddress,

};

function createOrder(order, props, data) {
    return dispatch => {
        dispatch(request({order , data }));
        userService.createOrder(order)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success(users.addUser.message));
                    dispatch(this.OrderDetails());
                    // dispatch(this.OrderList(data));
                    // window.location.reload(data);
                    console.log("datadata________",data);
                    props.history.push('/thankyou')
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: userConstants.CREATE_ORDER_REQUEST } }
    function success(users) { return { type: userConstants.CREATE_ORDER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CREATE_ORDER_FAILURE, error } }
}

function updateAddressStatus(data) {
    return dispatch => {
        dispatch(request());
        userService.updateAddressStatus(data)
            .then(
                address => {
                    console.log("dashboardsdashboardsdashboardsdashboards", address);
                    dispatch(success(address));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.STATUS_ADDRESS_REQUEST } }
    function success(dashboard) { return { type: userConstants.STATUS_ADDRESS_SUCCESS, dashboard } }
    function failure(error) { return { type: userConstants.STATUS_ADDRESS_FAILURE, error } }
}

function disableAddress(data) {
    return dispatch => {
        dispatch(request());
        userService.disableAddress(data)
            .then(
                address => {
                    console.log("dashboardsdas____________", address);
                    dispatch(success(address));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.DISABLE_ADDRESS_REQUEST } }
    function success(dashboard) { return { type: userConstants.DISABLE_ADDRESS_SUCCESS, dashboard } }
    function failure(error) { return { type: userConstants.DISABLE_ADDRESS_FAILURE, error } }
}

function getAddressList(data) {
    return dispatch => {
        dispatch(request());
        userService.getAddressList(data)
            .then(
                address => {
                    console.log("dashboardsdashboardsdashboardsdashboards", address);
                    dispatch(success(address));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_ADDRESS_REQUEST } }
    function success(dashboard) { return { type: userConstants.GETALL_ADDRESS_SUCCESS, dashboard } }
    function failure(error) { return { type: userConstants.GETALL_ADDRESS_FAILURE, error } }
}

function updateAddress(add, pagination, props) {
    return dispatch => {
        dispatch(request({ add }));
        userService.updateAddress(add)
            .then(
                users => {
                    dispatch(success(users));
                    // dispatch(alertActions.success(users.addUser.message));
                    console.log("ho gya ???");

                    // dispatch(this.getUserDetails());
                    dispatch(this.getAddressList(pagination));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: userConstants.UPDATE_ADDRESS_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_ADDRESS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_ADDRESS_FAILURE, error } }
}
function createAddress(add, props, data) {
    return dispatch => {
        dispatch(request({ add, data }));
        userService.createAddress(add)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success(users.addUser.message));
                    dispatch(this.getUserDetails());
                    dispatch(this.getAddressList(data));
                    window.location.reload(data);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: userConstants.CREATE_ADDRESS_REQUEST } }
    function success(users) { return { type: userConstants.CREATE_ADDRESS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.CREATE_ADDRESS_FAILURE, error } }
}

function userlogin(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.userlogin(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    props.history.push(`/home`)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}


function registerClient(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.registerClient(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    props.history.push(`/home`)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_FIRST_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_FIRST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FIRST_FAILURE, error } }
}

function getAllCategory(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.getAllCategory(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getUserDetails());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: userConstants.GET_USER_INFO_REQUEST } }
    function success(users) { return { type: userConstants.GET_USER_INFO_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_USER_INFO_FAILURE, error } }
}

function getUserProfile(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.getUserProfile(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: userConstants.USER_INFO_REQUEST } }
    function success(users) { return { type: userConstants.USER_INFO_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_INFO_FAILURE, error } }
}

function updatePassword(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.updatePassword(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success(users.addUser.message));
                    dispatch(this.getUserDetails());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: userConstants.UPDATE_PASSWORD_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_PASSWORD_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_PASSWORD_FAILURE, error } }
}


function loginValidateOtp(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.loginValidateOtp(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    props.history.push(`/app/dashboard`)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function changepassword(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.changepassword(data)
            .then(
                user => {
                    dispatch(success(user));

                    dispatch(alertActions.success(user.userinfo.message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.FORGOT_FIRST_REQUEST, user } }
    function success(user) { return { type: userConstants.FORGOT_FIRST_SUCCESS, user } }
    function failure(error) { return { type: userConstants.FORGOT_FIRST_FAILURE, error } }
}

function sendCoin(data) {
    let temp = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request({ data }));
        userService.sendCoin(data)
            .then(
                users => {
                    let message = users.sendCoin && users.sendCoin.message ? users.sendCoin.message : "NA";
                    dispatch(success(users));
                    dispatch(this.getUserDetails());
                    dispatch(this.getTx(temp));
                    dispatch(alertActions.success(message));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: userConstants.SEND_COIN_REQUEST, user } }
    function success(user) { return { type: userConstants.SEND_COIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.SEND_COIN_FAILURE, error } }
}
function registerValidateOtp(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.registerValidateOtp(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    props.history.push(`/app/dashboard`)
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    return dispatch => {
        dispatch(dashboardActions.resetDashboard());
        dispatch(this.resetUser());
        dispatch(this.logout1());
    };
}

function logout1() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function forgotUpdatePassword(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.forgotUpdatePassword(data)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(alertActions.success(user.userinfo.message));
                    setTimeout(() => {
                        props.history.push(`/userlogin`)
                    }, 3000);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function getUserDetails() {

    return dispatch => {
        dispatch(request());
        userService.getUserDetails()
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_DETAILS_REQUEST } }
    function success(users) { return { type: userConstants.USER_DETAILS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_DETAILS_FAILURE, error } }
}

function updateUserInfo(data) {

    return dispatch => {
        dispatch(request());
        userService.updateUserInfo(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(alertActions.success(users.updateUserInfo.message));
                    dispatch(this.getUserDetails());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USER_DETAILS_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USER_DETAILS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USER_DETAILS_FAILURE, error } }
}

function getPrice(priceData) {

    return dispatch => {
        dispatch(request());
        userService.getPrice(priceData)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.PRICE_REQUEST } }
    function success(users) { return { type: userConstants.PRICE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.PRICE_FAILURE, error } }
}

function swapAmount(priceData) {
    let temp = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        userService.swapAmount(priceData)
            .then(
                users => {
                    let message = users.swapAmount && users.swapAmount.message ? users.swapAmount.message : "NA";
                    dispatch(success(users));
                    dispatch(this.getUserDetails());
                    dispatch(this.getTx(temp));
                    dispatch(alertActions.success(message));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.SWAP_REQUEST } }
    function success(users) { return { type: userConstants.SWAP_SUCCESS, users } }
    function failure(error) { return { type: userConstants.SWAP_FAILURE, error } }
}

function getDocumentList() {

    return dispatch => {
        dispatch(request());
        userService.getDocumentList()
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_DOC_LIST_REQUEST } }
    function success(users) { return { type: userConstants.USER_DOC_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_DOC_LIST_FAILURE, error } }
}

function getKYC() {
    return dispatch => {
        dispatch(request());
        userService.getKYC()
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_KYC_REQUEST } }
    function success(users) { return { type: userConstants.GET_KYC_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_KYC_FAILURE, error } }
}

function createKYC(data) {

    return dispatch => {
        dispatch(request());
        userService.createKYC(data)
            .then(
                users => {
                    dispatch(this.getKYC());
                    dispatch(this.uploadImageClear());
                    dispatch(this.uploadImageClearSelfie());
                    dispatch(success(users));
                    dispatch(alertActions.success("Success"));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_KYC_CREATE_REQUEST } }
    function success(users) { return { type: userConstants.USER_KYC_CREATE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_KYC_CREATE_FAILURE, error } }
}

function uploadImage(data) {
    return dispatch => {
        userService.uploadImage(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(users) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}

function uploadImageSelfie(data) {
    return dispatch => {
        userService.uploadImage(data)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(users) { return { type: userConstants.FILE_UPLOAD_SELFIE_STATUS_SUCCESS, users } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_SELFIE_STATUS_FAILURE, error } }
}

function createTicket(data) {

    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }

    return dispatch => {
        dispatch(request());
        userService.createTicket(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getTicketList(tempdata));
                    dispatch(alertActions.success("Success"));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_TICKET_CREATE_REQUEST } }
    function success(users) { return { type: userConstants.USER_TICKET_CREATE_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_TICKET_CREATE_FAILURE, error } }
}

function getTicketList(data) {

    return dispatch => {
        dispatch(request());
        userService.getTicketList(data)

            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.USER_TICKET_LIST_REQUEST } }
    function success(users) { return { type: userConstants.USER_TICKET_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.USER_TICKET_LIST_FAILURE, error } }
}

function getTx(data) {

    return dispatch => {
        dispatch(request());
        userService.getTx(data)

            .then(
                users => {
                    dispatch(success(users));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.TX_LIST_REQUEST } }
    function success(users) { return { type: userConstants.TX_LIST_SUCCESS, users } }
    function failure(error) { return { type: userConstants.TX_LIST_FAILURE, error } }
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function uploadImageClearSelfie() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_SELFIE_CLEAR_SUCCESS, uploadImage } }
}

function resetUser() {
    return dispatch => {
        dispatch(success());
    };
    function success() { return { type: userConstants.RESET_USER_REDUX } }
}
