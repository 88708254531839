import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions, homeActions } from "../../_actions";
import Sticky from "wil-react-sticky";
import CreateSliderModal from "./components/CreateSliderModal/CreateSliderModal";
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import PublicFooter from "../../components/PublicFooter/PublicFooter";
import { authHeader } from '../../_helpers';
import Slider from "react-slick";

class Singleproductby extends Component {
  constructor(props) {
    super(props);
    // this.props.dispatch(userActions.logout());

    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      nav1: null,
      nav2: null,
      productItems: null,
      depositIndexCreateModal: false,
      totalAmount: '',
      sliderCreateModal: false,
    };
  }


  componentDidMount() {
    let dataReq = {
      "id": this.props.match.params.productId,
    }
    this.props.dispatch(homeActions.getArrivalsById(dataReq));
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });

    console.log("this.props", this.props);
    this.loadScript("https://checkout.razorpay.com/v1/checkout.js");
    window.scrollTo(0, 0)
    // window.localStorage.clear();
  }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    console.log(name, value);
    this.setState({ fieldslogin, errorslogin });
  }

  loginSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let { email, password, } = this.state;
      this.props.dispatch(userActions.userlogin({ email: email, password: password, }, this.props));
    }
  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    })
    this.hideErrorMessage();
  }


  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Email
    if (!fieldslogin["email"] || fieldslogin["email"] === "") {
      formIsValid = false;
      errorslogin["email"] = "Cannot be empty";
    }
    if (typeof fieldslogin["email"] !== "undefined" && fieldslogin["email"] !== "") {
      let lastAtPos = fieldslogin["email"].lastIndexOf('@');
      let lastDotPos = fieldslogin["email"].lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fieldslogin["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fieldslogin["email"].length - lastDotPos) > 2)) {
        formIsValid = false;
        errorslogin["email"] = "Enter valid email!";
      }
    }
    //Password
    if (!fieldslogin["password"]) {
      formIsValid = false;
      errorslogin["password"] = "Please enter password!";
    }
    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  }

  loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  forgotPassword = (e) => {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let { email } = this.state.fieldslogin;
      this.props.dispatch(
        userActions.forgotPassword(
          {
            email: email,
          },
          this.props,
        ),
      );
    }
  };

  otpSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidationOTP()) {
      let { token } = this.props.forgotuser;
      let { OTP, newPassword, confirmnewPassword } = this.state.fieldslogin;
      this.props.dispatch(
        userActions.forgotUpdatePassword(
          {
            token: token,
            newPassword,
            confirmnewPassword,
            otp: OTP,
          },
          this.props,
        ),
      );
    }
  };
  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    });
    this.hideErrorMessage();
  };

  handleValidationOTP = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Name
    if (!fieldslogin["OTP"]) {
      formIsValid = false;
      errorslogin["OTP"] = "Please enter OTP!";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  };

  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //Email
    if (!fieldslogin["email"] || fieldslogin["email"] === "") {
      formIsValid = false;
      errorslogin["email"] = "Cannot be empty";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  };

  onClickMenu = (url) => {
    this.props.history.push(url);
  };

  addToCart = (data) => {
    console.log("+++++++++++++++++++", data);
    let products = JSON.parse(localStorage.getItem("products"));
    console.log("localStorage____products::", products);
    // {
    //     JSON.stringify(products)   
    // }
    if (products && products.length > 0) {
      let currentPrductindex = products.findIndex((element) => (element.id === data.id));

      if (currentPrductindex >= 0) {

        products[currentPrductindex]['count'] += 1;
        let { product, amount } = products[currentPrductindex];

        console.log("products[product]:::", product.newPrice);

        products[currentPrductindex].subTotal = products[currentPrductindex].count * product.newPrice

        amount = products[currentPrductindex].subTotal;
        console.log("currentPrductindex::::::::::::  ", amount);
        // products[currentPrductindex].grandTotal = products.reduce + products[currentPrductindex].count * subTotal
        // console.log("grandTotalgrandTotalgrandTotalgrandTotalgrandTotal:::", grandTotal);
        // console.log("productsTotal:::", productsTotal);
        // localStorage.setItem("grandTotal", JSON.stringify(grandTotal))
        // localStorage.setItem("productsTotal", JSON.stringify(productsTotal))
        localStorage.setItem("products", JSON.stringify(products))
        // window.location.reload();
      } else {
        let product = {
          id: data.id,
          product: data,
          count: 1,
          subTotal: data.newPrice,
          grandtotal: data.newPrice
        }
        products.push(product)
        localStorage.setItem("products", JSON.stringify(products))
        // window.location.reload();
      }
    } else {
      let product = [{
        id: data.id,
        product: data,
        count: 1,
      }]
      localStorage.setItem("products", JSON.stringify(product))
      window.location.reload();
    }
    console.log("")
    console.log("productsproductsproducts ", products);
    localStorage.setItem("products", JSON.stringify(products))
    let totalAmount = products.reduce((a, b) => ({ subTotal: a.subTotal + b.subTotal }));
    console.log("totalAmounttotalAmount  ", totalAmount);
    this.setState({ products })
    this.setState({ totalAmount: totalAmount })

  }


  subtractToCart = (data) => {
    console.log("---------------");
    let products = JSON.parse(localStorage.getItem("products"));
    if (products && products.length > 0) {
      let currentPrductindex = products.findIndex((element) => (element.id == data.id));

      console.log("currentPrductindex msdddddddddddddddddd ", currentPrductindex);
      console.log("products[currentPrductindex]  ", products[currentPrductindex]);

      if (currentPrductindex >= 0 && products[currentPrductindex]['count'] > 1) {
        products[currentPrductindex]['count'] -= 1;
        let { product, productTotal } = products[currentPrductindex];
        products[currentPrductindex].subTotal = products[currentPrductindex].count * product.newPrice
        localStorage.setItem("products", JSON.stringify(products))
        window.location.reload();
      } else {
        let currentPrductindex = products.filter((element) => (element.id != data.id));
        localStorage.setItem("products", JSON.stringify(currentPrductindex))
        window.location.reload();
      }
    }

    console.log("")
    console.log("productsproductsproducts ", products);

    localStorage.setItem("products", JSON.stringify(products))

    let totalAmount = products.reduce((a, b) => ({ subTotal: a.subTotal + b.subTotal }));
    console.log("totalAmounttotalAmount  ", totalAmount);
    this.setState({ products })
    this.setState({ totalAmount })

  }

  removeFromCart = (data) => {
    let products = JSON.parse(localStorage.getItem("products"));
    if (products && products.length > 0) {
      let currentPrductindex = products.filter((element) => (element.id != data.id));
      localStorage.setItem("products", JSON.stringify(currentPrductindex))
      window.location.reload();
    }
  }

  // displayRazorpay = async (e) => {
  //   // alert("hello razorPay...");

  //   let header = new Headers({
  //     'Content-Type': 'application/json',
  //     "Authorization": authHeader().Authorization
  //   });

  //   let tempData = {
  //     amount: "10"
  //   }

  //   const requestOptions = {
  //     method: "POST",
  //     headers: header,
  //     body: JSON.stringify(tempData)
  //   }
  //   console.log("requestOptions  ", requestOptions);
  //   const data = await fetch("https://ecommerce.jamsara.com/razorpay", requestOptions).then((t) => t.json());

  //   const options = {
  //     key: "rzp_test_DauYslwjAowzOf",
  //     currency: data.currency,
  //     amount: data.amount,
  //     name: "Algems",
  //     description: "Money Transaction",
  //     image: "https://ecommerce.jamsara.com/dist/img/algems-logo.svg",
  //     order_id: data.id,
  //     // handler:  (response)=> {
  //     //   // alert("PAYMENT ID ::" + response.razorpay_payment_id);
  //     //   // alert("ORDER ID :: " + response.razorpay_order_id);

  //     //   this.setState({ depositIndexCreateModal: false , fieldsIndex: {},  errorsIndex: {},});
  //     // },
  //     handler: (response) => {
  //       // alert("PAYMENT ID ::" + response.razorpay_payment_id);
  //       // alert("ORDER ID :: " + response.razorpay_order_id);
  //       let temp = {
  //         "txType": "SEND_RECEIVED",
  //         "keyWord": "",
  //         "pageNo": this.state.pageNo,
  //         "size": this.state.size
  //       }

  //       this.setState({ depositIndexCreateModal: false });

  //     },
  //     prefill: {
  //       name: "Anirudh Jwala",
  //       email: "anirudh@gmail.com",
  //       contact: "9999999999",
  //     },
  //   };

  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // }

  displayRazorpay = async (e) => {

    let header = new Headers({
      'Content-Type': 'application/json',
      "Authorization": authHeader().Authorization
    });

    let tempData = {
      amount: e
      // amount: "10"
    }

    const requestOptions = {
      method: "POST",
      headers: header,
      body: JSON.stringify(tempData)
    }
    console.log("requestOptions  ", requestOptions);
    const data = await fetch("http://localhost:6081/razorpay", requestOptions).then((t) =>
      console.log('t________________________________t', t)

      // t.json()
    );

    const options = {
      key: "rzp_test_ukvYx9ObnFvVM1",
      currency: "INR",
      amount: 10000,
      name: "Algems",
      description: "Money Transaction",
      image: "https://ecommerce.jamsara.com/dist/img/algems-logo.svg",
      order_id: data.id,
      // handler:  (response)=> {
      //   // alert("PAYMENT ID ::" + response.razorpay_payment_id);
      //   // alert("ORDER ID :: " + response.razorpay_order_id);

      //   this.setState({ depositIndexCreateModal: false , fieldsIndex: {},  errorsIndex: {},});
      // },
      handler: (response) => {
        // alert("PAYMENT ID ::" + response.razorpay_payment_id);
        // alert("ORDER ID :: " + response.razorpay_order_id);
        let temp = {
          "txType": "SEND_RECEIVED",
          "keyWord": "",
          "pageNo": this.state.pageNo,
          "size": this.state.size
        }

        // this.setState({ depositIndexCreateModal: false });

      },
      prefill: {
        name: "Anirudh Jwala",
        email: "anirudh@gmail.com",
        contact: "9999999999",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }



  handleOpenCreateModal = () => {
    this.setState({ sliderCreateModal: true });
  }
  handleAppSeetingHideModal = () => {
    this.setState({ sliderCreateModal: false });
    this.setState({ sliderUpdateModal: false });
  }

  render() {
    let { home } = this.props;
    let { getArrivalsListId } = home;
    const truncate = (input) =>
      input?.length > 60 ? `${input.substring(0, 40)}...` : input;

    // console.log("asdfasdfsdf", this.state.totalAmount);
    console.log("getArrivalsListId____________________*******************", getArrivalsListId);

    let { user } = this.props;
    return (
      <>
        <div className="overflow-hidden">
          {/* Headdr */}
          <PublicHeader />
          <main className="bg-gray-50">
            {/* Product Results */}

            <section className="py-4  ">

              <div className="max-w-screen-2xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8 pb-5 sm:pb-20 lg:pb-24">

                <div className="flex flex-wrap justify-between lg:flex-row  cart-container">
                  {/* Cart Page => Cart Section */}
                  <div className="w-full sm:w-3/4  ">
                    <div className="mt-5">
                      {/* Cart Heading */}
                      <section className="relative z-20 items-center overflow-hidden dark:bg-gray-800">
                        <div className="relative px-6 mx-auto max-w-screen-2xl">
                          <div className="grid grid-cols-12 gap-10 p-6 bg-white rounded shadow lg:my-5 ring-1 ring-gray-200">

                            <div className="col-span-6 gap-5">
                              <div className="">
                                <Slider
                                  asNavFor={this.state.nav2}
                                  focusOnSelect={false}
                                  ref={(slider) => (this.slider1 = slider)}
                                  className={"product"}
                                >

                                  <div class="overflow-hidden rounded">
                                    <img alt="gallery" class="w-full h-full object-cover object-center" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} width="70" height="70" />
                                  </div>
                                </Slider>
                              </div>
                              <div className="">
                                <Slider
                                  asNavFor={this.state.nav1}
                                  ref={(slider) => (this.slider2 = slider)}
                                  slidesToShow={5}
                                  // vertical={true}
                                  className={"productThumb"}
                                  swipeToSlide={true}
                                  focusOnSelect={true}
                                >
                                  <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                                    <div className="overflow-hidden bg-gray-900 rounded">
                                      <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                                    </div>
                                  </div>
                                  <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                                    <div className="overflow-hidden bg-gray-900 rounded">
                                      <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                                    </div>
                                  </div>
                                  <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                                    <div className="overflow-hidden bg-gray-900 rounded">
                                      <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                                    </div>
                                  </div>
                                  <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                                    <div className="overflow-hidden bg-gray-900 rounded">
                                      <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                                    </div>
                                  </div>
                                  <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                                    <div className="overflow-hidden bg-gray-900 rounded">
                                      <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                                    </div>
                                  </div>
                                  <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                                    <div className="overflow-hidden bg-gray-900 rounded">
                                      <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                                    </div>
                                  </div>
                                  <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                                    <div className="overflow-hidden bg-gray-900 rounded">
                                      <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                                    </div>
                                  </div>
                                  <div class="slide-thumb cursor-pointer border border-gray-200 p-1 rounded">
                                    <div className="overflow-hidden bg-gray-900 rounded">
                                      <img alt="gallery" class="block object-cover object-center opacity-50 transition duration-300 ease-in-out hover:opacity-100" src={getArrivalsListId && getArrivalsListId.imageLink ? getArrivalsListId.imageLink : "Not fount"} />
                                    </div>
                                  </div>
                                </Slider>
                              </div>
                            </div>

                            <div className="col-span-6 ">

                              <div className="max-w-2xl mx-auto space-y-5">
                                {/* Product name */}
                                <h2 className="text-2xl font-bold leading-snug font-gray-900 font-headings line-clamp-3">
                                  {getArrivalsListId && getArrivalsListId.name ? getArrivalsListId.name : '-'}
                                  {/* ASTIR COLLEEN Vegan Leather Women/Girls */}
                                </h2>
                                {/* Vendor name */}
                                <p className="font-medium text-gray-500 ">
                                  {getArrivalsListId && getArrivalsListId.venderName ? getArrivalsListId.venderName : '-'}
                                  {/* MakerStuff India Private Limited */}
                                </p>
                                {/* Rating & Reviews */}
                                <div className="flex items-center py-2 space-x-3">
                                  <div className="flex items-center space-x-3 ">
                                    <div className="flex items-center space-x-1 ">
                                      <svg
                                        className="w-4 h-4 fill-current text-primary"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                      >
                                        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                                      </svg>
                                      <svg
                                        className="w-4 h-4 fill-current text-primary"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                      >
                                        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                                      </svg>
                                      <svg
                                        className="w-4 h-4 fill-current text-primary"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                      >
                                        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                                      </svg>
                                      <svg
                                        className="w-4 h-4 fill-current text-primary"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                      >
                                        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                                      </svg>
                                      <svg
                                        className="w-4 h-4 text-primary fill-current"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                      >
                                        <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
                                      </svg>
                                    </div>
                                    <span className="font-normal">{getArrivalsListId && getArrivalsListId.rating ? getArrivalsListId.rating : '-'} Reviews</span>
                                  </div>

                                  <div className="flex items-center space-x-2 text-green-500">
                                    <svg
                                      width="14"
                                      height="16"
                                      viewBox="0 0 14 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M12.25 2.25H9.115C8.8 1.38 7.975 0.75 7 0.75C6.025 0.75 5.2 1.38 4.885 2.25H1.75C0.925 2.25 0.25 2.925 0.25 3.75V14.25C0.25 15.075 0.925 15.75 1.75 15.75H12.25C13.075 15.75 13.75 15.075 13.75 14.25V3.75C13.75 2.925 13.075 2.25 12.25 2.25ZM7 2.25C7.4125 2.25 7.75 2.5875 7.75 3C7.75 3.4125 7.4125 3.75 7 3.75C6.5875 3.75 6.25 3.4125 6.25 3C6.25 2.5875 6.5875 2.25 7 2.25ZM5.5 12.75L2.5 9.74998L3.5575 8.69248L5.5 10.6275L10.4425 5.68498L11.5 6.74998L5.5 12.75Z"
                                        fill="CurrentColor"
                                      />
                                    </svg>
                                    <span className="font-normal">{getArrivalsListId && getArrivalsListId.order ? getArrivalsListId.order : '-'}</span>
                                  </div>
                                </div>
                                {/* Price & Availability*/}
                                <div className="flex items-center">
                                  {/* Price */}
                                  <p className="flex items-end flex-1 space-x-2 text-3xl font-medium text-gray-900 ">
                                    <strong className="font-bold">₹{getArrivalsListId && getArrivalsListId.newPrice ? getArrivalsListId.newPrice : '-'}</strong>
                                    {/* <span className="font-light text-gray-500 line-through">{getArrivalsListId && getArrivalsListId.oldPrice ? getArrivalsListId.oldPrice : '-'}</span> */}
                                    {/* <span className="font-light text-gray-400">
                                      / gram
                                    </span> */}
                                  </p>
                                  {/* Availability */}
                                  <p className="flex items-end space-x-2 text-lg font-normal text-right text-green-500 ">
                                    In Stock
                                  </p>
                                </div>
                                {/* Product Short Intro */}
                                <p className="font-light leading-loose text-gray-500 ">
                                  {getArrivalsListId && getArrivalsListId.desc ? getArrivalsListId.desc : '-'}
                                  {/* BP1008FZIP */}
                                </p>

                                {/* Product Few Featured */}
                                <div>
                                  <dl>
                                    <div class="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                      <dt class="text-sm font-medium text-gray-500">{getArrivalsListId && getArrivalsListId.model ? getArrivalsListId.model : '-'}</dt>
                                      <dd class="mt-1 text-sm font-semibold text-gray-900 sm:mt-0 sm:col-span-3">
                                        {getArrivalsListId && getArrivalsListId.model ? getArrivalsListId.model : '-'}
                                        {/* ASTIR COLLEEN */}
                                      </dd>
                                    </div>
                                    <div class="bg-white px-4 py-3 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                      <dt class="text-sm font-medium text-gray-500">Color</dt>
                                      <dd class="mt-1 text-sm font-bold text-gray-900 sm:mt-0 sm:col-span-3 capitalize">
                                        {getArrivalsListId && getArrivalsListId.color ? getArrivalsListId.color : '-'}
                                        Yellow
                                      </dd>
                                    </div>
                                  </dl>
                                </div>
                                {/* Quoates */}
                                <div className="relative flex items-center px-6 py-3 space-x-3 bg-white rounded-lg shadow-sm bg-pink-50 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                  <div className="flex-shrink-0 text-primary">
                                    <svg className="w-14" viewBox="0 0 37 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M5.48962 4.65941C4.70031 5.50253 4.0742 6.50347 3.64703 7.60507C3.21986 8.70667 3 9.88736 3 11.0797C3 12.2721 3.21986 13.4528 3.64703 14.5544C4.0742 15.656 4.70031 16.6569 5.48962 17.5L20 33L34.5104 17.5C36.1045 15.7973 37 13.4878 37 11.0797C37 8.67164 36.1045 6.36218 34.5104 4.65941C32.9163 2.95663 30.7543 2.00002 28.5 2.00002C26.2456 2.00002 24.0836 2.95663 22.4896 4.65941L20 7.31873L17.5105 4.65941C16.7212 3.81627 15.7841 3.14746 14.7529 2.69116C13.7216 2.23486 12.6163 2 11.5 2C10.3838 2 9.27849 2.23486 8.24722 2.69116C7.21595 3.14746 6.27892 3.81627 5.48962 4.65941Z" fill="currentColor" />
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.647031 5.60507C1.0742 4.50347 1.70031 3.50253 2.48962 2.65941C3.27892 1.81627 4.21595 1.14746 5.24722 0.691157C6.27849 0.234856 7.3838 0 8.50004 0C9.61628 0 10.7216 0.234856 11.7529 0.691157C12.4012 0.978018 13.0123 1.34887 13.5728 1.79422C13.5301 1.77438 13.4872 1.75489 13.4442 1.73575C12.3523 1.25001 11.1819 1 10 1C8.81814 1 7.64781 1.25001 6.55588 1.73575C5.46395 2.22149 4.47179 2.93345 3.63607 3.83098C2.80033 4.7285 2.13739 5.79401 1.68509 6.96668C1.23279 8.13936 1 9.39622 1 10.6655C1 11.9348 1.23279 13.1917 1.68509 14.3643C1.72754 14.4744 1.77184 14.5835 1.81796 14.6916C1.33909 14.041 0.944743 13.3221 0.647031 12.5544C0.219861 11.4528 0 10.2721 0 9.07972C0 7.88736 0.219861 6.70667 0.647031 5.60507ZM29.6703 1.1679C28.4083 0.408917 26.9738 2.31025e-05 25.5 2.30907e-05C23.2456 2.30728e-05 21.0836 0.956631 19.4896 2.65941L17.3757 4.91744L19 6.66188L21.636 3.83098C23.3238 2.01835 25.613 1.00002 28 1.00002C28.5644 1.00002 29.1234 1.05697 29.6703 1.1679Z" fill="currentColor" />
                                    </svg>
                                  </div>
                                  <div className="flex-1 min-w-0">
                                    <div className="focus:outline-none">
                                      <span className="absolute inset-0" aria-hidden="true" />
                                      <p className="text-lg font-medium text-primary">
                                        Shop on Algems and be part of a community doing good.
                                      </p>
                                      <p className="text-sm italic text-primary line-clamp-2">
                                        We are not just a destination for creative goods, we’re a community pushing for positive change for small businesses, people and the planet.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* Cuntinue Shopping button */}
                      <div className="flex justify-start items-end pt-5 space-x-5" onClick={() => this.onClickMenu("/home")}>
                        <button className="px-10 text-center py-3 text-white uppercase text-base rounded hover:bg-primary-500 bg-primary block transition ease-in-out duration-150">Continue shopping</button>
                      </div>
                    </div>
                  </div>
                  {/* Cart Page => Coupon & Cart Section */}
                  <div className="w-full sm:w-1/4 mx-auto mt-10 sm:mt-16  lg:mx-0 lg:mt-0">
                    {/* Cart Total For Desktop */}
                    <div className="hidden sm:block pt-10">
                      <Sticky offsetTop={20} offsetBottom={0} containerSelectorFocus=".cart-container">
                        <div className=" w-80 max-w-full ml-auto mr-0 flex flex-col space-y-5">
                          <div className="bg-white rounded-md shadow p-5 flex flex-col">
                            <form action="" method="POST">
                              <label className="block uppercase leading-none pb-2">Have coupon?</label>
                              <div class="flex items-center w-full relative">
                                <input type="coupon" name="code" id="coupon" placeholder="Apply coupon" class="w-full  outline-none appearance-none focus:outline-none active:outline-none bg-gray-50 shadow-inner border border-gray-300 rounded py-2 px-3 focus:text-primary focus:placeholder-gray-400 focus:ring-1 focus:ring-primary focus:border-primary" />
                                <button type="submit" class="px-3 flex items-center bg-primary hover:bg-primary-500 focus:outline-none cursor-pointer text-white rounded-r  absolute inset-y-0 right-0">

                                  <span class="font-medium">Apply</span>
                                </button>
                              </div>
                            </form>
                          </div>


                          <div className="p-5 mb-5 bg-white rounded-md shadow p-5 flex flex-col">
                            <h4 className="text-xl font-bold text-center sm:text-left font-sans">Cart Totals</h4>

                            <div className="pt-4 mb-5 space-y-2">
                              {/* {
                                JSON.parse(localStorage.getItem("products")) && JSON.parse(localStorage.getItem("products")).length > 0 ? JSON.parse(localStorage.getItem("products")).map((element, index) => (
                                  <> */}
                              <div className="flex justify-between">
                                <span className="text-sm text-gray-500">Total price:</span>
                                <span className="text-sm text-gray-500">₹{getArrivalsListId ? getArrivalsListId.oldPrice : "-"}</span>
                              </div>
                              <div className="flex justify-between">
                                <span className="text-sm text-red-500">Discount:</span>
                                <span className="text-sm text-red-500">-₹{getArrivalsListId ? (getArrivalsListId.oldPrice - getArrivalsListId.newPrice) : "-"}</span>
                              </div>
                              <div className="flex justify-between">
                                <span className="text-base font-normal text-black">Total</span>
                                <span className="text-base font-bold black">₹{getArrivalsListId ? getArrivalsListId.newPrice : "-"}</span>
                              </div>

                              {/* </>

                                )) : null
                              } */}
                            </div>

                            {
                              user && user.token ?

                                <button className="px-3 text-center py-3 text-white uppercase text-base rounded hover:bg-primary-500 bg-primary block transition ease-in-out duration-150"
                                  //  onClick={() => this.onClickMenu("/checkout")}
                                  onClick={() => this.displayRazorpay()}

                                >Proceed to checkout</button>

                                : <button className="mt-4 px-3 text-center py-3 text-white uppercase text-base rounded hover:bg-primary-500 bg-primary block transition ease-in-out duration-150"
                                  //  onClick={() => this.onClickMenu("/checkout")}
                                  onClick={() => this.handleOpenCreateModal()}

                                >Login</button>
                            }


                            <div className="pt-4 flex space-x-3 justify-between">
                              <img src="dist/img/card-1.svg" className="w-14" alt="" />
                              <img src="dist/img/card-2.svg" className="w-14" alt="" />
                              <img src="dist/img/card-3.svg" className="w-14" alt="" />
                              <img src="dist/img/card-4.svg" className="w-14" alt="" />
                            </div>
                          </div>
                        </div>
                      </Sticky>
                    </div>
                  </div>

                </div>

              </div>

            </section>

          </main>

          {/* Footer */}
          <PublicFooter />
        </div>

        <CreateSliderModal
          sliderCreateModal={this.state.sliderCreateModal}
          fieldslogin={this.state.fieldslogin}
          errorslogin={this.state.errorslogin}
          inputChange={this.inputChange}
          loginSubmit={this.loginSubmit}
          handleFile={this.handleFile}
          handleAppSeetingHideModal={this.handleAppSeetingHideModal}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn, otpSent, forgotuser, user } = state.authentication;
  const { users, home } = state;
  return {
    loggingIn,
    user,
    forgotuser,
    otpSent,
    users,
    home
  };
}
export default connect(mapStateToProps)(Singleproductby);
