import React from "react";
import { Grid} from "@material-ui/core";
import logo from "./logo.svg";
export default function Error() {
  var classes = {}

  return (
    <Grid container >
      <div className={classes.logotype}>
        <img className={classes.logotypeIcon} src={logo} alt="logo" />
          Page not found.
      </div>
      </Grid>
  );
}
