export const CONST = {
  // BACKEND_URL: 'http://139.162.218.18:6081/api/v1/client',
  BACKEND_URL: 'https://ecommerce.jamsara.com/api/v1/client',
  // BACKEND_URL: 'http://localhost:6081/api/v1/client',
  //BACKEND_URL: 'http://localhost:5000/api/v1',
  // BACKEND_URL: 'https://wallet.daikicoin.org/api/v1',
  // TXID_LINK: 'http://explore.daikicoin.org/tx/',
};


