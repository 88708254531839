import React, { Component } from "react";
import { connect } from 'react-redux';
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import PublicFooter from "../../components/PublicFooter/PublicFooter";

// import { userActions } from '../../_actions';

class Thankyou extends Component {
  constructor(props) {
    super(props);
    // this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.loginValidateOtp = this.loginValidateOtp.bind(this);
    this.state = {
      email: '',
      password: '',
      otp: ''
    }
  }
  onClickMenu = (url) => {
    this.props.history.push(url)
  }
  // componentDidMount() {
  // }
  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }
  loginSubmit(e) {
    e.preventDefault();
    // let { email, password } = this.state;
    //this.props.dispatch(userActions.login({ email: email, password }, this.props));
  }
  loginValidateOtp(e) {
    e.preventDefault();
    // let { email, password, otp } = this.state;
    //this.props.dispatch(userActions.loginValidateOtp({ email: email, password, otp }, this.props));
  }
  render() {
    let { user, otpSent } = this.props;
    console.log("user  ", user);
    console.log("otpSent  ", otpSent);

    return (
      <>

        <PublicHeader/>

        {/* thank you start */}
        <div class="flex items-center justify-center h-screen">
      <div class="p-4 rounded shadow-lg ring ring-indigo-600/50">
        <div class="flex text-primary flex-col items-center space-y-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="text-green-600 w-28 h-28" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h1 class="text-4xl font-bold">Thank You !</h1>
          <p className="font-bold">Thank you for shopping with Algems! May have happiness with beautiful Gemstones.</p>
          <a 
            onClick={() => this.onClickMenu("/home")}
            class="inline-flex cursor-pointer items-center px-4 py-2 text-white bg-primary border border-primary-500 rounded-full hover:bg-primary-500 focus:outline-none focus:ring">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 mr-2" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
            </svg>
            <span class="text-sm font-medium">
              Home
            </span>
          </a>
        </div>
      </div>
    </div>

        {/* footer */}
        <PublicFooter />
      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSent } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    otpSent,
    user,
    users
  };
}
export default connect(mapStateToProps)(Thankyou);
