import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import PublicFooter from "../../components/PublicFooter/PublicFooter";
class Contactus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      "name": "",
      "email": "",
      "mobile": "",
      "message": ""
    }
  }
  handleChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  }
  onClickMenu = (url) => {
    this.props.history.push(url)
  }
  handleSubmit = () => {
    let { name, email, mobile, message } = this.state;
    let reqData = { name, email, mobile, message };
    console.log("reqData  ", reqData);
    this.props.dispatch(userActions.createNotification(reqData, this.props));
  }
  componentDidMount() {
    // this.props.dispatch(userActions.statics());
  }

  render() {
    //let classes = {};
    let { authentication } = this.props;
    //let { statics} = users;
    return (
      <>

        <>

          <PublicHeader />
          {/* hero section */}
          <div className="bg-cover bg-no-repeat bg-right bg_contactus" style={{ backgroundColor: "#ea67c0" }}>
            <div className="md:h-[calc(100vh_-_14rem)] bg-red-400 md:flex items-center">
              {/* wave */}

              <div className="2xl:w-4/6 xl:w-5/6 w-11/12  mx-auto  md:flex md:items-center md:space-y-0 space-y-10 py-10">

                {/* text-section */}
                <div className="space-y-6 w-full md:text-left text-center">
                  <h1 data-aos="zoom-in" data-aos-delay="50"
                    data-aos-duration="1000" className="text-white xl:text-4xl text-2xl font-bold xl:leading-10">Contact us information</h1>
                  <p className="lg:text-base text-sm text-white mb-4">If you have any questions or queries a member of staff will always be happy to help.
                    Feel free to contact us and we will be sure to get back to you as soon as possible.
                  </p>
                  <div className="flex md:justify-start justify-center space-x-4">
                    <a data-aos="flip-right" data-aos-delay="50"
                      data-aos-duration="1500" href="#down" class="group [transform:translateZ(0)] px-6 py-3 rounded-full overflow-hidden  relative before:absolute cursor-pointer before:bg-white   before:top-1/2 before:left-1/2 before:h-8 before:w-8 before:-translate-y-1/2 before:-translate-x-1/2 before:rounded-full before:scale-[0] before:opacity-0 hover:before:scale-[6] hover:before:opacity-100 before:transition before:ease-in-out before:duration-500 text-white border"><span class="relative z-0  group-hover:text-blue-500 transition ease-in-out duration-500 ">Request
                        a demo</span></a>
                  </div>

                </div>

                {/* img section */}
                {/* <div data-aos="fade-left" data-aos-delay="50"
                  data-aos-duration="1500" className="w-full flex md:justify-end justify-center">
                  <img src="images/illuslator/Contactus.svg" alt="contact Us" title='contact Us' className="w-5/6 " />
                </div> */}
              </div>
            </div>
          </div>
          {/* get in touch */}
          <div className="bg-gray-100 py-20 p-4">
            <section className="2xl:w-4/6 xl:w-5/6 w-11/12  mx-auto">
              {/* text-section */}
              <div className="text-center space-y-4">


                <h1 data-aos="zoom-in" data-aos-delay="50"
                  data-aos-duration="1500" className="text-2xl font-bold capitalize text-black">
                  get in touch</h1>
                <div className="flex justify-center">
                  <div className="h-1 w-20 rounded-full bg-pink-400 "></div>
                </div>
                <p>Interested in learning further about our capabilities, or just want to get in touch? Fill out the form below to connect with a Comfygen team members today!</p>
                <div id="down" className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-10 pt-8 lg:px-8 md:px-4">
                  <div className="grid lg:grid-cols-1 md:grid-cols-2 grid-cols-1 gap-10 text-left  w-full ">
                    {/* card 1 */}
                    <div data-aos="flip-right" data-aos-delay="50"
                      data-aos-duration="1500"
                      className="bg-white rounded-xl p-6 drop-shadow-2xl  space-x-4   flex itmes-center cursor-pointer w-full">
                      <div className="flex items-start">
                        <div
                          className="bg-gradient-to-r from-blue-400 to-blue-600 rounded-full p-1 w-16 h-16 flex items-center justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                              d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <h1 className="text-lg font-semibold capitalize">phone number</h1>
                        <p className=""> +91 9587867258</p>
                      </div>
                    </div>

                    {/* card 1 */}
                    <div data-aos="flip-right" data-aos-delay="50"
                      data-aos-duration="1500"
                      className="bg-white rounded-xl p-6 drop-shadow-2xl  space-x-4   flex itmes-center cursor-pointer">
                      <div className="flex items-start">
                        <div
                          className="bg-gradient-to-r from-blue-400 to-blue-600 rounded-full p-1 w-16 h-16 flex items-center justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path
                              d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <h1 className="text-lg font-semibold capitalize">Email Address</h1>
                        <p className=""> sales@comfygen.com</p>
                      </div>
                    </div>

                    {/* card 1 */}
                    <div data-aos="flip-right" data-aos-delay="50"
                      data-aos-duration="1500"
                      className="bg-white rounded-xl p-6 drop-shadow-2xl  space-x-4   flex itmes-center cursor-pointer">
                      <div className="flex items-start">
                        <div
                          className="bg-gradient-to-r from-blue-400 to-blue-600 rounded-full p-1 w-16 h-16 flex items-center justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                              d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z"
                              clip-rule="evenodd" />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <h1 className="text-lg font-semibold capitalize">Landmark</h1>
                        <p className="">Niwaru Road</p>
                      </div>
                    </div>

                    {/* card 1 */}
                    <div data-aos="flip-right" data-aos-delay="50"
                      data-aos-duration="1500"
                      className="bg-white rounded-xl p-6 drop-shadow-2xl  space-x-4   flex itmes-center cursor-pointer">
                      <div className="flex items-start">
                        <div
                          className="bg-gradient-to-r from-blue-400 to-blue-600 rounded-full p-1 w-16 h-16 flex items-center justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                              d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                              clip-rule="evenodd" />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <h1 className="text-lg font-semibold capitalize">Location</h1>
                        <p className="">70, A Narayanpuri Jhotwara, Jaipur, Rajasthan, INDIA, 302012</p>
                      </div>
                    </div>

                  </div>

                  {/* card 1 */}
                  <div className="bg-white rounded-xl p-6 drop-shadow-2xl space-y-4 text-left lg:col-span-2">

                    <h1 className="text-lg font-semibold">Send a Message</h1>
                    <p className="">For all enquiries, please contact with us using the form below we'll be glad to help you.</p>
                    <div className="grid grid-cols-2 gap-6">

                      <div>
                        <input type="text" name="name" placeholder="First name*" className="focus:outline-none border p-2 w-full focus:ring-1 rounded font-light focus:font-normal" />
                      </div>

                      <div>
                        <input type="text" name="email" placeholder="Email*" className="focus:outline-none border p-2 w-full focus:ring-1 rounded font-light focus:font-normal" />
                      </div>

                      <div>
                        <input type="number" name="mobNo"
                          placeholder="Phone number *"
                          className="focus:outline-none border p-2 w-full focus:ring-1 rounded font-light focus:font-normal" />
                      </div>

                      <div>
                        <input type="text" name="subject"
                          placeholder="Subject "
                          className="focus:outline-none border p-2 w-full focus:ring-1 rounded font-light focus:font-normal" />
                      </div>

                      <div className="col-span-2">
                        <textarea type="text" name="msg" cols="20" rows="8" placeholder="Message " className="focus:outline-none focus:ring-1 w-full border p-2 rounded resize-none font-light focus:font-normal"></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center pt-4">
                    <button type="button" onClick={this.createContactUs}
                      className="uppercase mt-4 px-8 py-3 rounded-full text-sm text-white font-medium bg-gradient-to-b from-pink-400 to-purple-500 whitespace-nowrap">Send Message</button>
                  </div>
                </div>

              </div >

            </section>

          </div >


          {/* find us on google*/}
          < div className="bg-white md:py-20 mb-10" >
            <section className="2xl:w-4/6 xl:w-5/6 w-11/12 mx-auto">
              <div className="text-center space-y-4">
                <h1 data-aos="zoom-in" data-aos-delay="50"
                  data-aos-duration="1500"
                  className="text-2xl font-bold capitalize text-black">Find us on google map</h1>
                {/* <div className="flex justify-center">
                  <div className="h-1 w-20 rounded-full bg-pink-400 "></div>
                </div> */}
                  <div data-aos="fade-down" data-aos-delay="50"
                    data-aos-duration="1500" className=" pt-8 w-full">
                    <iframe title='googlemap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d747.6231852009806!2d75.73434582052502!3d26.95691455946126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db3134aeb1007%3A0x6b2253a37cf5c82e!2sCOMFYGEN%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1652939859741!5m2!1sen!2sin" className="w-full h-96"></iframe>
                </div>
              </div>
            </section>
          </div >

          <PublicFooter />
        </>


      </>
    );
  }
}
function mapStateToProps(state) {
  const { users, authentication } = state;
  return {
    users,
    authentication
  };
}
export default connect(mapStateToProps)(Contactus);
