import React, { Component } from "react";
import { connect } from 'react-redux';
import Sticky from "wil-react-sticky";
import { userActions } from "../../_actions";
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import PublicFooter from "../../components/PublicFooter/PublicFooter";

// import { userActions } from '../../_actions';

class Payment extends Component {
  constructor(props) {
    super(props);
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.loginValidateOtp = this.loginValidateOtp.bind(this);
    this.state = {
      email: '',
      password: '',
      otp: ''
    }
  }
  onClickMenu = (url) => {
    this.props.history.push(url)
  }
  // componentDidMount() {
  // }


  removeFromCart = (data) => {

    let products = JSON.parse(localStorage.getItem("products"));
    if (products && products.length > 0) {
      let currentPrductindex = products.filter((element) => (element.id !== data.id));
      localStorage.setItem("products", JSON.stringify(currentPrductindex))
      window.location.reload();
    }
  }

  addToWishList = (data) => {
    console.log("+++++++++++++++++++", data);
    let products = JSON.parse(localStorage.getItem("products"));
    console.log("localStorage____products::", products);
    // {
    //     JSON.stringify(products)   
    // }
    if (products && products.length > 0) {
      let currentPrductindex = products.findIndex((element) => (element.id === data.id));

      if (currentPrductindex >= 0) {

        products[currentPrductindex]['count'] += 1;
        let { product, amount } = products[currentPrductindex];

        console.log("products[product]:::", product.newPrice);

        products[currentPrductindex].subTotal = products[currentPrductindex].count * product.newPrice

        amount = products[currentPrductindex].subTotal;
        console.log("currentPrductindex::::::::::::  ", amount);

        localStorage.setItem("products", JSON.stringify(products))
        window.location.reload();


      } else {
        let product = {
          id: data.id,
          product: data,
          count: 1,
          subTotal: data.newPrice,
          grandtotal: data.newPrice
        }
        products.push(product)
        localStorage.setItem("products", JSON.stringify(products))
        window.location.reload();
      }
    } else {
      let product = [{
        id: data.id,
        product: data,
        count: 1,
      }]
      localStorage.setItem("products", JSON.stringify(product))
      window.location.reload();
    }
    console.log("")
    console.log("productsproductsproducts ", products);

    localStorage.setItem("products", JSON.stringify(products))

    let totalAmount = products.reduce((a, b) => ({ subTotal: a.subTotal + b.subTotal }));
    console.log("totalAmounttotalAmount  ", totalAmount);
    this.setState({ products })
    this.setState({ totalAmount: totalAmount })

  }


  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }
  loginSubmit(e) {
    e.preventDefault();
    // let { email, password } = this.state;
    //this.props.dispatch(userActions.login({ email: email, password }, this.props));
  }
  loginValidateOtp(e) {
    e.preventDefault();
    // let { email, password, otp } = this.state;
    //this.props.dispatch(userActions.loginValidateOtp({ email: email, password, otp }, this.props));
  }
  render() {
    let { user, otpSent } = this.props;
    console.log("user  ", user);
    console.log("otpSent  ", otpSent);

    return (
      <>
        <div className="overflow-hidden">
          {/* Headdr */}
          <PublicHeader />

          <main className="bg-gray-50">
            <section className="py-4  ">

              <div className="max-w-screen-2xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8 pb-5 sm:pb-20 lg:pb-24">


                <div className="flex flex-wrap justify-between lg:flex-row  cart-container ">
                  <div className="w-full border border-gray-900 h-full sm:w-3/4 p-5 rounded-md">
                    <div className="mt-5 ">
                      {/* Cart Heading */}
                      <div className="mb-4 text-left heading ">
                        <h2 className="pb-2  text-xl font-semibold  text-gray-900 text-left sm:text-4xl">Select a payment method</h2>

                      </div>

                      <p className="text-sm pb-3 text-left text-gray-400 font-medium mt-2 border-b mx-5 border-gray-700">Another payment method</p>
                      <div className="flex ">
                        <div>
                          <input type="radio" />
                        </div>
                        <div>
                          <h1>Add Debit/Credit/ATM Card</h1>
                        </div>
                      </div>



                      {/* Cart items wrapper */}
                      {/* <div className="md:pt-4">
                        Cart Items header
                        {
                          localStorage.getItem("products") && JSON.parse(localStorage.getItem("products")) && JSON.parse(localStorage.getItem("products")).map((element, index) => (
                            <>
                              <div className="hidden md:flex justify-between border-b border-black border-opacity-20 mr-10">
                                <div className="w-1/2 pb-2 lg:w-3/5 xl:w-1/2 ">
                                  <span className="text-sm uppercase font-normal text-gray-600 ">Product</span>
                                </div>

                                <div className="w-1/5 pb-2 text-left ">
                                  <span className="text-sm uppercase font-normal text-gray-600 ">Price</span>
                                </div>

                                <div className="w-1/4 pb-2 text-center ">
                                  <span className="text-sm uppercase font-normal text-gray-600 ">Quantity</span>
                                </div>

                                <div className="w-1/5 pb-2 text-right  ">
                                  <span className="text-sm uppercase font-normal text-gray-600 ">Total</span>
                                </div>
                                <div className="flex items-center "> <button className="w-6 h-6 opacity-0 visibility-hidden flex justify-center items-center rounded-full bg-gray-100"><svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" fill="currentColor" className="w-4"><path d="M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z"></path></svg></button></div>

                              </div>

                              <div className="max-h-[500px] overflow-auto pr-10">
                                <div className="flex flex-wrap lg:flex-nowrap justify-between items-center relative py-4 lg:py-8 border-b border-black border-opacity-20">
                                  <div className="md:w-1/2 w-full flex items-center ">
                                    <div className="w-20 pr-0 mx-0">
                                      <div className="flex items-center justify-center h-20 rounded bg-gray-100">
                                        <div className="w-full  aspect-w-1 aspect-h-1">
                                          <img src={element && element.product && element.product.imageLink} alt="productImage" className="object-cover" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="">
                                      <span className="mt-2 ml-4 text-sm lg:text-base font-bold text-black block ">{element && element.product && element.product.name}</span>
                                      <span className="ml-4 text-sm  font-normal text-gray-400 block ">Rs.2,500</span>
                                    </div>
                                  </div>

                                  <div className="w-1/5  text-left  flex flex-wrap items-center mt-3">
                                    <span className="block w-full text-sm md:hidden mb-1">Price</span>
                                    <span className="text-base uppercase font-medium text-black">{element && element.product && element.product.newPrice}</span>
                                  </div>

                                  <div className="w-1/4  text-left  flex flex-wrap items-center mt-3">
                                    <span className="block w-full text-sm md:hidden mb-1 text-center">Quantity</span>
                                    <div className="container flex items-center w-28 justify-center bg-gray-100 rounded-full py-2 mx-auto">
                                      <button className="mx-auto cart-qty-minus" type="button" value="+"  ><svg xmlns="http://www.w3.org/2000/svg"
                                        id="Outline" viewBox="0 0 24 24" className="w-4">
                                        <rect x="6" y="11" width="12" height="2" rx="1"></rect>
                                      </svg></button>
                                      <input type="text" name="qty" className="p-0 text-center w-5 bg-gray-100 text-sm  qty " maxlength="12"
                                        value="1" min="1" />
                                      <button className="mx-auto cart-qty-minus" type="button" value="+" > <svg xmlns="http://www.w3.org/2000/svg"
                                        id="Outline" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                        <path
                                          d="M17,11H13V7a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v4H7a1,1,0,0,0-1,1H6a1,1,0,0,0,1,1h4v4a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13h4a1,1,0,0,0,1-1h0A1,1,0,0,0,17,11Z">
                                        </path>
                                      </svg></button>
                                    </div>
                                  </div>

                                  <div className="w-1/5  text-right lg:pr-10  mt-3">
                                    <span className="block w-full text-sm md:hidden mb-1">Total</span>
                                    <span className="text-base uppercase font-medium text-black  ml-auto">$240</span>
                                  </div>

                                  <div className="flex items-center ">
                                    <button
                                    onClick={() => this.removeFromCart(element)}
                                      className="hover:text-red-600 text-gray-400 cursor-pointer w-8 h-8 rounded bg-white flex justify-center items-center buzz-out transition hover:bg-red-50">
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" id="Outline" viewBox="0 0 24 24"
                                        className="transition w-4">
                                        <path
                                          d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z">
                                        </path>
                                        <path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z"></path>
                                        <path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"></path>
                                      </svg>
                                    </button>
                                  </div>


                                  <div className="flex items-center ">
                                    <button
                                      className="hover:text-red-600 text-gray-400 cursor-pointer w-8 h-8 rounded bg-white flex justify-center items-center buzz-out transition hover:bg-red-50">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))
                        }


                      </div> */}

                      {/* Cuntinue Shopping button */}
                      <div className="flex justify-start items-end pt-5 space-x-5" onClick={() => this.onClickMenu("/products")}>
                        <button className="px-10 text-center py-3 text-white uppercase text-base rounded hover:bg-primary-500 bg-primary block transition ease-in-out duration-150">Continue shopping</button> </div>


                    </div>



                  </div>



                  {/* Cart Page => Coupon & Cart Section */}
                  <div className="w-full sm:w-1/4 mx-auto mt-10 sm:mt-16  lg:mx-0 lg:mt-0">
                    {/* Cart Total For Desktop */}
                    <div className="hidden sm:block pt-10">
                      <Sticky offsetTop={20} offsetBottom={0} containerSelectorFocus=".cart-container">
                        <div className=" w-80 max-w-full ml-auto mr-0 flex flex-col space-y-5">
                          <div className="bg-white rounded-md shadow p-5 flex flex-col">
                            <form action="" method="POST">
                              <label className="block uppercase leading-none pb-2">Have coupon?</label>
                              <div class="flex items-center w-full relative">
                                <input type="coupon" name="code" id="coupon" placeholder="Apply coupon" class="w-full  outline-none appearance-none focus:outline-none active:outline-none bg-gray-50 shadow-inner border border-gray-300 rounded py-2 px-3 focus:text-primary focus:placeholder-gray-400 focus:ring-1 focus:ring-primary focus:border-primary" />
                                <button type="submit" class="px-3 flex items-center bg-primary hover:bg-primary-500 focus:outline-none cursor-pointer text-white rounded-r z-20 absolute inset-y-0 right-0">

                                  <span class="font-medium">Apply</span>
                                </button>
                              </div>
                            </form>
                          </div>


                          <div className="p-5 mb-5 bg-white rounded-md shadow p-5 flex flex-col">
                            <h4 className="text-xl font-bold text-center sm:text-left font-sans">Cart Totals</h4>

                            <div className="pt-4 mb-5 space-y-2">

                              <div className="flex justify-between">
                                <span className="text-sm text-gray-500">Total price:</span>
                                <span className="text-sm text-gray-500">$236</span>
                              </div>
                              <div className="flex justify-between">
                                <span className="text-sm text-red-500">Discount:</span>
                                <span className="text-sm text-red-500">-$36</span>
                              </div>
                              <div className="flex justify-between">
                                <span className="text-base font-normal text-black">Total</span>
                                <span className="text-base font-bold black">$200</span>
                              </div>
                            </div>
                            <button className="px-3 text-center py-3 text-white uppercase text-base rounded hover:bg-primary-500 bg-primary block transition ease-in-out duration-150">Proceed to checkout</button>

                            <div className="pt-4 flex space-x-3 justify-between">
                              <img src="dist/img/card-1.svg" className="w-14" alt="" />
                              <img src="dist/img/card-2.svg" className="w-14" alt="" />
                              <img src="dist/img/card-3.svg" className="w-14" alt="" />
                              <img src="dist/img/card-4.svg" className="w-14" alt="" />
                            </div>
                          </div>
                        </div>
                      </Sticky>
                    </div>
                    {/* Cart Total For Mobile */}
                    <div className="p-5 mb-5 bg-primary bg-opacity-10 sm:w-80 max-w-full ml-auto mr-0 sm:hidden">
                      <h4 className="pb-3 text-2xl text-center sm:text-left">Cart Totals</h4>

                      <div className="hidden">
                        <p className="pb-2 text-gray-500">Add Coupon</p>
                        <div className="flex justify-start ">
                          <label for="discount_code" className="relative block w-0 h-0 overflow-hidden">Discount Code</label>
                          <div className="relative w-full">
                            <input type="text" placeholder="Discount code" className="px-3 py-2 pr-10 w-full hover:border-primary border text-primary border-black border-opacity-10 focus:outline-none text-sm font-base  " />
                            <button className="px-3 py-2 bg-primary text-white uppercase text-sm font-base border-primary hover:border-secondary border  hover:bg-secondary absolute right-0 top-0" aria-label="Apply button">Apply</button></div>
                        </div>
                      </div>
                      <div className="pt-4 mb-5">
                        <p className="pb-2 text-gray-500">Cart Total</p>
                        <div className="flex justify-between pb-1 border-b border-grey-darker">
                          <span className="text-sm text-gray-500">Subtotal</span>
                          <span className="text-sm text-gray-500">$236</span>
                        </div>
                        <div className="flex justify-between pt-2 pb-1 border-b border-grey-darker">
                          <span className="text-sm text-gray-500">Coupon applied</span>
                          <span className="text-sm text-gray-500">-$36</span>
                        </div>
                        <div className="flex justify-between pt-3">
                          <span className="text-base font-bold text-gray-500">Total</span>
                          <span className="text-base font-bold text-gray-500">$200</span>
                        </div>
                      </div>
                      <a href="/cart/customer-info" className="px-3 text-center py-3 bg-secondary text-white uppercase text-base  hover:bg-primary block">Proceed to checkout</a>


                    </div>


                  </div>

                </div>

              </div>

            </section>

          </main>

          {/* Footer */}
          <PublicFooter />
        </div>
      </>
    )
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSent } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    otpSent,
    user,
    users
  };
}
export default connect(mapStateToProps)(Payment);
