import React from "react";
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function Header(props) {
  const onClickMenu = (url) => {
    props.history.push(url)
  }
  
  return (
    <>

      {/* Navbar */}
        {/* <button  className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-800 focus:text-gray-600 md:hidden">
          <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7">
            </path>
          </svg>
        </button>   */}

        <div className="flex-1 px-4 flex justify-end">
          <div className="ml-4 flex items-center md:ml-6">
            {/* <button className="p-1 text-dark rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500" aria-label="Notifications">
              <svg className="h-6 w-6 bg-gray-300 rounded-full" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9">
                </path>
              </svg>
            </button> */}

            <Menu as="div" className="relative inline-block text-left">
              {({ open }) => (
                <>
                  {/* <div className="ml-6 relative">
                    <Menu.Button className="max-w-xs flex items-center cursor-pointer text-sm rounded-full focus:outline-none focus:shadow-outline">
                      <img className="h-12 w-12 rounded-full" src="dist/img/profile.png" alt="" />
                    </Menu.Button>
                  </div> */}
                  <Transition show={open} as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items static className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {/* <Menu.Item>
                          {({ active }) =>
                            (<span href="#" className={classNames(active ? 'bg-gray-200 text-gray-900' : 'text-gray-700', 'transition ease-in-out duration-500 cursor-pointer block px-4 py-2 text-sm')} >Your Profile</span>)}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) =>
                            (<span href="#" className={classNames(active ? 'bg-gray-200 text-gray-900' : 'text-gray-700', 'transition ease-in-out duration-500 cursor-pointer block px-4 py-2 text-sm')} >Settings</span>)}
                        </Menu.Item>*/}
                        <Menu.Item> 
                          {({ active }) =>
                            (<span onClick={() => onClickMenu('../login')} className={classNames(active ? 'bg-secondary text-gray-900' : 'text-gray-900', 'transition ease-in-out duration-500 cursor-pointer block px-4 py-2 text-medium')}>Logout</span>)}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>

          </div>
        </div>
   


    </>
  );
}
